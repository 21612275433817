import LocalizedStrings from 'react-localization';

// en-US
// hi
let strings = new LocalizedStrings({

	en: {
		english: 'English',
		hindi: 'Hindi',	
        dashboard:'Dashboard',
        new_order:'New Order',
        cart:'Cart',
        order_preview:'Order Preview',
        account:'Account',
        past_orders:'Past Orders/Bills',
        add_to_cart:'Add to Cart',
        notify_when_available:'Notify when Available',
        last_3_orders:'Recent Orders',
        latest_products:'Latest Products',
        upcoming_products:'Upcoming Products',
        disclaimer:'Disclaimer',
        disclaimer_text:'This is a disclaimer text to be shown before someone places an order',
        accept_and_continue:'Accept and Continue',
        view_fitment:'VIEW FITMENT',
        view_colors:'VIEW COLORS',
        category:'Category',
        brand_name:'Brand Name',
        helmet_size:'Helmet Size',
        price_range:'Price Range',
        ratings:'Ratings',
        user_name:'User Name',
        owner_name:'Owner Name',
        contact_number:'Contact Number',
        whatsapp_number_1:'WhatsApp Number 1',
        whatsapp_number_2:'WhatsApp Number 2',
        other_contact_person:'Other Contact Person',
        whatsapp_number:'WhatsApp Number',
        contact_name_1:'Contact Name 1',
        contact_name_2:'Contact Name 2',
        contact_name_3:'Contact Name 3',
        save_profile:'Save Profile',
        logout:'Logout',  
        you_have_not_placed_any_order_yet:'You have not placed any order yet',
        view_details:'View Details',
        view:'View',
        status:'Status',
        placed_at:'Placed at',
        order_total:'Order Total',
        cart_total:'Cart Total',
        you_will_be_notified:'You will be notified when this product is available',
        brand:'Brand',
        helmets:'Helmets',
        back:'Back',
        fitment_for:'Fitment for',
        order:'Order',
        print_order:'PRINT ORDER',
        back_to_orders:'BACK TO ORDERS',
        product:'Product',
        color:'Color',
        model:'Model',
        unit_price:'Unit Price',
        quantity:'Quantity',
        past_orders:'Past Orders',
        order_id:'Order ID',
        placed_on:'Placed On',
        view_order:'View Order',
        account_details:'Account Details',
        username:'Username',
        owner_name:'Owner Name',
        contact_no:'Contact No',
        whatsapp_no_1:'WhatsApp No 1',
        other_contact_person:'Other Contact Person' ,
        contact_name_2:'Contact Name 2',
        whatsapp_no_2:'WhatsApp No 2',
        contact_name_3:'Contact Name 3',
        whatsapp_no_3:'WhatsApp No 3',
        contact_name_4:'Contact Name 4',
        whatsapp_no_4:'WhatsApp No 4',
        order_placed_successfully:'Order placed',
        profile_saved_successfully:'Profile saved',
        place_and_send_order:'PLACE ORDER',
        subtotal:'Subtotal',
        gst:'GST',
        go_to_cart_to_complete_this_order:'Go to Cart',
        draft:'Draft',
        size:'Size',
        email:'Email',
        order_id:'Order ID',
        gst_applicable_extra:'GST Applicable Extra',
        preview_order:'Preview Order',
        designation:'Designation',
        no_items_in_cart:'No items in cart',
        address:'Address',
        state:'State',
        district:'District',
        type_to_search:' Type to search',
        type_to_search_categories:' Type to search categories',
        type_to_search_products:' Type to search products',
        filter:'Filter',
        go_back:'GO BACK',
        please_select_a_mode_of_delivery:'Please select a mode of delivery',
        walk_in:'Walk-in',
        by_bus:'By Bus',
        by_transport:'By Transport',
        tempo_load:'Tempo Load',
        order_comments:'Order Comments',
        colors:'Colors',
        mode_of_delivery:'Mode of Delivery',
        type_any_comments_you_have_about_this_order:'Type any comments you have about this order',
        action:'Action',
        view_product_video:'View Product Video',
        hide_product_video:'Hide Product Video',
        product_code:'Product Code',
        product_name:'Product Name',
        product_description:'Product Description',
        billing_name:'Billing Name',
        ordering_for_customer:'Ordering for Customer',
        register_yourself_as:'Register Yourself As',
        register_shopkeeper:'Shopkeeper',
        register_showroom:'Showroom',
        register_billing_name_shopkeeper:'Billing Name',
        register_billing_name_showroom:'Billing Name',
        register_owner_name_shopkeeper:'Owner Name',
        register_owner_name_showroom:'Owner Name',
        register_email_id:'Email ID',
        register_whatsapp_number:'WhatsApp Number',
        register_phone_number:'Phone Number',
        register_address:'Address',
        register_district:'District',
        register_state:'State',
        register_pincode:'Pincode',
        register_gst_number:'GST Number',
        register_vehicle_brand:'Vehicle Brand',
        register_dealer_type:'Dealer Type',
        register_hero:'Hero',
        register_honda:'Honda',
        register_tvs:'TVS',
        register_bajaj:'Bajaj',
        register_yamaha:'Yamaha',
        register_suzuki:'Suzuki',
        register_electric:'Electric',
        register_dealer_type:'Dealer Type',
        register_authorized_main_dealer:'Authorized Main Dealer',
        register_associate_dealer:'Associate Dealer',
        register_authorized_representative_of_dealer:'Authorized Representative of Dealer',
        register_name_of_the_main_dealer:'Name of the main dealer',        
        register_billing_name_shopkeeper_is_mandatory:'Billing Name is mandatory',
        register_billing_name_showroom_is_mandatory:'Billing Name is mandatory',
        register_owner_name_shopkeeper_is_mandatory:'Owner Name is mandatory',
        register_owner_name_showroom_is_mandatory:'Owner Name is mandatory',
        register_email_id_is_mandatory:'Email ID is mandatory',
        register_email_id_is_invalid:'Please enter a valid email ID',
        register_whatsapp_number_is_mandatory:'WhatsApp Number is mandatory',
        register_phone_number_is_mandatory:'Phone Number is mandatory',
        register_address_is_mandatory:'Address is mandatory',
        register_district_is_mandatory:'District is mandatory',
        register_state_is_mandatory:'State is mandatory',
        register_vehicle_brand_is_mandatory:'Vehicle Brand is mandatory',
        register_dealer_type_is_mandatory:'Dealer Type is mandatory',    
        register_phone_digit_exceed:'Phone number can contain maximum 10 digits',
        register_whatsapp_digit_exceed:'Whatsapp number can contain maximum 10 digits',
        thank_you_for_registering:'Thank you for registering. We will get back to you soon',
        register_phone_digit_insufficient:'Phone number must contain minimum 10 digits',
        register_whatsapp_digit_insufficient:'Whatsapp number must contain minimum 10 digits',
        loading_products:'Loading Products',
	},

	hi: {
		english: 'अंग्रेज़ी',
		hindi: 'हिन्दी',        
        dashboard:'डैशबोर्ड',
        new_order:'नया आर्डर ',
        cart:'कार्ट',
        order_preview:'आर्डर प्रीव्यू ',
        account:'अकाउंट ',
        past_orders:'पिछले ऑर्डर्स / बिल्स ',
        add_to_cart:'कार्ट में डालें',
        notify_when_available:'उपलब्ध होने पर सूचित करें',
        last_3_orders:'हाल ही का आर्डर',
        latest_products:'नए प्रोडक्ट्स ',
        upcoming_products:'आने वाले प्रोडक्ट्स ',
        disclaimer:'डिस्क्लेमर ',
        disclaimer_text:'यह एक अस्वीकरण पाठ है जिसे किसी के आदेश देने से पहले दिखाया जाना चाहिए',
        accept_and_continue:'स्वीकार करें और जारी रखें',
        view_fitment:'फिटमेंट देखें',
        view_colors:'रंग देखें',
        category:'वर्ग',
        brand_name:'ब्रांड का नाम',
        helmet_size:'हेलमेट का आकार',
        price_range:'मूल्य सीमा',
        ratings:'रेटिंग्स',
        user_name:'उपयोगकर्ता नाम',
        owner_name:'मालिक का नाम',
        contact_number:'संपर्क संख्या',
        whatsapp_number_1:'व्हाट्सएप नंबर 1',
        whatsapp_number_2:'व्हाट्सएप नंबर 2',
        other_contact_person:'अन्य संपर्क व्यक्ति',
        whatsapp_number:'व्हाट्सएप नंबर',
        contact_name_1:'संपर्क नाम 1',
        contact_name_2:'संपर्क नाम 2',
        contact_name_3:'संपर्क नाम 3',
        save_profile:'प्रोफाइल सेव करें ',
        logout:'लॉग आउट ', 
        you_have_not_placed_any_order_yet:'आपने अभी तक कोई आर्डर नहीं दिया है',	
        view_details:'विवरण देखें',
        view:'देखें',
        status:'स्टेटस',
        placed_at:'पर रखा गया',
        order_total:'आर्डर टोटल',
        cart_total:'कार्ट टोटल',
        you_will_be_notified:'यह प्रोडक्ट उपलब्ध होने पर आपको सूचित किया जाएगा',
        brand:'ब्रांड',
        helmets:'हेल्मेट्स ',
        back:'वापस जाएं',
        fitment_for:'फिटमेंट फॉर',
        order:'आर्डर',
        print_order:'आर्डर को प्रिंट करें ',
        back_to_orders:'वापस जाएँ ',
        product:'प्रोडक्ट',
        color:'रंग',
        model:'मॉडल',
        unit_price:'कीमत',
        quantity:'मात्रा',
        past_orders:'पिछले आर्डर',
        order_id:'आदेश संख्या',
        placed_on:'आर्डर की तारीख',
        view_order:'आर्डर विवरण',
        account_details:'अकाउंट डिटेल्स ',
        username:'यूजरनाम ',
        owner_name:'मालिक का नाम ',
        contact_no:'कांटेक्ट नंबर ',
        whatsapp_no_1:'व्हाट्सप्प नंबर 1',
        other_contact_person:'दूसरा कांटेक्ट पर्सन',
        contact_name_2:'दूसरा कांटेक्ट पर्सन का नाम',
        whatsapp_no_2:'व्हाट्सप्प नंबर 2',
        contact_name_3:'तीसरा  कांटेक्ट पर्सन का नाम',
        whatsapp_no_3:'व्हाट्सप्प नंबर 3',
        contact_name_4:'चौथा कांटेक्ट पर्सन का नाम',
        whatsapp_no_4:'व्हाट्सप्प नंबर  4',
        order_placed_successfully:'आर्डर संपन्न ',
        profile_saved_successfully:'प्रोफाइल सेव किया गया ',
        place_and_send_order:'आर्डर सबमिट करें ',
        subtotal:'सबटोटल ',
        gst:'जीएसटी ',
        go_to_cart_to_complete_this_order:'कार्ट पर जाएँ',
        draft:'ड्राफ्ट ',
        size:'आकार',
        email:'ईमेल',
        order_id:'आर्डर नंबर ',
        gst_applicable_extra:'GST अलग से अप्लाई होगा ',
        preview_order:'आर्डर देखें ',
        designation:'पद',
        no_items_in_cart:'कार्ट में कोई आइटम नहीं',
        address:'पता',
        state:'राज्य',
        district:'जिला',
        type_to_search:' खोजने के लिए लिखें',
        type_to_search_categories:' केटेगरी को खोजने के लिए टाइप करें',
        type_to_search_products:' प्रोडक्ट को खोजने के लिए टाइप करें',
        filter:'फ़िल्टर',
        go_back:'वापस जाओ',
        please_select_a_mode_of_delivery:'कृपया डिलीवरी का एक तरीका चुनें',
        walk_in:'वाक-इन ',
        by_bus:'बस से',
        by_transport:'परिवहन द्वारा',
        tempo_load:'टेम्पो लोड',
        order_comments:'आर्डर कमेंट',
        colors:'रंग',
        mode_of_delivery:'डिलीवरी का तरीका',
        type_any_comments_you_have_about_this_order:'इस आर्डर के बारे में अपनी कोई भी टिप्पणी लिखें',
        action:'एक्शन',
        view_product_video:'उत्पाद वीडियो देखें',
        hide_product_video:'उत्पाद वीडियो छुपाएं',
        product_code:'उत्पाद कोड',
        product_name:'प्रोडक्ट का नाम',
        product_description:'उत्पाद वर्णन',
        billing_name:'बिलिंग नाम',
        ordering_for_customer:'कस्टमर के लिए आर्डर करते हुए ',
        register_yourself_as:'Register Yourself As',
        register_shopkeeper:'Shopkeeper',
        register_showroom:'Showroom',
        register_billing_name_shopkeeper:'दुकान का नाम',
        register_billing_name_showroom:'शोरूम का नाम',
        register_owner_name_shopkeeper:'दुकानदार का नाम',
        register_owner_name_showroom:'शोरूम मालिक का नाम',
        register_email_id:'ईमेल आईडी',
        register_whatsapp_number:'वॉट्स्ऐप नंबर',
        register_phone_number:'फ़ोन नंबर',
        register_address:'पता',
        register_district:'जिला',
        register_state:'राज्य',
        register_pincode:'पिन कोड',
        register_gst_number:'जीएसटी नंबर',
        register_vehicle_brand:'वाहन ब्रांड',
        register_dealer_type:'डीलर प्रकार',
        register_hero:'हीरो',
        register_honda:'होंडा',
        register_tvs:'टीवीएस',
        register_bajaj:'बजाज',
        register_yamaha:'यामाहा',
        register_suzuki:'सुजुकी',
        register_electric:'इलेक्ट्रिक',
        register_dealer_type:'डीलर प्रकार',
        register_authorized_main_dealer:'अधिकृत मुख्य डीलर',
        register_associate_dealer:'सहयोगी डीलर',
        register_authorized_representative_of_dealer:'डीलर का अधिकृत प्रतिनिधि',
        register_name_of_the_main_dealer:'मुख्य डीलर का नाम',        
        register_billing_name_shopkeeper_is_mandatory:'दुकान का नाम अनिवार्य है',
        register_billing_name_showroom_is_mandatory:'शोरूम का नाम अनिवार्य है',
        register_owner_name_shopkeeper_is_mandatory:'दुकानदार का नाम अनिवार्य है',
        register_owner_name_showroom_is_mandatory:'शोरूम मालिक का नाम अनिवार्य है',
        register_email_id_is_mandatory:'ईमेल आईडी अनिवार्य है',
        register_email_id_is_invalid:'कृपया एक मान्य ईमेल दर्ज करें',
        register_whatsapp_number_is_mandatory:'वॉट्स्ऐप नंबर अनिवार्य है',
        register_phone_number_is_mandatory:'फ़ोन नंबर अनिवार्य है',
        register_address_is_mandatory:'पता अनिवार्य है',
        register_district_is_mandatory:'जिला अनिवार्य है',
        register_state_is_mandatory:'राज्य अनिवार्य है',
        register_vehicle_brand_is_mandatory:'वाहन ब्रांड अनिवार्य है',
        register_dealer_type_is_mandatory:'डीलर प्रकार अनिवार्य है',     
        register_phone_digit_exceed:'फ़ोन नंबर में अधिकतम 10 अंक हो सकते हैं',
        register_whatsapp_digit_exceed:'व्हाट्सएप नंबर में अधिकतम 10 अंक हो सकते हैं',
        thank_you_for_registering:'रजिस्टर करने के लिए धन्यवाद। हम आपसे जल्द ही संपर्क करेंगे ',
        register_phone_digit_insufficient:'फ़ोन नंबर में काम से काम 10 अंक होने चाहिए ',
        register_whatsapp_digit_insufficient:'व्हाट्सप्प  नंबर में काम से काम 10 अंक होने चाहिए ',
        loading_products:'प्रोडक्ट्स लोड हो रहे हैँ',
	},	
});


export { strings }
