import React, { useState, useEffect } from "react";
import Carousel from "react-elastic-carousel";
import Grid from "@mui/material/Grid";
import { strings } from "../../string";
import axios from "axios";
import { Image, Breathing } from 'react-shimmer'
import { isMobile } from 'react-device-detect';


const customStyle = {
  margin: isMobile?"5px":"20px",
  marginTop: isMobile?"15px":"20px",
  padding: "10px 10px",
  border: "2px solid #EEEEEE",
  display:'flex',
  flexDirection:'row',
  backgroundColor: "#fff",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "flex-start",
  borderRadius: 10,
  width: isMobile ? '95%' : 'auto',
  marginLeft:isMobile?0:0
}


function LastOrders(props) {

  const [items, setItems] = useState([
    {
      id: 1,
      url: "https://rukminim1.flixcart.com/image/416/416/khmbafk0-0/helmet/x/t/m/sbh-17-terminator-full-face-graphic-helmet-in-matt-black-fluo-original-imafxhedgn57jgvd.jpeg?q=70",
    },
    {
      id: 2,
      url: "https://rukminim1.flixcart.com/image/612/612/kdt50nk0-0/helmet/o/a/9/crux-of-open-face-crx-of-r-open-face-vega-original-imafuhyuc7vvr7hh.jpeg?q=70",
    },
    {
      id: 3,
      url: "https://rukminim1.flixcart.com/image/612/612/jtiz0cw0/helmet/c/x/x/off-road-1-or-d-v-dk-58-motocross-vega-original-imafevfhm4uttsyj.jpeg?q=70",
    },
    {
      id: 4,
      url: "https://rukminim1.flixcart.com/image/612/612/khmbafk0-0/helmet/w/o/5/sbh-17-terminator-full-face-graphic-helmet-in-fluo-matt-black-original-imafxhe9pbxukrht.jpeg?q=70",
    },
    {
      id: 5,
      url: "https://rukminim1.flixcart.com/image/612/612/kapoo7k0/helmet/h/z/w/new-1-824-56-open-face-nivia-original-imafs82jfadh3rut.jpeg?q=70",
    },
  ]
  );

  const [latestOrders, setLatestOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areArrowsVisible, setAreArrowsVisible] = useState(false);
  const [theCartData, setTheCartData] = useState([])
  const [cartTotal, setCartTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [numCart, setNumCart] = useState(0)


  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];


  useEffect(() => {
    getLastOrders();
    getCartData();
  }, []);


  const handleOpen1 = () => {

      // props.changeTabIndex("3");
      props.history.push('/past-orders')
  }

  const handleOpen2 = () => {

      //props.changeTabIndex("2");
      props.history.push('/cart')
  }

  const showArrows = () => {
    setAreArrowsVisible(true)
  }

  const hideArrows = () => {
    setAreArrowsVisible(false)
  }

  const getCartData = async (json) => {

    try {

      var highestCartIndex = localStorage.getItem('highest_cart_index');

      var cartData = [];

      var theSubTotal = 0;
      var theCartTotal = 0;

      var theCartString = '';

      for (var i = 1; i <= parseInt(highestCartIndex); i++) {


        var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
        var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
        var cartItemPrice = localStorage.getItem('cart_item_product_price_' + i);
        var cartItemQty = localStorage.getItem('cart_item_product_qty_' + i);
        var cartItemStatus = localStorage.getItem('cart_item_product_status_' + i);

        var cartItemCategory = localStorage.getItem('cart_item_product_category_' + i);
        var cartItemColor = localStorage.getItem('cart_item_product_color_' + i);
        var cartItemSize = localStorage.getItem('cart_item_product_size_' + i);
        var cartItemBrand = localStorage.getItem('cart_item_product_brand_' + i);

        var fitmentFor = localStorage.getItem('cart_item_product_fitment_for_' + i);
        var fitmentName = localStorage.getItem('cart_item_product_fitment_name_' + i);
        var type = localStorage.getItem('cart_item_product_type_' + i);


        if (cartItemStatus == 'active') {

          cartData.push({
            'product_id': cartItemProductId,
            'product_name': cartItemProductName,
            'product_price': cartItemPrice,
            'qty': cartItemQty,
            'index': i,
            'status': cartItemStatus,
            'category': cartItemCategory,
            'color': cartItemColor,
            'size': cartItemSize,
            'brand': cartItemBrand,
            'fitment_for': fitmentFor,
            'fitment_name': fitmentName,
            'fitment_type': type,
          });

          var lineTotal = parseFloat(cartItemPrice) * parseInt(cartItemQty);

          theSubTotal = theSubTotal + parseFloat(lineTotal);
          theCartTotal = theCartTotal + parseFloat(lineTotal);

          theCartString = theCartString + '#' + (cartItemProductId +
            '*' + cartItemQty +
            '*' + cartItemPrice +
            '*' + cartItemCategory +
            '*' + cartItemColor +
            '*' + cartItemSize +
            '*' + cartItemBrand +
            '*' + fitmentFor +
            '*' + fitmentName +
            '*' + type
          );
        }

      }

      setCartTotal(theCartTotal);
      setSubTotal(theSubTotal);
      setTheCartData(cartData);

    } catch (error) {
      //console.log(error);
    }

  };


  const getLastOrders = () => {

    setIsLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: {
          'Authorization': 'Bearer ' + props.access_token
      }
    };


    fetch(
      'https://arihant.trikodev.xyz' + "/api/get-latest-orders", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setLatestOrders(json.orders);
        setIsLoading(false);
      })

  };


  return (
    <>

    <div>
      {isLoading ?
        <Breathing width={'100%'} height={150} style={{ marginTop: 20 }} />
        :

        <div onMouseEnter={showArrows} onMouseLeave={hideArrows} 
        style={{width:'98%', marginLeft:'0%'}}>

        {subTotal > 0 &&
              <div style={customStyle}>
                <span style={{
                  backgroundColor: 'grey', color: 'white', borderRadius: 5, fontSize: 12,
                  padding: 5, width:50, alignSelf:'center', textAlign:'center'
                }}>{strings.draft}</span>

                <p 
                 
                 style={{ fontSize: 14, marginLeft:isMobile?'12%':'5%', width:isMobile?'50%':'auto',
                 display:'flex', flexDirection:'row' }}>
                   {strings.cart_total}: ₹{subTotal.toFixed(2)}</p>
              
                <a onClick={handleOpen2}
                  style={{ textDecoration: 'underline',  cursor:'pointer',
                  marginLeft:isMobile?'1%':'2%', textAlign: 'center', color:'#F05225',
                  alignSelf:'center', fontSize: 12 }}>
                  {strings.go_to_cart_to_complete_this_order}</a>
              </div>
            }

          {latestOrders.length > 0?
         
         <>         

            {latestOrders.map((item) => (
              <div key={item.id} style={customStyle}>
                <p style={{ fontSize: 14 }}>{strings.order_id}: #000{item.id}</p>
                <p style={{ fontSize: 14, marginLeft:isMobile?0:30 }}>{strings.placed_at}: {(new Date(item.created_at)).toLocaleDateString()}</p>
                <p style={{ fontSize: 14, marginLeft:isMobile?0:30 }}>{strings.order_total}: ₹{item.order_total.toFixed(2)}</p>
                <a onClick={handleOpen1} style={{ textDecoration: 'underline',  cursor:'pointer',
                textAlign: 'center', 
                marginLeft:isMobile?0:30, alignSelf:'center', 
                color:'#F05225', fontWeight:700, fontSize: 12 }}>
                  {strings.view_details}</a>
              </div>
            ))}

            </>
          :
          <span style={{textAlign:'center', fontSize:13}}>{strings.you_have_not_placed_any_order_yet}</span>
            }

        </div>
      }
      </div>
    </>

  );
}
export default LastOrders;
