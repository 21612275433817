import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Card } from "@material-ui/core";
import Drawer from '@mui/material/Drawer';
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Rating } from "@mui/material";
import { fontSize, textAlign } from "@mui/system";
import Pizza from "../Home/Pizza.jpg";
import Filter from "./Filter";
import { strings } from "../../string.js";
import axios from "axios";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress, Snackbar } from '@material-ui/core';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, withRouter } from "react-router-dom";
import Header from "../Home/Header";
import placeholder from "../../assets/img/placeholder.png";

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

const Neworder4 = ({ history, server, access_token, login, role, auth, logout }) => {

    const [value, setValue] = React.useState(2);
    const [isError, setIsError] = React.useState(false);
    const [theCartData, setTheCartData] = useState([])
    const [cartTotal, setCartTotal] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [numCart, setNumCart] = useState(0)

    const [onLoading, setOnLoading] = useState(false)
    const [cartString, setCartString] = useState('')
    const [itemToDelete, setItemToDelete] = useState(null)

    const [orderComments, setOrderComments] = useState('')
    const [modeOfDelivery, setModeOfDelivery] = useState('')

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

    const [customItemName1, setCustomItemName1] = React.useState('');
    const [customItemName2, setCustomItemName2] = React.useState('');
    const [customItemName3, setCustomItemName3] = React.useState('');
    const [customItemName4, setCustomItemName4] = React.useState('');
    const [customItemName5, setCustomItemName5] = React.useState('');
    const [customItemName6, setCustomItemName6] = React.useState('');
    const [customItemName7, setCustomItemName7] = React.useState('');
    const [customItemName8, setCustomItemName8] = React.useState('');
    const [customItemName9, setCustomItemName9] = React.useState('');
    const [customItemName10, setCustomItemName10] = React.useState('');
    const [customItemName11, setCustomItemName11] = React.useState('');
    const [customItemName12, setCustomItemName12] = React.useState('');
    const [customItemName13, setCustomItemName13] = React.useState('');
    const [customItemName14, setCustomItemName14] = React.useState('');
    const [customItemName15, setCustomItemName15] = React.useState('');
    const [customItemQuantity1, setCustomItemQuantity1] = React.useState('');
    const [customItemQuantity2, setCustomItemQuantity2] = React.useState('');
    const [customItemQuantity3, setCustomItemQuantity3] = React.useState('');
    const [customItemQuantity4, setCustomItemQuantity4] = React.useState('');
    const [customItemQuantity5, setCustomItemQuantity5] = React.useState('');
    const [customItemQuantity6, setCustomItemQuantity6] = React.useState('');
    const [customItemQuantity7, setCustomItemQuantity7] = React.useState('');
    const [customItemQuantity8, setCustomItemQuantity8] = React.useState('');
    const [customItemQuantity9, setCustomItemQuantity9] = React.useState('');
    const [customItemQuantity10, setCustomItemQuantity10] = React.useState('');
    const [customItemQuantity11, setCustomItemQuantity11] = React.useState('');
    const [customItemQuantity12, setCustomItemQuantity12] = React.useState('');
    const [customItemQuantity13, setCustomItemQuantity13] = React.useState('');
    const [customItemQuantity14, setCustomItemQuantity14] = React.useState('');
    const [customItemQuantity15, setCustomItemQuantity15] = React.useState('');

    useEffect(() => {

        var unavailableItemName1 = localStorage.getItem('unavailable_item_name1');
        var unavailableItemName2 = localStorage.getItem('unavailable_item_name2');
        var unavailableItemName3 = localStorage.getItem('unavailable_item_name3');
        var unavailableItemName4 = localStorage.getItem('unavailable_item_name4');
        var unavailableItemName5 = localStorage.getItem('unavailable_item_name5');
        var unavailableItemName6 = localStorage.getItem('unavailable_item_name6');
        var unavailableItemName7 = localStorage.getItem('unavailable_item_name7');
        var unavailableItemName8 = localStorage.getItem('unavailable_item_name8');
        var unavailableItemName9 = localStorage.getItem('unavailable_item_name9');
        var unavailableItemName10 = localStorage.getItem('unavailable_item_name10');
        var unavailableItemName11 = localStorage.getItem('unavailable_item_name11');
        var unavailableItemName12 = localStorage.getItem('unavailable_item_name12');
        var unavailableItemName13 = localStorage.getItem('unavailable_item_name13');
        var unavailableItemName14 = localStorage.getItem('unavailable_item_name14');
        var unavailableItemName15 = localStorage.getItem('unavailable_item_name15');

        var unavailableItemQuantity1 = localStorage.getItem('unavailable_item_quantity1');
        var unavailableItemQuantity2 = localStorage.getItem('unavailable_item_quantity2');
        var unavailableItemQuantity3 = localStorage.getItem('unavailable_item_quantity3');
        var unavailableItemQuantity4 = localStorage.getItem('unavailable_item_quantity4');
        var unavailableItemQuantity5 = localStorage.getItem('unavailable_item_quantity5');
        var unavailableItemQuantity6 = localStorage.getItem('unavailable_item_quantity6');
        var unavailableItemQuantity7 = localStorage.getItem('unavailable_item_quantity7');
        var unavailableItemQuantity8 = localStorage.getItem('unavailable_item_quantity8');
        var unavailableItemQuantity9 = localStorage.getItem('unavailable_item_quantity9');
        var unavailableItemQuantity10 = localStorage.getItem('unavailable_item_quantity10');
        var unavailableItemQuantity11 = localStorage.getItem('unavailable_item_quantity11');
        var unavailableItemQuantity12 = localStorage.getItem('unavailable_item_quantity12');
        var unavailableItemQuantity13 = localStorage.getItem('unavailable_item_quantity13');
        var unavailableItemQuantity14 = localStorage.getItem('unavailable_item_quantity14');
        var unavailableItemQuantity15 = localStorage.getItem('unavailable_item_quantity15');

        setCustomItemName1(unavailableItemName1);
        setCustomItemName2(unavailableItemName2);
        setCustomItemName3(unavailableItemName3);
        setCustomItemName4(unavailableItemName4);
        setCustomItemName5(unavailableItemName5);
        setCustomItemName6(unavailableItemName6);
        setCustomItemName7(unavailableItemName7);
        setCustomItemName8(unavailableItemName8);
        setCustomItemName9(unavailableItemName9);
        setCustomItemName10(unavailableItemName10);
        setCustomItemName11(unavailableItemName11);
        setCustomItemName12(unavailableItemName12);
        setCustomItemName13(unavailableItemName13);
        setCustomItemName14(unavailableItemName14);
        setCustomItemName15(unavailableItemName15);

        setCustomItemQuantity1(unavailableItemQuantity1);
        setCustomItemQuantity2(unavailableItemQuantity2);
        setCustomItemQuantity3(unavailableItemQuantity3);
        setCustomItemQuantity4(unavailableItemQuantity4);
        setCustomItemQuantity5(unavailableItemQuantity5);
        setCustomItemQuantity6(unavailableItemQuantity6);
        setCustomItemQuantity7(unavailableItemQuantity7);
        setCustomItemQuantity8(unavailableItemQuantity8);
        setCustomItemQuantity9(unavailableItemQuantity9);
        setCustomItemQuantity10(unavailableItemQuantity10);
        setCustomItemQuantity11(unavailableItemQuantity11);
        setCustomItemQuantity12(unavailableItemQuantity12);
        setCustomItemQuantity13(unavailableItemQuantity13);
        setCustomItemQuantity14(unavailableItemQuantity14);
        setCustomItemQuantity15(unavailableItemQuantity15);

  }, []);


    const items = { ...localStorage };

    const options = {
        title: 'Alert',
        message: 'Are you sure you want to remove this item from cart?',
        buttons: [
            {
                label: 'Yes',
                onClick: () => removeFromCart()
            },
            {
                label: 'No',

            }
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypressEscape: () => { },
        overlayClassName: "overlay-custom-class-name"
    };


    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }

    const goBackToCart = async () => {

        history.push('/cart')
    }


    useEffect((async) => {

        (async () => {

            try {

                getCartData();

                var num = localStorage.getItem('num_of_items_in_cart');

                if (num == null) {
                    setNumCart(0);
                } else if (num == '0') {

                    setNumCart(0);
                } else {
                    setNumCart(num);
                }

            } catch (error) {
                // console.log(error);
            }


        })();


    }, []);


    const askToRemoveFromCart = async (item) => {


        setItemToDelete(item);
        await timeout(500);
        confirmAlert(options);

    }



    const removeAllItemsFromCart = async (item) => {


        for (var i = 0; i < 100; i++) {

            localStorage.setItem('cart_item_product_status_' + i, 'inactive');

            var prevQty = localStorage.getItem('cart_item_product_qty_' + i);
            prevQty = parseInt(prevQty);


            var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');
            prevNumOfCartItems = parseInt(prevNumOfCartItems);

            var newNumOfCartItems = prevNumOfCartItems - prevQty;

            localStorage.setItem('cart_item_product_qty_' + i, 0);
            localStorage.setItem('num_of_items_in_cart', newNumOfCartItems);

            setNumCart(newNumOfCartItems);
        }

        // Remove custom items from local storage
        localStorage.removeItem('unavailable_item_name1');
        localStorage.removeItem('unavailable_item_name2');
        localStorage.removeItem('unavailable_item_name3');
        localStorage.removeItem('unavailable_item_name4');
        localStorage.removeItem('unavailable_item_name5');
        localStorage.removeItem('unavailable_item_name6');
        localStorage.removeItem('unavailable_item_name7');
        localStorage.removeItem('unavailable_item_name8');
        localStorage.removeItem('unavailable_item_name9');
        localStorage.removeItem('unavailable_item_name10');
        localStorage.removeItem('unavailable_item_name11');
        localStorage.removeItem('unavailable_item_name12');
        localStorage.removeItem('unavailable_item_name13');
        localStorage.removeItem('unavailable_item_name14');
        localStorage.removeItem('unavailable_item_name15');
    
        localStorage.removeItem('unavailable_item_quantity1');
        localStorage.removeItem('unavailable_item_quantity2');
        localStorage.removeItem('unavailable_item_quantity3');
        localStorage.removeItem('unavailable_item_quantity4');
        localStorage.removeItem('unavailable_item_quantity5');
        localStorage.removeItem('unavailable_item_quantity6');
        localStorage.removeItem('unavailable_item_quantity7');
        localStorage.removeItem('unavailable_item_quantity8');
        localStorage.removeItem('unavailable_item_quantity9');
        localStorage.removeItem('unavailable_item_quantity10');
        localStorage.removeItem('unavailable_item_quantity11');
        localStorage.removeItem('unavailable_item_quantity12');
        localStorage.removeItem('unavailable_item_quantity13');
        localStorage.removeItem('unavailable_item_quantity14');
        localStorage.removeItem('unavailable_item_quantity15');


        //window.location.reload();


        getCartData();
        //props.refreshCartQty();

    }

    // Remove from cart
    const removeFromCart = async (item) => {

        var indexOfTheCartItem = item.index;

        localStorage.setItem('cart_item_product_status_' + indexOfTheCartItem, 'inactive');

        var prevQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);
        prevQty = parseInt(prevQty);


        var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');
        prevNumOfCartItems = parseInt(prevNumOfCartItems);

        var newNumOfCartItems = prevNumOfCartItems - prevQty;

        localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, 0);
        localStorage.setItem('num_of_items_in_cart', newNumOfCartItems);

        setNumCart(newNumOfCartItems);

        getCartData();
        //props.refreshCartQty();
    };



    const notifySuccess = async (json) => {

        setOnLoading(false)

        toast(strings.order_placed_successfully, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        removeAllItemsFromCart();



        history.push('/past-orders')
    }



    const placeOrder = async (json) => {

        if (modeOfDelivery == '') {

            setIsError(true);

        } else {

            setIsError(false);

            var formData = new FormData();

            formData.append("cart_string", cartString);
            formData.append("order_comments", orderComments);
            formData.append("mode_of_delivery", modeOfDelivery);

            // Add the unavailable items
            formData.append("custom_item_1", customItemName1);
            formData.append("custom_item_qty_1", customItemQuantity1);

            formData.append("custom_item_2", customItemName2);
            formData.append("custom_item_qty_2", customItemQuantity2);

            formData.append("custom_item_3", customItemName3);
            formData.append("custom_item_qty_3", customItemQuantity3);

            formData.append("custom_item_4", customItemName4);
            formData.append("custom_item_qty_4", customItemQuantity4);

            formData.append("custom_item_5", customItemName5);
            formData.append("custom_item_qty_5", customItemQuantity5);

            formData.append("custom_item_6", customItemName6);
            formData.append("custom_item_qty_6", customItemQuantity6);

            formData.append("custom_item_7", customItemName7);
            formData.append("custom_item_qty_7", customItemQuantity7);

            formData.append("custom_item_8", customItemName8);
            formData.append("custom_item_qty_8", customItemQuantity8);

            formData.append("custom_item_9", customItemName9);
            formData.append("custom_item_qty_9", customItemQuantity9);

            formData.append("custom_item_10", customItemName10);
            formData.append("custom_item_qty_10", customItemQuantity10);

            formData.append("custom_item_11", customItemName11);
            formData.append("custom_item_qty_11", customItemQuantity11);

            formData.append("custom_item_12", customItemName12);
            formData.append("custom_item_qty_12", customItemQuantity12);

            formData.append("custom_item_13", customItemName13);
            formData.append("custom_item_qty_13", customItemQuantity13);

            formData.append("custom_item_14", customItemName14);
            formData.append("custom_item_qty_14", customItemQuantity14);

            formData.append("custom_item_15", customItemName15);
            formData.append("custom_item_qty_15", customItemQuantity15);


            setOnLoading(true);

            const requestOptions = {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': 'Bearer ' + access_token
                }
            };

            fetch('https://arihant.trikodev.xyz/' + 'api/user/place-order', requestOptions)
                .then(response => response.json())
                .then(data => notifySuccess());
        }

    }


    const getCartData = async (json) => {

        try {

            var highestCartIndex = localStorage.getItem('highest_cart_index');

            var cartData = [];

            var theSubTotal = 0;
            var theCartTotal = 0;

            var theCartString = '';

            for (var i = 1; i <= parseInt(highestCartIndex); i++) {

                var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
                var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
                var cartItemTallyImportName = localStorage.getItem('cart_item_tally_import_name_' + i);

                var cartItemPrice = localStorage.getItem('cart_item_product_price_' + i);
                var cartItemQty = localStorage.getItem('cart_item_product_qty_' + i);
                var cartItemStatus = localStorage.getItem('cart_item_product_status_' + i);
                var cartItemCategory = localStorage.getItem('cart_item_product_category_' + i);
                var cartItemColor = localStorage.getItem('cart_item_product_color_' + i);
                var cartItemSize = localStorage.getItem('cart_item_product_size_' + i);
                var cartItemBrand = localStorage.getItem('cart_item_product_brand_' + i);
                var fitmentFor = localStorage.getItem('cart_item_product_fitment_for_' + i);
                var fitmentName = localStorage.getItem('cart_item_product_fitment_name_' + i);
                var type = localStorage.getItem('cart_item_product_type_' + i);
                var productBucket = localStorage.getItem('cart_item_product_bucket_' + i);
                var bucketPrice = localStorage.getItem('cart_item_bucket_price_' + i);
                var productImage = localStorage.getItem('cart_item_product_image_' + i);


                if (cartItemStatus == 'active') {

                    cartData.push({
                        'product_id': cartItemProductId,
                        'product_name': cartItemProductName,
                        'tally_import_name': cartItemTallyImportName,
                        'product_price': cartItemPrice,
                        'qty': cartItemQty,
                        'index': i,
                        'status': cartItemStatus,
                        'category': cartItemCategory,
                        'color': cartItemColor,
                        'size': cartItemSize,
                        'brand': cartItemBrand,
                        'fitment_for': fitmentFor,
                        'fitment_name': fitmentName,
                        'fitment_type': type,
                        'product_bucket': productBucket,
                        'bucket_price': bucketPrice,
                        'product_image_path': productImage
                    });

                    var lineTotal = parseFloat(cartItemPrice) * parseInt(cartItemQty);

                    theSubTotal = theSubTotal + parseFloat(lineTotal);
                    theCartTotal = theCartTotal + parseFloat(lineTotal);

                    theCartString = theCartString + '#' + (cartItemProductId +
                        '*' + cartItemQty +
                        '*' + cartItemPrice +
                        '*' + cartItemCategory +
                        '*' + cartItemColor +
                        '*' + cartItemSize +
                        '*' + cartItemBrand +
                        '*' + fitmentFor +
                        '*' + fitmentName +
                        '*' + type
                    );
                }

                setCartString(theCartString);
            }


            setCartTotal(theCartTotal);
            setSubTotal(theSubTotal);

            setTheCartData(cartData);


        } catch (error) {
            //console.log(error);
        }


    };

    const changeQty = async (e) => {

        var indexOfTheCartItem = e.target.name;

        var itemQty = e.target.value;
        localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, itemQty);

        var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');

        prevNumOfCartItems = parseInt(prevNumOfCartItems);
        prevNumOfCartItems = itemQty;

        localStorage.setItem('num_of_items_in_cart', prevNumOfCartItems);
        setNumCart(prevNumOfCartItems);

        getCartData();

    }


    var buckets = [];

    for (var i = 0; i < theCartData.length; i++) {

        if (!buckets.includes(theCartData[i].product_bucket)) {
            buckets.push(theCartData[i].product_bucket);
        }
    }


    var bucketizedCartData = [];

    for (var i = 0; i < buckets.length; i++) {

        if (buckets[i] !== "0") {

            var theItem = {};
            var total = 0;
            var totalQty = 0;

            var prodName = '';
            var category = '';
            var brand = '';
            var productPrice = 0;

            

            for (var j = 0; j < theCartData.length; j++) {                

                if (theCartData[j].product_bucket == buckets[i]) {

                    totalQty = totalQty + parseInt(theCartData[j].qty);

                    if(theCartData[j].tally_import_name){
                        
                        if(theCartData[j].tally_import_name != 'Null' && theCartData[j].tally_import_name !=='null'){
                            prodName = theCartData[j].tally_import_name
                        }else{
                            if(theCartData[j].product_name!=='Null' && theCartData[j].product_name!=='null'){
                                prodName = theCartData[j].product_name;
                            }
                        }
                    }

                    category = theCartData[j].category;
                    brand = theCartData[j].brand;

                    if(theCartData[j].bucket_price && theCartData[j].bucket_price !== 'null' && theCartData[j].bucket_price !== 'Null'){
                        productPrice = theCartData[j].bucket_price;
                    }else{
                        productPrice = theCartData[j].product_price;
                    }                   

                    
                    
                }else{
                  //  productPrice = theCartData[j].product_price;
                }
            }



            theItem.qty = totalQty;
            theItem.product_name = prodName;
            theItem.category = category;
            theItem.brand = brand;
            theItem.product_price = productPrice;

            bucketizedCartData.push(theItem);

        } else {

            for (var j = 0; j < theCartData.length; j++) {

                if (theCartData[j].product_bucket == buckets[i]) {

                    bucketizedCartData.push(theCartData[j]);
                }
            }
        }

    }

    return (
        <>

            <div style={{ width: '100%' }}>

                <div style={{
                    display: 'flex', flexDirection: 'column',
                    width: '100%', position: 'fixed',
                    top: 0, left: 0, right: 0, zIndex: 1000, backgroundColor: 'white'
                }}>

                    <Header />

                    <div className="only-desktop" style={{
                        flexDirection: 'row', marginTop: 65,
                        position: 'fixed', top: 0, left: 0, right: 0, zIndex: 8000, backgroundColor: '#FCF5E5', marginBottom: 120
                    }}>

                        <Link to="/dashboard" style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                            width: '20%', borderRadius: 0, textDecoration: 'none', height: 60,
                            cursor: 'pointer',
                            alignSelf: 'center', borderBottom: '2px solid grey'
                        }}>

                            <span style={{
                                width: '100%', textAlign: 'center', color: '#000000',
                                fontSize: 15, textTransform: 'uppercase', fontWeight: 700
                            }}>{strings.dashboard}</span>
                        </Link>

                        <Link to="/new-order" style={{
                            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
                            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
                        }}>

                            <span style={{
                                width: '100%', textAlign: 'center', fontSize: 15,
                                textTransform: 'uppercase', fontWeight: 700
                            }}>{strings.new_order}</span>
                        </Link>

                        <Link to="/cart" style={{
                            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
                            alignSelf: 'center', borderBottom: '4px solid #F05225'
                        }}>

                            <span style={{
                                width: '100%', color: '#F05225', textAlign: 'center',
                                fontSize: 15, textTransform: 'uppercase', fontWeight: 700
                            }}>{strings.cart}</span>
                        </Link>

                        <Link to="/past-orders" style={{
                            display: 'flex', borderRadius: 0, textDecoration: 'none', flexDirection: 'row',
                            cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
                            alignSelf: 'center', borderBottom: '2px solid grey'
                        }}>

                            <span style={{
                                width: '100%', textAlign: 'center', fontSize: 15,
                                textTransform: 'uppercase', fontWeight: 700, color: '#000000'
                            }}>{strings.past_orders}</span>
                        </Link>

                        <Link to="/account" style={{
                            display: 'flex', borderRadius: 0, textDecoration: 'none',
                            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
                            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
                        }}>

                            <span style={{
                                width: '100%', textAlign: 'center',
                                fontSize: 15, textTransform: 'uppercase', fontWeight: 700
                            }}>{strings.account}</span>
                        </Link>


                    </div>

                </div>



                <div className="main-div previewOrderPage">

                <h2 style={{ marginLeft: 0 }}>{strings.order_preview}</h2>


                    <div className="cartTableParent1" style={{overflow:'scroll',}}>

                        <table className="orderReview"
                            style={{
                                width: '100%',
                                border: '1 px solid grey', borderCollapse: 'collapse'
                            }}>

                            <thead style={{
                                backgroundColor: '#f5f5f5', borderWidth: 2, borderColor: 'black',
                                height: 50, paddingTop: 10, paddingBottom: 10
                            }}>
                                <tr>
                                    <th>#</th>
                                    <th>{strings.category}</th>
                                    <th>{strings.brand}</th>
                                    <th>{strings.product}</th>
                                    <th>{strings.unit_price}</th>
                                    <th>{strings.quantity}</th>
                                </tr>

                            </thead>

                            <tbody>

                                {bucketizedCartData.map((item, index) => (

                                    <tr style={{ backgroundColor: index % 2 == 0 ? '#FFFAFA' : '#F8F8FF' }}>
                                        <td>{index + 1}</td>
                                        <td>{item.category}</td>
                                        <td>{item.brand}</td>
                                        <td>{item.product_name}</td>
                                        <td><b>₹</b>{item.product_price}</td>
                                        <td>{item.qty}</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>

                    {(customItemName1 || customItemName2 || customItemName3 || customItemName4 || customItemName5 
                    || customItemName6 || customItemName7 || customItemName8 || customItemName9 || customItemName10 
                    || customItemName11 || customItemName12 || customItemName13 || customItemName14 || customItemName15) &&

                    <>

                    <h3 style={{marginTop:20}}>Items that could not be found</h3>

                  <table className="unavailableItemsTable" style={{backgroundColor:'#F4F4FA', padding:10}} border="0">
                           <thead>
                               <th style={{textAlign:'left'}}>Item Name</th>
                               <th style={{textAlign:'center', }}>Quantity</th>
                           </thead>

                           <tbody>

                           {customItemName1 &&
                              <tr>
                               <td style={{width:600}}> {customItemName1}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity1}
                               </td>
                              </tr>
                            }

                            {customItemName2 &&
                              <tr>
                               <td style={{width:600}}>{customItemName2}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity2}
                               </td>
                              </tr>
                            }

                              {customItemName3 &&
                              <tr>
                               <td style={{width:600}}>{customItemName3}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity3}
                               </td>
                              </tr>
                              }


                              {customItemName4 &&
                              <tr>
                               <td style={{width:600}}>{customItemName4}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity4}
                               </td>
                              </tr>
                              }

                              {customItemName5 &&
                              <tr>
                               <td style={{width:600}}>{customItemName5}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity5}
                               </td>
                              </tr>
                              }

                              {customItemName6 &&
                              <tr>
                               <td style={{width:600}}>{customItemName6}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity6}
                               </td>
                              </tr>
                              }

                              {customItemName7 &&
                              <tr>
                               <td style={{width:600}}>{customItemName7}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity7}
                               </td>
                              </tr>
                              }

                              {customItemName8 &&
                              <tr>
                               <td style={{width:600}}>{customItemName8}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity8}
                               </td>
                              </tr>
                              }

                              {customItemName9 &&
                              <tr>
                               <td style={{width:600}}>{customItemName9}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity9}
                               </td>
                              </tr>
                              }

                              {customItemName10 &&
                              <tr>
                               <td style={{width:600}}>{customItemName10}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity10}
                               </td>
                              </tr>
                              }

                              {customItemName11 &&
                              <tr>
                               <td style={{width:600}}>{customItemName11}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity11}
                               </td>
                              </tr>
                              }

                              {customItemName12 &&
                              <tr>
                               <td style={{width:600}}>{customItemName12}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity12}
                               </td>
                              </tr>
                              }

                              {customItemName13 &&
                              <tr>
                               <td style={{width:600}}>{customItemName13}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity13}
                               </td>
                              </tr>
                              }

                              {customItemName14 &&
                              <tr>
                               <td style={{width:600}}>{customItemName14}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity14}
                               </td>
                              </tr>
                              }

                              {customItemName15 &&
                              <tr>
                               <td style={{width:600}}>{customItemName15}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity15}
                               </td>
                              </tr>
                              }



                           </tbody>
                       </table>

                       </>
                       
                    }

                       



                    <div style={{ marginTop: isMobile?30:100 }}>
                        <label><b>{strings.order_comments}</b></label><br />
                        <textarea
                            cols={40}
                            rows={5}
                            value={orderComments}
                            name="order_comments"
                            placeholder={strings.type_any_comments_you_have_about_this_order}
                            style={{ width: isMobile?'90%':'50%', marginTop:5,
                            padding: 10, border: '1px solid rgba(0,0,0,.125)', 
                            borderRadius: 5, backgroundColor: '#ebedf8' }}
                            onChange={(event) => setOrderComments(event.target.value)}
                        >

                        </textarea>

                    </div>

                    <br />

                    <div style={{ width: isMobile?'95%':'52%' }}>

                        <fieldset style={{ border: '1px solid rgba(0,0,0,.125)', borderRadius: 5, backgroundColor: '#ebedf8' }}>
                            <legend><b>{strings.please_select_a_mode_of_delivery}</b></legend>

                            <div>
                                <input type="radio" id="huey" name="drone" value="Walk-in"
                                    onChange={(event) => setModeOfDelivery(event.target.value)} />
                                <label for="huey">{strings.walk_in}</label>
                            </div>

                            <div>
                                <input type="radio" id="dewey" name="drone" value="By Bus" onChange={(event) => setModeOfDelivery(event.target.value)} />
                                <label for="dewey">{strings.by_bus}</label>
                            </div>

                            <div>
                                <input type="radio" id="louie" name="drone" value="By Transport" onChange={(event) => setModeOfDelivery(event.target.value)} />
                                <label for="louie">{strings.by_transport}</label>
                            </div>

                            <div>
                                <input type="radio" id="louie" name="drone" value="Tempo Load" onChange={(event) => setModeOfDelivery(event.target.value)} />
                                <label for="louie">{strings.tempo_load}</label>
                            </div>
                        </fieldset>

                        {isError &&
                            <p style={{ color: 'red', fontSize: 13 }}>{strings.please_select_a_mode_of_delivery}</p>
                        }

                        <select onChange={(event) => setModeOfDelivery(event.target.value)} style={{ display: 'none' }}>
                            <option value="Walk-in">Walk-in</option>
                            <option value="By Bus">By Bus</option>
                            <option value="By Transport">By Transport</option>
                            <option value="Tempo Load">Tempo Load</option>
                        </select>
                    </div>

                    <div style={{ float: 'right', marginBottom: 20, marginRight:isMobile?20: 50, }}>

                        <h5 style={{ textAlign: 'right' }}>{strings.order_total} : <b>₹</b>{subTotal.toFixed(2)}</h5>
                        <h5 style={{ textAlign: 'right' }}>{strings.gst_applicable_extra}</h5>

                    </div>


                    <div style={{
                        display: 'flex', width: '100%',
                        marginBottom: 100,
                        marginRight: 50,
                        flexDirection: 'row'
                    }}>

                        <button className="btn9 orderPreviewGoBackButton"
                            style={{ borderRadius: 5 }}
                            onClick={goBackToCart}
                        >
                            {strings.go_back}</button>

                        <button className="btn9 orderPreviewPlaceOrderButton"
                            style={{ borderRadius: 5 }}
                            onClick={placeOrder}
                        >
                            {onLoading ? <CircularProgress size={20} /> : strings.place_and_send_order}</button>
                    </div>

                </div>
            </div>


        </>
    )
}

const mapStateToProps = (state) => ({
    server: state.auth.server,
    access_token: state.auth.access_token,
    role: state.auth.role[0],
    point: state.auth.point,
});

export default connect(mapStateToProps, {})(withRouter(Neworder4));