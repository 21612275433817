import React, { Fragment } from "react";
// import "./Style.css";
import Switch from "@material-ui/core/Switch";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from '@mui/material/Badge';

import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Home from "../Home/Home";
import Neworder from "../Neworder/Neworder";
import Account from "../Acoounts/Account";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Cart from "../Ongoing/Cart";
import PastOrders from "../PastOrders/PastOrders";
import { strings } from "../../string.js";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {
    useGmailTabsStyles,
    useGmailTabItemStyles,
} from "@mui-treasury/styles/tabs";
import Inbox from "@material-ui/icons/Inbox";
import LocalOffer from "@material-ui/icons/LocalOffer";
import People from "@material-ui/icons/People";
import Info from "@material-ui/icons/Info";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import { typography } from "@mui/system";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../actions/authAction";

import logo from "../../assets/img/arihant_logo.jpeg";

const indicatorColors = ["#d93025", "#1a73e8", "#188038", "#e37400"];

const Notifications = ({ history, server, access_token, login, role, auth, logout }) => {

    const customStyle = {
        display: "flex",
        flexDirection: "row",
    }

    const goToNotifications = () => {
        history.push('/notifications');
      }
    
      const goToHome = () => {
        history.push('/');
      }

      const handleLogOut = () => {

        const config = {
          headers: { Authorization: `Bearer ${access_token}` },
        };
        const parameter = {
          _method: "DELETE",
        };
    
        console.log("In logout1");
        logout();
        history.push("/");
        
      };
    
      
      const switchChanged = (e) => {
    
        if(e.target.checked){
          strings.setLanguage('hi')
          setIsChecked(true)
        }else{
          strings.setLanguage('en')
          setIsChecked(false)
        }
        
      }
    

    const [isChecked, setIsChecked] = React.useState(false);

    return (
        <Container maxWidth={false} disableGutters={true}>

            <Box sx={{ bgcolor: "#FFA500" }}>
                <Grid container justifyContent="center" alignItems="center">

                    <Grid xs onClick={goToHome} style={{ cursor: 'pointer' }}>
                        <img src={logo} width="200" style={{ marginTop: 5 }} />
                    </Grid>

                    <Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            marginRight="20px"
                            style={{ color: 'white' }}
                        >
                            {strings.english}
                            <Switch
                                checked={strings.getLanguage() == 'hi'}
                                onChange={switchChanged}
                                checked={isChecked}

                            /> {strings.hindi}

                            <a onClick={handleLogOut} style={{ fontSize: 12, marginLeft: 50, cursor: 'pointer' }}>
                                Logout</a>

                            <Badge badgeContent={0}
                                color="secondary"
                                showZero
                                style={{ marginRight: 20 }}
                                onClick={goToNotifications}>
                                <NotificationsIcon onClick={goToNotifications}
                                    style={{ marginLeft: 30, fontSize: 25 }} />
                            </Badge>


                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <div style={{ margin: 10 }}>

                <h2>Notifications</h2>

            </div>


        </Container>

    );
};

const mapStateToProps = (state) => ({
    server: state.auth.server,
    access_token: state.auth.access_token,
    role: state.auth.role[0],
    point: state.auth.point,
  });
  
export default connect(mapStateToProps, { logout })(withRouter(Notifications));
