import React, { useState, useEffect, useRef } from "react";
import "../Acoounts/Account.css";
import { Button, Card } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import ReactToPrint from 'react-to-print';
import { strings } from "../../string.js";
import { Image, Breathing } from 'react-shimmer'
import Header from "../Home/Header";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { isMobile } from "react-device-detect";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const PastOrders = ({ history, server, access_token, login, role, auth, logout }) => {

  const [orders, setOrders] = React.useState([]);
  const [isDetailVisible, setIsDetailVisible] = useState(false)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [user, setUser] = useState(null)

  const [tabIndex, setTabIndex] = React.useState("0");

  const handleChange = (e, index) => {

    setTabIndex(index)

    if (index == 0) {
      history.push('/dashboard')
    }

    if (index == 1) {
      history.push('/new-order')
    }

    if (index == 2) {
      history.push('/cart')
    }

    if (index == 3) {
      history.push('/past-orders')
    }

    if (index == 4) {
      history.push('/account')
    }

  }

  const [isLoading, setIsLoading] = useState(false)
  const componentRef = useRef();

  useEffect(() => {

    getOrders();

  }, []);

  const viewDetails = (order) => {

    setIsDetailVisible(true);
    setCurrentOrder(order);
  }


  const getOrders = () => {

    setIsLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch('https://arihant.trikodev.xyz/' + 'api/user/get-user-settings', requestOptions)
      .then(response => response.json())
      .then(data => setUser(data.user));

    fetch('https://arihant.trikodev.xyz/' + 'api/user/get-my-orders', requestOptions)
      .then(response => response.json())
      .then(data => setTheOrders(data.orders));

  };

  const setTheOrders = (orders) => {
    setOrders(orders);
    setIsLoading(false);
  }

  const backToOrders = () => {

    setIsDetailVisible(false)

  }


  return (

    <>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

        <Header />

        <div className="only-desktop" style={{
          flexDirection: 'row', marginTop: 60,
          position: 'fixed', top: 0, left: 0, right: 0, zIndex: 8000, backgroundColor: '#FCF5E5', marginBottom: 120
        }}>

          <Link to="/dashboard" style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            width: '20%', borderRadius: 0, textDecoration: 'none', height: 60,
            cursor: 'pointer',
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              color: '#000000', fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.dashboard}</span>
          </Link>

          <Link to="/new-order" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', fontSize: 15,
              textTransform: 'uppercase', fontWeight: 700, color: '#000000'
            }}>{strings.new_order}</span>
          </Link>

          <Link to="/cart" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', color: '#000000', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.cart}</span>
          </Link>

          <Link to="/past-orders" style={{
            display: 'flex', borderRadius: 0, color: '#F05225', textDecoration: 'none',
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '4px solid #F05225'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.past_orders}</span>
          </Link>

          <Link to="/account" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none',
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.account}</span>
          </Link>


        </div>

        {isLoading ?
          <div style={{ marginTop: isMobile?150: 200, marginLeft: isMobile?10: '20%', 
          marginRight: isMobile?10: '20%', display: 'flex', flexDirection: 'column' }}>
            <Skeleton height={80} />
            <Skeleton height={20} count={20} />

          </div>
          :

          <div className="orderDetailPage">
            {orders.length == 0 ?

              <p style={{ textAlign: 'center', marginTop: 100 }}>
                {strings.you_have_not_placed_any_order_yet}</p>
              :
              <div className="main-acc"
                style={{
                  display: 'flex', width: '100%', marginBottom: 30,
                  flexDirection: 'column', alignsItems: "center"
                }}>

                {!isDetailVisible ?
                  <div style={{
                    display: 'flex', margin: 0,
                    width: '100%', flexDirection: 'column', alignsItems: "center"
                  }}>
                    <h2 style={{ alignSelf: isMobile?'flex-start':'center',
                  marginLeft:isMobile?20:0 }}>{strings.past_orders}</h2>

                    <div className="cartTableParent">

                      <table className="orderReview"
                        style={{
                          width: '100%', marginBottom: 70, overflow:'scroll',
                          border: '1 px solid grey', borderCollapse: 'collapse', alignSelf: 'center'
                        }}>

                        <thead style={{
                          backgroundColor: '#f5f5f5',
                          borderWidth: 2, borderColor: 'black',
                          height: 50, paddingTop: 10, paddingBottom: 10
                        }}>
                          <tr>
                            <th>#</th>
                            <th>{strings.order_total}</th>
                            <th>{strings.placed_on}</th>
                            <th>{strings.status}</th>
                            <th>{strings.action}</th>
                          </tr>

                        </thead>

                        <tbody>

                          {orders.map((order, index) => (

                            <tr style={{ backgroundColor: index % 2 == 0 ? '#FFFAFA' : '#F8F8FF' }}>
                              <td>{order.id}</td>
                              <td><b>₹</b>{order.order_total.toFixed(2)}</td>
                              <td>{new Date(order.created_at).toLocaleDateString()}</td>
                              <td>{order.status}</td>

                              <td style={{display:'flex', alignItems:'center'}}>
                                <button
                                  className="btn9"
                                  style={{ borderRadius: 5, marginTop: 0, width:'80%', alignSelf:'center', textAlign:'center' }}
                                  onClick={() => viewDetails(order)}
                                >
                                  {strings.view_order}
                                </button>
                              </td>
                            </tr>
                          ))}

                        </tbody>
                      </table>

                    </div>

                  </div>
                  :


                  <div style={{ marginBottom: 50, }}>

                    <div className="main-div" ref={componentRef}
                      style={{ display: 'flex', flexDirection: 'column', marginBottom:80 }}>


                      <h3 style={{ marginLeft: isMobile ? 10 : 30 }}>{strings.order} #0000{currentOrder.id}</h3>

                      <div className="cartTableParent1" style={{ overflow:'scroll'  }}>

                        <table className="orderReview"
                          border="0"
                          style={{
                            width: 'auto',
                            border: '0 px solid grey',
                            borderCollapse: 'collapse',
                            marginTop: 10,
                            paddingRight: 10,
                            paddingLeft: 10,
                            width:'auto',
                            marginLeft: isMobile ? 10 : 30,
                            marginRight: 30
                          }}>

                          <thead style={{
                            backgroundColor: '#f5f5f5',
                            borderWidth: 2,
                            borderColor: 'black',
                            height: 80,
                            paddingTop: 10,
                            paddingBottom: 10
                          }}>

                            <tr>
                              <th style={{ width: 60 }}>#</th>
                              <th style={{ width: 200 }}>{strings.category}</th>
                              <th style={{ width: 100 }}>{strings.brand}</th>
                              <th style={{ width: 280 }}>{strings.product}</th>
                              <th style={{ width: 120 }}>{strings.fitment_for}</th>
                              <th style={{ width: 80 }}>{strings.color}</th>
                              <th style={{ width: 80 }}>{strings.model}</th>
                              <th style={{ width: 80 }}>{strings.unit_price}</th>
                              <th style={{ width: 80 }}>{strings.quantity}</th>
                              <th></th>

                            </tr>

                          </thead>

                          <tbody>

                            {currentOrder && currentOrder.order_items.map((item, index) => (

                              <tr style={{ backgroundColor: index % 2 == 0 ? '#FFFAFA' : '#F8F8FF' }}>
                                <td>{index + 1}</td>
                                <td>{item.category}</td>
                                <td>{item.brand}</td>
                                <td>{strings.getLanguage() == 'en' ? item.product.name : item.product.name_in_hindi}</td>
                                <td>{(item.fitment_for && item.fitment_for !== 'null') ? item.fitment_for : '---'}</td>

                                <td>

                                  {item.color && item.color!=='null' && item.color !=='Null' ?
                                  
                                      <span>{item.color}</span>                                      
                                  :
                                  '---'
                                  }
                                  
                                </td>

                                <td>

                                  {item.model && item.model!=='null' && item.model !=='Null' ?
                                  
                                      <span>{item.model}</span>                                      
                                  :
                                  '---'
                                  }
                                  
                                </td>

                                <td><b>₹</b>{item.unit_price}</td>
                                <td>{item.quantity}</td>

                              </tr>
                            ))}


                          </tbody>
                        </table>

                        <h3 style={{marginTop:20, marginLeft:30}}>Items that could not be found</h3>


{currentOrder &&
<table className="unavailableItemsTable"
       style={{backgroundColor:'#F4F4FA', padding:10, marginLeft:30}}>
         <thead>
             <th style={{textAlign:'left'}}>Item Name</th>
             <th style={{textAlign:'left'}}>Quantity</th>
         </thead>

         <tbody>
          {currentOrder.custom_item_name_1 &&
            <tr>
             <td style={{width:600}}> {currentOrder.custom_item_name_1}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_1}
             </td>
            </tr>
          }


          {currentOrder.custom_item_name_2 &&

            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_2}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_2}
             </td>
            </tr>
          }


          {currentOrder.custom_item_name_3 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_3}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_3}
             </td>
            </tr>
          }

          {currentOrder.custom_item_name_4 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_4}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_4}
             </td>
            </tr>
          }

          {currentOrder.custom_item_name_5 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_5}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_5}
             </td>
            </tr>
          }

          {currentOrder.custom_item_name_6 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_6}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_6}
             </td>
            </tr>
          }


          {currentOrder.custom_item_name_7 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_7}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_7}
             </td>
            </tr>
          }

          {currentOrder.custom_item_name_8 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_8}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_8}
             </td>
            </tr>
          }


          {currentOrder.custom_item_name_9 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_9}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_9}
             </td>
            </tr>
            }


          {currentOrder.custom_item_name_10 &&
            <tr>
             <td style={{width:600}}>{currentOrder.custom_item_name_10}
             </td>
             <td style={{width:100}}>{currentOrder.custom_item_qty_10}
             </td>
            </tr>
          }

         </tbody>
     </table>
}

                      </div>

                      <div style={{width:isMobile?'80%':1030, display:'flex', 
                       flexDirection:isMobile?'column':'row'}}>

                        <div style={{
                          marginBottom: 20,
                          marginRight: 50,marginTop: 50, marginLeft: isMobile ? 20 : 40,
                          width:isMobile?'100%':500
                        }}>
                          <span style={{  }}><b>
                            {strings.order_comments}: </b>
                            {currentOrder.order_comments ? currentOrder.order_comments : 'None'}</span><br /><br />
                          <span><b>{strings.mode_of_delivery}: </b>{currentOrder.mode_of_delivery}</span>

                        </div>

                        <div style={{
                          display:'flex', flexDirection:isMobile?'column':'column',
                          marginBottom: 20, marginTop: isMobile ? 30 : 0,
                          marginRight: 0,alignSelf:isMobile?'flex-start':'flex-end', 
                          width:isMobile?'100%':500

                        }}>

                          <h3 style={{ alignSelf:isMobile?'flex-start':'flex-end',
                           marginLeft:isMobile?20:0,
                           textAlign:isMobile?'left':'right' }}>
                            {strings.order_total} : <b>₹</b>{currentOrder.order_total.toFixed(2)}</h3>                         

                        </div>
                      </div>
                    </div>

                    {user && user.show_print_order == 1 &&
                            <ReactToPrint
                              documentTitle={strings.order + '#000' + currentOrder.id + '.pdf'}
                              trigger={() => <button className="btn9"
                                pageStyle={pageStyle}
                                style={{
                                  width: 250, marginRight: 0, borderRadius: 5,
                                  float: isMobile?'left':'left',
                                  marginLeft:23,
                                  alignSelf: isMobile?'flex-start':'flex-end',
                                  flexDirection:'row'
                                }}>
                                <LocalPrintshopIcon/> &nbsp;&nbsp;{strings.print_order}</button>}
                              content={() => componentRef.current}
                            />
                          }

                          <button className="btn9 orderDetailBackButton" 
                                  style={{ borderRadius: 5, flexDirection:'row'
                                   }}
                            onClick={backToOrders}
                          >
                           <ArrowBackIcon/> &nbsp; &nbsp; {strings.back_to_orders}
                          </button>                    
                  </div>
                }
              </div>
            }

          </div>
        }

      </div>
      <Box
        sx={{
          display: { xs: "block", md: "none", lg: "none" },
        }}
      >
        <BottomNavigation
          sx={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            zIndex: 1000,
            left: 0,
            backgroundColor: '#f0f0f0',
            right: 0,
          }}
          value={"3"}
          showLabels
          onChange={handleChange}
          elevation={3} s
        >
          <BottomNavigationAction
            label="DASHBOARD"
            value="0"
            sx={{fontSize:35, fontWeight:700}}
            icon={<DashboardIcon />}
          />
          <BottomNavigationAction
            label="NEW ORDER"
            value="1"
            sx={{fontSize:35, fontWeight:700}}
            icon={<AddBoxIcon />}
          />
          <BottomNavigationAction
            label="CART"
            value="2"
            sx={{fontSize:35, fontWeight:700}}
            icon={<ShoppingCartIcon />}
          />
          <BottomNavigationAction
            label="ORDERS"
            value="3"
            sx={{fontSize:35, fontWeight:700}}
            icon={<ShoppingBagIcon />}
          />

        </BottomNavigation>
      </Box>
    </>
  );
};


const pageStyle = `
  @page {
    size: 210mm 148mm;
    padding:5px;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, {})(withRouter(PastOrders));