import React, { useState, useEffect } from "react";
//import Carousel from "react-elastic-carousel";
import Grid from "@mui/material/Grid";
import { strings } from "../../string";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Image, Breathing } from 'react-shimmer'
import { isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Rating } from "@mui/material";
import ReactStars from "react-rating-stars-component";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ReactImageZoom from 'react-image-zoom';
import CloseIcon from '@mui/icons-material/Close';
import placeholder from "../../assets/img/placeholder.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
//import { Carousel } from 'react-responsive-carousel';
//import Carousel from 'react-material-ui-carousel'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const customStyle = {
  margin: "0px",
  padding: isMobile?0:"10px 10px",
  border: "2px solid #EEEEEE",
  backgroundColor: "#fff",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "center",
  alignSelf:'center',  
  borderRadius: 10,
  width: isMobile ? '100%' : 200,
  height: isMobile?'100%':270,
  display: 'flex',
  flexDirection: 'column',
  marginTop:isMobile?0:15,
  marginLeft:isMobile?0:15,
  marginBottom:15
}


function UpcomingProducts(props) {

  const [items, setItems] = useState([
    {
      id: 1,
      url: "https://rukminim1.flixcart.com/image/416/416/khmbafk0-0/helmet/x/t/m/sbh-17-terminator-full-face-graphic-helmet-in-matt-black-fluo-original-imafxhedgn57jgvd.jpeg?q=70",
    },
    {
      id: 2,
      url: "https://rukminim1.flixcart.com/image/612/612/kdt50nk0-0/helmet/o/a/9/crux-of-open-face-crx-of-r-open-face-vega-original-imafuhyuc7vvr7hh.jpeg?q=70",
    },
    {
      id: 3,
      url: "https://rukminim1.flixcart.com/image/612/612/jtiz0cw0/helmet/c/x/x/off-road-1-or-d-v-dk-58-motocross-vega-original-imafevfhm4uttsyj.jpeg?q=70",
    },
    {
      id: 4,
      url: "https://rukminim1.flixcart.com/image/612/612/khmbafk0-0/helmet/w/o/5/sbh-17-terminator-full-face-graphic-helmet-in-fluo-matt-black-original-imafxhe9pbxukrht.jpeg?q=70",
    },
    {
      id: 5,
      url: "https://rukminim1.flixcart.com/image/612/612/kapoo7k0/helmet/h/z/w/new-1-824-56-open-face-nivia-original-imafs82jfadh3rut.jpeg?q=70",
    },
  ]
  );

  const [upcomingProducts, setUpcomingProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [areArrowsVisible, setAreArrowsVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [photo, setPhoto] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setOpen(false);


  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];


  const responsive = {
    
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  useEffect(() => {
    getUpcomingProducts();
  }, []);

  const showModal = (item) => {

    setCurrentProduct(item);
    setOpen(true);
  }

  const showArrows = () => {
    setAreArrowsVisible(true)
  }


  const setNotification = () => {

    toast(strings.you_will_be_notified, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const hideArrows = () => {
    setAreArrowsVisible(false)
  }



  const showLightBox = (url) => {
    setIsOpen(true);
    setPhoto(url)
  }


  const getUpcomingProducts = () => {


    setIsLoading(true)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + props.access_token
      }
    };

    fetch(
      'https://arihant.trikodev.xyz' + "/api/user/get-upcoming-products", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setUpcomingProducts(json.products)
        setIsLoading(false)

      })
  };





  var theProducts = [];
  var addedProductNames = [];

  for (var i = 0; i < upcomingProducts.length; i++) {

    if (!addedProductNames.includes(upcomingProducts[i].name)) {
      theProducts.push(upcomingProducts[i]);

      if (strings.getLanguage() == 'en') {
        addedProductNames.push(upcomingProducts[i].name);
      } else {
        addedProductNames.push(upcomingProducts[i].name_in_hindi);
      }

    }


  }

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(theProducts.length);
    }
  };


  return (
    <>
      <div style={{padding:10}}>
        {isLoading ?
          <Breathing width={'100%'} height={150} />

          :
          <div onMouseEnter={showArrows}
            onMouseLeave={hideArrows} style={{width:'98%', marginLeft:'1%'}}>
            <Carousel
              responsive={responsive}
              ref={carouselRef}
              infiniteLoop={true}
              infinite={true}
              showArrows={true}
              showThumbs={false}
              enableAutoPlay
              centerMode={false}
              onPrevStart={onPrevStart}
              onNextStart={onNextStart}
              disableArrowsOnEnd={false}
              breakPoints={breakPoints}>
              {theProducts.map((item) => (
                <div key={item.id} style={customStyle}>

                  <img className="square"
                    style={{ width: isMobile ? '60%' : 200, maxHeight: 200, 
                    alignSelf: 'center', objectFit:'contain', marginTop:5 }}
                    src={item.product_image_path!==null?'https://arihant.trikodev.xyz' + '/' + item.product_image_path:placeholder} 
                    alt="" height={156} width={156} />

                  <span style={{
                    textAlign: 'center', fontSize: 16, marginTop: 15,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>
                    {strings.getLanguage() == 'en' ? item.name : item.name_in_hindi}
                  </span>
                  <span style={{ textAlign: 'center', fontSize: 12 }}>₹{item.price}</span>
                  <button className="notify" 
                  style={{ alignSelf: 'center', marginLeft: 0, marginTop:20, backgroundColor:'#F05225' }}
                    onClick={() => showModal(item)}>{strings.notify_when_available}</button>
                </div>
              ))}
            </Carousel>
          </div>

        }

        <Modal
          open={open}
          onClose={handleClose}
          style={{zIndex:50000}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {currentProduct &&
            <div className="background">
              <div className="modal-container" style={{ width: '100%' }}>

                <CloseIcon style={{ float: 'right', marginRight:-20, marginTop:-5 }} 
                           onClick={handleClose} />

                <div className="main modalProduct">

                  <img className="square"
                    style={{ width: isMobile ? '100%' : 200, textAlign: 'center', objectFit:'contain' }}
                    src={currentProduct.product_image_path!==null?'https://arihant.trikodev.xyz' + '/'
                       + currentProduct.product_image_path:placeholder}
                  />


                  <div className="modalRightArea" 
                     style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="text-modal">
                      <h2 style={{ lineHeight: 1, paddingBottom: 0, marginBottom: 0 }}>
                        {strings.getLanguage() == 'en' ? currentProduct.name : currentProduct.name_in_hindi}</h2>
                    </div>

                    <div className="ratings" style={{marginLeft:3}}>
                      <ReactStars
                        count={5}
                        size={24}
                        value={currentProduct.rating_in_stars}
                        isHalf={true}
                        edit={false}
                        activeColor="#ffd700"
                      />
                    </div>


                    <div className="Buttons" style={{ marginTop: 10 }}>
                      <button className="cat">{currentProduct.category.name}</button>
                      <button className="brand ">{currentProduct.brand.name}</button>
                    </div>
                    <div className="text-modal">
                      <h5>Product Description</h5>
                      <p>
                        {currentProduct.description}
                      </p>
                    </div>

                    <div className="Notify-me">

                    <button className="btn9"
                       onClick={setNotification}
                      style={{ alignSelf: 'center', marginLeft: 0, paddingTop:5,
                       backgroundColor:'#F05225' }}>{strings.notify_when_available}</button>
                      
                    </div>

                  </div>
                </div>
              </div>
            </div>
          }
        </Modal>




      </div>
    </>
  );
}
export default UpcomingProducts;
