import React from "react";

const Mychoice = () => {
    return (
        <>
        <p>I am inside my choice</p>
        </>
    )
}

export default Mychoice;