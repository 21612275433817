import React, { useState, Fragment, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { Button, Card, TextField } from "@material-ui/core";
import CardContent from "@mui/material/CardContent";
import Pizza from "../Home/Pizza.jpg";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import { strings } from "../../string.js";
import Neworder4 from "../Neworder/NewOrder4";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Header from "../Home/Header";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import placeholder from "../../assets/img/placeholder.png";
import emptyCart from "../../assets/img/emptycart.png";
import UnavailableItemsEntry from '../../UnavailableItemsEntry';

const customStyle1 = {
  margin: "20px",
  padding: "30px 40px",
  border: "2px solid #D6B1B1",
  backgroundColor: "#D6B1B1",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "center",
  width: 100
}

const customStyle2 = {
  margin: "20px",
  padding: "30px 40px",
  border: "2px solid #B2ACFA",
  backgroundColor: "#B2ACFA",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "center",
  width: 100
}


const Cart = ({ history, server, access_token, login, role, auth, logout }) => {

  const initialCount = 1;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [count, setCount] = useState(initialCount);
  const [value, setValue] = React.useState(2);
  const [theCartData, setTheCartData] = useState([])
  const [cartTotal, setCartTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [numCart, setNumCart] = useState(0)

  const [underReview, setUnderReview] = useState(false);

  const [products, setProducts] = React.useState([]);
  const [itemToDelete, setItemToDelete] = useState(null)

  const [tabIndex, setTabIndex] = React.useState("0");

  const [customItemName1, setCustomItemName1] = React.useState('');
  const [customItemName2, setCustomItemName2] = React.useState('');
  const [customItemName3, setCustomItemName3] = React.useState('');
  const [customItemName4, setCustomItemName4] = React.useState('');
  const [customItemName5, setCustomItemName5] = React.useState('');
  const [customItemName6, setCustomItemName6] = React.useState('');
  const [customItemName7, setCustomItemName7] = React.useState('');
  const [customItemName8, setCustomItemName8] = React.useState('');
  const [customItemName9, setCustomItemName9] = React.useState('');
  const [customItemName10, setCustomItemName10] = React.useState('');
  const [customItemName11, setCustomItemName11] = React.useState('');
  const [customItemName12, setCustomItemName12] = React.useState('');
  const [customItemName13, setCustomItemName13] = React.useState('');
  const [customItemName14, setCustomItemName14] = React.useState('');
  const [customItemName15, setCustomItemName15] = React.useState('');
  const [customItemQuantity1, setCustomItemQuantity1] = React.useState('');
  const [customItemQuantity2, setCustomItemQuantity2] = React.useState('');
  const [customItemQuantity3, setCustomItemQuantity3] = React.useState('');
  const [customItemQuantity4, setCustomItemQuantity4] = React.useState('');
  const [customItemQuantity5, setCustomItemQuantity5] = React.useState('');
  const [customItemQuantity6, setCustomItemQuantity6] = React.useState('');
  const [customItemQuantity7, setCustomItemQuantity7] = React.useState('');
  const [customItemQuantity8, setCustomItemQuantity8] = React.useState('');
  const [customItemQuantity9, setCustomItemQuantity9] = React.useState('');
  const [customItemQuantity10, setCustomItemQuantity10] = React.useState('');
  const [customItemQuantity11, setCustomItemQuantity11] = React.useState('');
  const [customItemQuantity12, setCustomItemQuantity12] = React.useState('');
  const [customItemQuantity13, setCustomItemQuantity13] = React.useState('');
  const [customItemQuantity14, setCustomItemQuantity14] = React.useState('');
  const [customItemQuantity15, setCustomItemQuantity15] = React.useState('');

  useEffect(() => {

    refreshCustomItemsTable();   

  }, []);


  

  const refreshCustomItemsTable = () => {

    var unavailableItemName1 = localStorage.getItem('unavailable_item_name1');
    var unavailableItemName2 = localStorage.getItem('unavailable_item_name2');
    var unavailableItemName3 = localStorage.getItem('unavailable_item_name3');
    var unavailableItemName4 = localStorage.getItem('unavailable_item_name4');
    var unavailableItemName5 = localStorage.getItem('unavailable_item_name5');
    var unavailableItemName6 = localStorage.getItem('unavailable_item_name6');
    var unavailableItemName7 = localStorage.getItem('unavailable_item_name7');
    var unavailableItemName8 = localStorage.getItem('unavailable_item_name8');
    var unavailableItemName9 = localStorage.getItem('unavailable_item_name9');
    var unavailableItemName10 = localStorage.getItem('unavailable_item_name10');
    var unavailableItemName11 = localStorage.getItem('unavailable_item_name11');
    var unavailableItemName12 = localStorage.getItem('unavailable_item_name12');
    var unavailableItemName13 = localStorage.getItem('unavailable_item_name13');
    var unavailableItemName14 = localStorage.getItem('unavailable_item_name14');
    var unavailableItemName15 = localStorage.getItem('unavailable_item_name15');

    var unavailableItemQuantity1 = localStorage.getItem('unavailable_item_quantity1');
    var unavailableItemQuantity2 = localStorage.getItem('unavailable_item_quantity2');
    var unavailableItemQuantity3 = localStorage.getItem('unavailable_item_quantity3');
    var unavailableItemQuantity4 = localStorage.getItem('unavailable_item_quantity4');
    var unavailableItemQuantity5 = localStorage.getItem('unavailable_item_quantity5');
    var unavailableItemQuantity6 = localStorage.getItem('unavailable_item_quantity6');
    var unavailableItemQuantity7 = localStorage.getItem('unavailable_item_quantity7');
    var unavailableItemQuantity8 = localStorage.getItem('unavailable_item_quantity8');
    var unavailableItemQuantity9 = localStorage.getItem('unavailable_item_quantity9');
    var unavailableItemQuantity10 = localStorage.getItem('unavailable_item_quantity10');
    var unavailableItemQuantity11 = localStorage.getItem('unavailable_item_quantity11');
    var unavailableItemQuantity12 = localStorage.getItem('unavailable_item_quantity12');
    var unavailableItemQuantity13 = localStorage.getItem('unavailable_item_quantity13');
    var unavailableItemQuantity14 = localStorage.getItem('unavailable_item_quantity14');
    var unavailableItemQuantity15 = localStorage.getItem('unavailable_item_quantity15');

    setCustomItemName1(unavailableItemName1);
    setCustomItemName2(unavailableItemName2);
    setCustomItemName3(unavailableItemName3);
    setCustomItemName4(unavailableItemName4);
    setCustomItemName5(unavailableItemName5);
    setCustomItemName6(unavailableItemName6);
    setCustomItemName7(unavailableItemName7);
    setCustomItemName8(unavailableItemName8);
    setCustomItemName9(unavailableItemName9);
    setCustomItemName10(unavailableItemName10);
    setCustomItemName11(unavailableItemName11);
    setCustomItemName12(unavailableItemName12);
    setCustomItemName13(unavailableItemName13);
    setCustomItemName14(unavailableItemName14);
    setCustomItemName15(unavailableItemName15);

    setCustomItemQuantity1(unavailableItemQuantity1);
    setCustomItemQuantity2(unavailableItemQuantity2);
    setCustomItemQuantity3(unavailableItemQuantity3);
    setCustomItemQuantity4(unavailableItemQuantity4);
    setCustomItemQuantity5(unavailableItemQuantity5);
    setCustomItemQuantity6(unavailableItemQuantity6);
    setCustomItemQuantity7(unavailableItemQuantity7);
    setCustomItemQuantity8(unavailableItemQuantity8);
    setCustomItemQuantity9(unavailableItemQuantity9);
    setCustomItemQuantity10(unavailableItemQuantity10);
    setCustomItemQuantity11(unavailableItemQuantity11);
    setCustomItemQuantity12(unavailableItemQuantity12);
    setCustomItemQuantity13(unavailableItemQuantity13);
    setCustomItemQuantity14(unavailableItemQuantity14);
    setCustomItemQuantity15(unavailableItemQuantity15);

  }


  const handleChange = (e, index) => {

    setTabIndex(index)

    if (index == 0) {
      history.push('/dashboard')
    }

    if (index == 1) {
      history.push('/new-order')
    }

    if (index == 2) {
      history.push('/cart')
    }

    if (index == 3) {
      history.push('/past-orders')
    }

    if (index == 4) {
      history.push('/account')
    }

  }

  const options = {

    title: 'Alert',
    message: 'Are you sure you want to remove this item from cart?',
    buttons: [
      {
        label: 'Yes',
        onClick: () => removeFromCart()
      },
      {
        label: 'No',
      }
    ],
    childrenElement: () => <div />,
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypressEscape: () => { },
    overlayClassName: "overlay-custom-class-name"
  };


  const handleAccept = () => {
    history.push('/order-preview')
  };


  const askToRemoveFromCart = async (item) => {
    

    removeFromCart(item);
  }

  useEffect(() => {

    getProducts();
  }, []);

  useEffect(() => {

  }, [theCartData]);



  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  useEffect(() => {


  }, [itemToDelete]);

  const comeBack = async () => {
    setUnderReview(false);
  }

  const getProducts = () => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch(
      'https://arihant.trikodev.xyz' +"/api/user/get-all-products", requestOptions)
      .then((res) => res.json())
      .then((json) => {
        setProducts(json.products)
      })

  };


  useEffect((async) => {

    (async () => {

      try {

        getCartData();
        var num = localStorage.getItem('num_of_items_in_cart');

        if (num == null) {
          setNumCart(0);
        } else if (num == '0') {
          setNumCart(0);
        } else {
          setNumCart(num);
        }


      } catch (error) {
        console.log(error);
      }


    })();


  }, []);




  // Increase cart item qty
  const plusCart = (item) => {

    var indexOfTheCartItem = item.index;

    var itemQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);

    itemQty = parseInt(itemQty);

    // Increment the quantity of the cart item and set state
    itemQty = itemQty + 1;
    localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, itemQty);

    var newQty = itemQty + 1;

    var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');

    prevNumOfCartItems = parseInt(prevNumOfCartItems);
    prevNumOfCartItems = prevNumOfCartItems + 1;

    localStorage.setItem('num_of_items_in_cart', prevNumOfCartItems);
    setNumCart(prevNumOfCartItems);

    // Check the quantity and set the price accordingly
    var price = item.product_price;

    localStorage.setItem('cart_item_product_price_' + indexOfTheCartItem, price);

    getCartData();

  };


  const plusHandler = async (item, countValue) => {

    plusCart(item)

  }


  // Remove from cart
  const removeFromCart = async (item) => {


    var indexOfTheCartItem = item.index;

    localStorage.setItem('cart_item_product_status_' + indexOfTheCartItem, 'inactive');

    var prevQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);
    prevQty = parseInt(prevQty);


    var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');
    prevNumOfCartItems = parseInt(prevNumOfCartItems);

    var newNumOfCartItems = prevNumOfCartItems - prevQty;

    localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, 0);
    localStorage.setItem('num_of_items_in_cart', newNumOfCartItems);

    setNumCart(newNumOfCartItems);

    getCartData();
  };

  const addItemToCart = async (productID, productName, unitPrice, category, color, model, size, brand) => {

    try {

      // Check if this item is already available in the local storage cart data
      // If, yes just increment the quantity by 1.
      // Else, add a new cart item with incremented index and set the data
      var highestCartIndex = localStorage.getItem('highest_cart_index');


      if (highestCartIndex == null) {
        highestCartIndex = '0';
      }

      // Quantity based pricing
      var price = unitPrice;

      if (highestCartIndex == '0') {


        localStorage.setItem('cart_item_product_id_1', productID);
        localStorage.setItem('cart_item_product_name_1', productName);
        localStorage.setItem('cart_item_product_price_1', price);
        localStorage.setItem('cart_item_product_qty_1', 1);
        localStorage.setItem('cart_item_product_status_1', 'active');
        localStorage.setItem('cart_item_product_category_1', category);
        localStorage.setItem('cart_item_product_color_1', color);
        localStorage.setItem('cart_item_product_model_1', model);
        localStorage.setItem('cart_item_product_size_1', size);
        localStorage.setItem('cart_item_product_brand_1', brand);

        localStorage.setItem('num_of_items_in_cart', 1);
        localStorage.setItem('highest_cart_index', 1);

      } else {

        var isItemInCart = 0;

        for (var i = 1; i <= (parseInt(highestCartIndex)); i++) {

          // Check if the current product is there in the cart
          // if there, increment its quantity
          var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
          var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
          var cartItemProductPrice = localStorage.getItem('cart_item_product_price_' + i);
          var cartItemProductQty = localStorage.getItem('cart_item_product_qty_' + i);

          if (cartItemProductId == '' + productID) {

            var numOfItemsInCart = localStorage.getItem('num_of_items_in_cart');

            var cartItemProductQty = parseInt(cartItemProductQty) + 1;

            localStorage.setItem('cart_item_product_qty_' + i, (parseInt(cartItemProductQty)) + '');
            localStorage.setItem('cart_item_product_status_' + i, 'active');
            localStorage.setItem('cart_item_product_price_' + i, '' + price);

            localStorage.setItem('cart_item_product_category_' + i, category);
            localStorage.setItem('cart_item_product_color_' + i, color);
            localStorage.setItem('cart_item_product_model_' + i, model);
            localStorage.setItem('cart_item_product_size_' + i, size);
            localStorage.setItem('cart_item_product_brand_' + i, brand);

            localStorage.setItem('num_of_items_in_cart', (parseInt(numOfItemsInCart) + 1) + '');

            isItemInCart = 1;
          }

        }

        // If the item was not found in cart
        if (isItemInCart == 0) {


          highestCartIndex = parseInt(highestCartIndex) + 1;

          localStorage.setItem('cart_item_product_id_' + highestCartIndex, productID);
          localStorage.setItem('cart_item_product_name_' + highestCartIndex, productName);
          localStorage.setItem('cart_item_product_price_' + highestCartIndex, price);

          localStorage.setItem('cart_item_product_qty_' + highestCartIndex, 1);
          localStorage.setItem('cart_item_product_status_' + highestCartIndex, 'active');

          var numOfItemsInCart = localStorage.getItem('num_of_items_in_cart');

          localStorage.setItem('num_of_items_in_cart', (parseInt(numOfItemsInCart) + 1));
          localStorage.setItem('highest_cart_index', (parseInt(highestCartIndex)));

        } else {

        }

      }

      //props.refreshCartQty();
      getCartData();
    }
    catch (error) {
      //alert('aa' + error)
    }

  };

  const getCartData = async (json) => {


    try {

      var highestCartIndex = localStorage.getItem('highest_cart_index');

      var cartData = [];

      var theSubTotal = 0;
      var theCartTotal = 0;

      for (var i = 1; i <= parseInt(highestCartIndex); i++) {


        var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
        var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
        var cartItemTallyImportName = localStorage.getItem('cart_item_tally_import_name_' + i);

        
        var cartItemPrice = localStorage.getItem('cart_item_product_price_' + i);
        var cartItemQty = localStorage.getItem('cart_item_product_qty_' + i);
        var cartItemStatus = localStorage.getItem('cart_item_product_status_' + i);
        var cartItemCategory = localStorage.getItem('cart_item_product_category_' + i);
        var cartItemColor = localStorage.getItem('cart_item_product_color_' + i);
        var cartItemModel = localStorage.getItem('cart_item_product_model_' + i);
        var cartItemSize = localStorage.getItem('cart_item_product_size_' + i);
        var cartItemBrand = localStorage.getItem('cart_item_product_brand_' + i);
        var fitmentFor = localStorage.getItem('cart_item_product_fitment_for_' + i);
        var fitmentName = localStorage.getItem('cart_item_product_fitment_name_' + i);
        var type = localStorage.getItem('cart_item_product_type_' + i);
        var productBucket = localStorage.getItem('cart_item_product_bucket_' + i);
        var bucketPrice = localStorage.getItem('cart_item_bucket_price_' + i);
        var productImage = localStorage.getItem('cart_item_product_image_' + i);

        if (cartItemStatus == 'active') {

          cartData.push({

            'product_id': cartItemProductId,
            'product_name': cartItemProductName,
            'tally_import_name':cartItemTallyImportName,
            'product_price': cartItemPrice,
            'qty': cartItemQty,
            'index': i,
            'status': cartItemStatus,
            'category': cartItemCategory,
            'color': cartItemColor,
            'model': cartItemModel,
            'size': cartItemSize,
            'brand': cartItemBrand,
            'fitment_for': fitmentFor,
            'fitment_name': fitmentName,
            'fitment_type': type,
            'product_bucket': productBucket,
            'bucket_price': bucketPrice,
            'product_image_path': productImage,
          });

          var lineTotal = parseFloat(cartItemPrice) * parseInt(cartItemQty);

          theSubTotal = theSubTotal + parseFloat(lineTotal);
          theCartTotal = theCartTotal + parseFloat(lineTotal);
        }

      }


      setCartTotal(theCartTotal);
      setSubTotal(theSubTotal);

      setTheCartData(cartData);

    } catch (error) {
      //console.log(error);
    }


  };

  const changeQty = async (e, item) => {


    if (e.target.value == '') {

      // In this case, modify the in-memory version of theCartData, but not the 
      // one saved in localstorage
      var indexOfTheCartItem = e.target.name;

      var tempCartData = [];
      for (var i = 0; i < theCartData.length; i++) {

        if (theCartData[i].index == indexOfTheCartItem) {
          theCartData[i].qty = '';
        }

        tempCartData.push(theCartData[i])
      }

      setTheCartData(tempCartData);
      
    } else {

      if (e.target.value == "0") {
        
        alert('You cannot enter zero quantity. Please delete the item instead');
        //askToRemoveFromCart(item);
        getCartData();

      } else {

        var indexOfTheCartItem = e.target.name;

        var itemQty = e.target.value;
        localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, itemQty);

        var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');

        prevNumOfCartItems = parseInt(prevNumOfCartItems);
        prevNumOfCartItems = itemQty;

        localStorage.setItem('num_of_items_in_cart', prevNumOfCartItems);
        setNumCart(prevNumOfCartItems);

        getCartData();
      }
    }  

  }


  var cartizedProducts = [];


  for (var i = 0; i < products.length; i++) {

    for (var j = 0; j < theCartData.length; j++) {

      if (theCartData[j].product_id == products[i].id) {
        products[i].qty = theCartData[j].qty;
        products[i].index = theCartData[j].index;
        products[i].product_price = theCartData[j].product_price;

      }
    }

    if (products[i].hasOwnProperty("qty") && (products[i].qty > 0 || products[i].qty == '')) {
      cartizedProducts.push(products[i])
    }

  }



  const decrm = () => {
    if (count > 0) {
      setCount(count - 1);
    } else {
      setCount(0);
    }
  };

  const width = 40;

  return (
    <>

      <div style={{ width: '100%' }}>

        <Header />

        <div className="only-desktop" style={{
          flexDirection: 'row', marginTop: 60,
          position: 'fixed', top: 0, left: 0, right: 0, zIndex: 8000, backgroundColor: '#FCF5E5', marginBottom: 120
        }}>

          <Link to="/dashboard" style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            width: '20%', borderRadius: 0, textDecoration: 'none', height: 60,
            cursor: 'pointer',
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', color: '#000000',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.dashboard}</span>
          </Link>

          <Link to="/new-order" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', fontSize: 15,
              textTransform: 'uppercase', fontWeight: 700
            }}>{strings.new_order}</span>
          </Link>

          <Link to="/cart" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '4px solid #F05225'
          }}>

            <span style={{
              width: '100%', color: '#F05225', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.cart}</span>
          </Link>

          <Link to="/past-orders" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none', flexDirection: 'row',
            cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', fontSize: 15,
              textTransform: 'uppercase', fontWeight: 700, color: '#000000'
            }}>{strings.past_orders}</span>
          </Link>

          <Link to="/account" style={{
            display: 'flex', borderRadius: 0, textDecoration: 'none',
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', color: '#000000', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.account}</span>
          </Link>


        </div>

        {!underReview ?
          <div className="cartPage" >


            {theCartData.length > 0 ?

              <div className="cartTableParent1" style={{ marginTop: 20, overflow: 'scroll' }}>

                
                <div style={{
                  borderWidth: 2, borderColor: 'black',
                  marginTop: 30, marginLeft: 20, marginRight: 20, backgroundColor: '#FFFAFA'
                }}>

                  <table className="orderReview"
                    style={{
                      width: '100%',
                      borderCollapse: 'collapse',
                    }}>

                    <thead style={{
                      backgroundColor: '#f5f5f5', borderWidth: 2, borderColor: 'black',
                      height: 50, paddingTop: 10, paddingBottom: 10
                    }}>
                      <tr>
                        <th>#</th>
                        <th></th>
                        <th>{strings.category}</th>
                        <th>{strings.brand}</th>
                        <th>{strings.product}</th>
                        <th>{strings.fitment_for}</th>
                        <th>{strings.color}/{strings.model}</th>
                        <th>{strings.size}</th>
                        <th>{strings.unit_price}</th>
                        <th>{strings.quantity}</th>
                        <th></th>
                      </tr>

                    </thead>

                    <tbody>

                      {theCartData.map((item, index) => (

                        <tr style={{ backgroundColor: index % 2 == 0 ? '#FFFAFA' : '#F8F8FF' }}>
                          <td>{index + 1}</td>
                          <td><img src={item.product_image_path !== 'null' ? 'https://arihant.trikodev.xyz' +'/' + item.product_image_path : placeholder} style={{ width: 50, height: 50, objectFit: 'contain' }} /></td>
                          <td>{item.category}</td>
                          <td>{item.brand}</td>
                          <td>{item.product_name}</td>
                          <td>{item.fitment_for && item.fitment_for!=='null' ? item.fitment_for : '---'}</td>
                          <td>

                            {item.model && !item.color &&
                              <>
                               {item.model!=='null' && item.model !=='Null' &&
                                <span><b>Model : </b>{item.model}</span>
                               }
                              </>
                            }

                            {item.color && !item.model &&
                              <>
                              {item.model!=='null' && item.model !=='Null' &&
                                <span><b>Color : </b>{item.color}</span>
                              }
                              </>
                            }

                            {item.color && item.model &&
                              <>
                              {item.color!=='null' && item.color !=='Null' &&
                                 <span><b>Color : </b>{item.color}</span>
                              }  

                              {item.color!=='null' && item.color !=='Null' && item.model!=='null' && item.model !=='Null' &&
                                <span>&nbsp;</span>
                              }


                              {item.model!=='null' && item.model !=='Null' &&
                                <span><b>Model : </b>{item.model}</span>
                              }
                              </>
                            }
                                                       
                            
                          </td>
                          <td>{(item.size == 'None' || item.size == null || item.size=='null')  ? '---' : item.size}</td>
                          <td><b>₹</b>{item.product_price}</td>
                          <td>
                            <input type="text"
                                   value={item.qty}
                                   name={item.index}                                   
                                   onChange={(e) => changeQty(e, item)}    
                                   style={{
                                       width: 50, border: '1px solid rgba(0,0,0,.125)',
                                       textAlign: 'center',
                                       outline: 'none'
                                   }} />
                          </td>
                          <td>
                            <button
                              onClick={() => { if (window.confirm('Are you sure you want to delete this item from cart?')) { askToRemoveFromCart(item) } }}
                              style={{ backgroundColor: '#fa532c', borderWidth: 0, borderRadius: 5 }}>
                              <DeleteForeverIcon style={{ color: "white", padding: 2, marginTop: 2 }} />
                            </button>
                          </td>

                        </tr>

                      ))}

                    </tbody>

                  </table>

                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:200}}>


                  <div>

                    {(customItemName1 || customItemName2 || customItemName3 || customItemName4 || customItemName5 
                    || customItemName6 || customItemName7 || customItemName8 || customItemName9 || customItemName10 
                    || customItemName11 || customItemName12 || customItemName13 || customItemName14 || customItemName15) &&

                    <>

                  <h3 style={{marginTop:20}}>Items that could not be found</h3>

                  <table className="unavailableItemsTable" style={{backgroundColor:'#F4F4FA', padding:10}} border="0">

                           <thead>
                               <th style={{textAlign:'left'}}>Item Name</th>
                               <th style={{textAlign:'center'}}>Quantity</th>
                           </thead>

                           <tbody>

                           {customItemName1 &&
                              <tr>
                               <td style={{width:600}}> {customItemName1}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity1}
                               </td>
                              </tr>
                          }

                              {customItemName2 &&
                              <tr>
                               <td style={{width:600}}>{customItemName2}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity2}
                               </td>
                              </tr>
                              }

                              {customItemName3 &&
                              <tr>
                               <td style={{width:600}}>{customItemName3}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity3}
                               </td>
                              </tr>
                            }

                            {customItemName4 &&
                              <tr>
                               <td style={{width:600}}>{customItemName4}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity4}
                               </td>
                              </tr>
                            }

                            {customItemName5 &&
                              <tr>
                               <td style={{width:600}}>{customItemName5}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity5}
                               </td>
                              </tr>
                            }

                            {customItemName6 &&
                              <tr>
                               <td style={{width:600}}>{customItemName6}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity6}
                               </td>
                              </tr>
                            }

                              {customItemName7 &&
                              <tr>
                               <td style={{width:600}}>{customItemName7}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity7}
                               </td>
                              </tr>
                              }

                              {customItemName8 &&
                              <tr>
                               <td style={{width:600}}>{customItemName8}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity8}
                               </td>
                              </tr>
                              }

                              {customItemName9 &&
                              <tr>
                               <td style={{width:600}}>{customItemName9}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity9}
                               </td>
                              </tr>
                              }


                              {customItemName10 &&
                              <tr>
                               <td style={{width:600}}>{customItemName10}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity10}
                               </td>
                              </tr>
                              }

                              {customItemName11 &&
                              <tr>
                               <td style={{width:600}}>{customItemName11}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity11}
                               </td>
                              </tr>
                              }

                              {customItemName12 &&
                              <tr>
                               <td style={{width:600}}>{customItemName12}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity12}
                               </td>
                              </tr>
                              }

                              {customItemName13 &&
                              <tr>
                               <td style={{width:600}}>{customItemName13}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity13}
                               </td>
                              </tr>
                              } 

                              {customItemName14 &&
                              <tr>
                               <td style={{width:600}}>{customItemName14}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity14}
                               </td>
                              </tr>
                              }

                              {customItemName15 &&
                              <tr>
                               <td style={{width:600}}>{customItemName15}
                               </td>
                               <td style={{width:100, textAlign:'center'}}>{customItemQuantity15}
                               </td>
                              </tr>
                              }

                           </tbody>
                       </table>

                       </>
            }

                       </div> 
                       

                       {theCartData.length > 0 &&
              <div style={{
                display: 'flex',
                float: 'right',
                 marginBottom: 20, marginTop: 20, marginRight: isMobile?20:50, flexDirection: 'column'
              }}>

                <span style={{ textAlign: 'right' }}>{strings.order_total} : <b>₹</b>{subTotal.toFixed(2)}</span>
                <span style={{
                  textAlign: 'right', color: 'red', fontSize: 12, marginTop: 10
                }}>{strings.gst_applicable_extra}</span>

                <button className="btn9" style={{ width: '100%', marginLeft: 0, borderRadius: 5, marginTop: 30, paddingTop:5 }}
                  onClick={handleAccept}>{strings.preview_order}</button>

              </div>
            }

</div>

                </div>

              </div>
              :

              <div>
                <h3 style={{ textAlign: 'center', marginTop: isMobile ? 130 : 230 }}>
                  {strings.no_items_in_cart}</h3>
                <img src={emptyCart} width={'20%'} style={{ textAlign: 'center', marginLeft: '40%' }} />
              </div>

            }

            
          </div>
          :

          <Neworder4 comeBack={comeBack}
            server={server}
            access_token={access_token} />

        }

      </div>

      <Box
        sx={{
          display: { xs: "block", md: "none", lg: "none" },
        }}
      >
        <BottomNavigation
          sx={{
            width: "100%",
            position: "fixed",
            bottom: 0,
            backgroundColor: '#f0f0f0',
            left: 0,
            zIndex: 1000,
            right: 0,
          }}
          value={"2"}
          showLabels
          onChange={handleChange}
          elevation={3} s
        >
          <BottomNavigationAction
            label="DASHBOARD"
            value="0"
            sx={{fontSize:35, fontWeight:700}}
            icon={<DashboardIcon />}
          />
          <BottomNavigationAction
            label="NEW ORDER"
            value="1"
            sx={{fontSize:35, fontWeight:700}}
            icon={<AddBoxIcon />}
          />
          <BottomNavigationAction
            label="CART"
            value="2"
            sx={{fontSize:35, fontWeight:700}}
            icon={<ShoppingCartIcon />}
          />
          <BottomNavigationAction
            label="ORDERS"
            value="3"
            sx={{fontSize:35, fontWeight:700}}
            icon={<ShoppingBagIcon />}
          />


        </BottomNavigation>
      </Box>

      <UnavailableItemsEntry refreshCustomItemsTable={refreshCustomItemsTable}/>     


    </>

  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, {})(withRouter(Cart));