import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Box from '@mui/material/Box';
import AddIcon from "@mui/icons-material/Add";
import Rating from "@mui/material/Rating";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Checkbox from "@mui/material/Checkbox";
import { strings } from "../../string.js";
import { Button } from "@material-ui/core";
import ReactStars from "react-rating-stars-component";
import { Image, Breathing } from 'react-shimmer';
import placeholder from "../../assets/img/placeholder.png";
import { deviceType } from "react-device-detect";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Filter = (props) => {
  const history = useHistory();
  const [value, setValue] = React.useState(2);

  const [categories, setCategories] = React.useState([]);
  const [brands, setBrands] = React.useState([]);
  const [fitments, setFitments] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [searchKey, setSearchKey] = useState('')
  const [isLoading, setLoading] = useState(false)

  const [brandsCount, setBrandsCount] = useState(0)
  const [ratingsCount, setRatingsCount] = useState(5)
  const [priceRangesCount, setPriceRangesCount] = useState(5)
  const [fitmentForCount, setFitmentForCount] = useState(0)

  const [myChoicePageVisible, setMyChoicePageVisible] = useState(false)

  const [currentlyExpandedCategory, setCurrentlyExpandedCategory] = useState(0)

  const [flag, setFlag] = React.useState(true);

  useEffect(() => {

    getCategories();
    getBrands();
    getFitments();
    getColors();

  }, []);


  const toggleFlag = (e) => {

    setFlag(!flag)
  }

  const hideFilterModal = () => {

    props.setOpen(false)
  }

  const setTheCategory = (item) => {
    

    // Reset all filters and search keys, in memory and in localstorage, as the category is changing now
    localStorage.removeItem(item.id + 'brandsFilterArray')
    localStorage.removeItem(item.id + 'ratingsFilterArray')
    localStorage.removeItem(item.id + 'priceRangeFilterArray')
    localStorage.removeItem(item.id + 'sizeFilterArray')
    props.resetSearchKeyAndFilters();

    props.resetHelmetFilters(); 

    // If clicked on the same catgeory as the current category, set the category to none
    if (item.id == props.currentCategoryId) {

      props.setCurrentCategoryId(0)
      props.setCurrentCategory(null)
      setCurrentlyExpandedCategory(0)

    } else {

      props.setCurrentCategoryId(item.id)
      props.setCurrentCategory(item)
      setCurrentlyExpandedCategory(item.id);

      // Fetch products of a category
      props.getProducts(item.id, false);    

     /* // Set the filter arrays as per the saved filter info
      var brandsFilterArrayString = localStorage.getItem(item.id + 'brandsFilterArray')
      var ratingsFilterArrayString = localStorage.getItem(item.id + 'ratingsFilterArray')
      var priceRangeFilterArrayString = localStorage.getItem(item.id + 'priceRangeFilterArray')
      var sizeFilterArrayString = localStorage.getItem(item.id + 'sizeFilterArray')


      if (brandsFilterArrayString) {
        props.setBrandsFilterArray(JSON.parse(brandsFilterArrayString));
      } else {
        props.setBrandsFilterArray([]);
      }


      if (ratingsFilterArrayString) {
        props.setRatingsFilterArray(JSON.parse(ratingsFilterArrayString));
      } else {
        props.setRatingsFilterArray([]);
      }

      if (priceRangeFilterArrayString) {
        props.setPriceRangeFilterArray(JSON.parse(priceRangeFilterArrayString));
      } else {
        props.setPriceRangeFilterArray([]);
      }

      if (sizeFilterArrayString) {
        props.setSizeFilterArray(JSON.parse(sizeFilterArrayString));
      } else {
        props.setSizeFilterArray([]);
      }

      // When category is changed, reset the helmet filters
      props.resetHelmetFilters();    */  

    }

    setMyChoicePageVisible(false);
    props.setMyChoicePageVisible(false)

    

  }

  console.log('myChoicePageVisible', myChoicePageVisible)


  const handleChange = (e, catId) => {


    var tmpFilterArray = localStorage.getItem(catId + 'brandsFilterArray')

    if (tmpFilterArray == null) {
      tmpFilterArray = [];
    } else {
      tmpFilterArray = JSON.parse(localStorage.getItem(catId + 'brandsFilterArray'));
    }

    if (tmpFilterArray.length == 0) {
      if (e.target.checked == true) {
        tmpFilterArray.push(parseInt(e.target.name));
      }
    }

    for (var i = 0; i < tmpFilterArray.length; i++) {

      if (parseInt(e.target.name) == tmpFilterArray[i]) {

        if (e.target.checked == true) {

          if (!tmpFilterArray.includes(parseInt(e.target.name))) {
            tmpFilterArray.push(parseInt(e.target.name));
          }

        } else {

          if (tmpFilterArray.includes(parseInt(e.target.name))) {
            tmpFilterArray = removeItemOnce(tmpFilterArray, parseInt(e.target.name));
          }
        }
      } else {
        if (!tmpFilterArray.includes(parseInt(e.target.name))) {
          tmpFilterArray.push(parseInt(e.target.name));
        }
      }
    }


    props.setBrandsFilterArray(tmpFilterArray);

    localStorage.setItem(catId + 'brandsFilterArray', JSON.stringify(tmpFilterArray))

    toggleFlag();
    props.toggleFlag();
  }


  const handleChangeSizeFilter = (e, catId) => {

    var tmpFilterArray = localStorage.getItem(catId + 'sizeFilterArray')
    if (tmpFilterArray == null) {
      tmpFilterArray = [];
    } else {
      tmpFilterArray = JSON.parse(localStorage.getItem(catId + 'sizeFilterArray'));
    }


    if (tmpFilterArray.length == 0) {
      if (e.target.checked == true) {
        tmpFilterArray.push(e.target.name);
      }
    }

    for (var i = 0; i < tmpFilterArray.length; i++) {

      if (e.target.name == tmpFilterArray[i]) {

        if (e.target.checked == true) {

          if (!tmpFilterArray.includes(e.target.name)) {
            tmpFilterArray.push(e.target.name);
          }

        } else {


          if (tmpFilterArray.includes(e.target.name)) {

            tmpFilterArray = removeItemOnce(tmpFilterArray, e.target.name);
          }
        }
      } else {
        if (!tmpFilterArray.includes(e.target.name)) {
          tmpFilterArray.push(e.target.name);
        }
      }
    }

    props.setSizeFilterArray(tmpFilterArray);

    localStorage.setItem(props.currentCategoryId + 'sizeFilterArray', JSON.stringify(tmpFilterArray))


    toggleFlag();
    props.toggleFlag();

  }

  const handleChangeRatingsFilter = (e, catId) => {

    var tmpFilterArray = localStorage.getItem(catId + 'ratingsFilterArray')
    if (tmpFilterArray == null) {
      tmpFilterArray = [];
    } else {
      tmpFilterArray = JSON.parse(localStorage.getItem(catId + 'ratingsFilterArray'));
    }

    if (tmpFilterArray.length == 0) {
      if (e.target.checked == true) {
        tmpFilterArray.push(e.target.name);
      }
    }

    for (var i = 0; i < tmpFilterArray.length; i++) {

      if (e.target.name == tmpFilterArray[i]) {


        if (e.target.checked == true) {

          if (!tmpFilterArray.includes(e.target.name)) {
            tmpFilterArray.push(e.target.name);
          }

        } else {

          if (tmpFilterArray.includes(e.target.name)) {

            tmpFilterArray = removeItemOnce(tmpFilterArray, e.target.name);
          }
        }
      } else {
        if (!tmpFilterArray.includes(e.target.name)) {
          tmpFilterArray.push(e.target.name);
        }
      }
    }


    props.setRatingsFilterArray(tmpFilterArray);

    localStorage.setItem(props.currentCategoryId + 'ratingsFilterArray', JSON.stringify(tmpFilterArray))


    toggleFlag();
    props.toggleFlag();
  }

  const handleChangeFitmentFilter = (e) => {

    var tmpFilterArray = props.fitmentFilterArray;

    if (tmpFilterArray.length == 0) {
      if (e.target.checked == true) {
        tmpFilterArray.push(e.target.name);
      }
    }


    if (tmpFilterArray.length == 0) {
      tmpFilterArray.push(e.target.name);
    } else {


      for (var i = 0; i < tmpFilterArray.length; i++) {

        if (e.target.name == tmpFilterArray[i]) {

          if (e.target.checked == true) {

            if (!tmpFilterArray.includes(e.target.name)) {
              tmpFilterArray.push(e.target.name);
            } else {
              //
            }

          } else {

            if (tmpFilterArray.includes(e.target.name)) {

              tmpFilterArray = removeItemOnce(tmpFilterArray, e.target.name);
            }
          }
        } else {
          if (!tmpFilterArray.includes(e.target.name)) {
            tmpFilterArray.push(e.target.name);
          }
        }
      }

    }


    props.setFitmentFilterArray(tmpFilterArray);
    toggleFlag();
    props.toggleFlag();
  }

  const handleChangeColorFilter = (e) => {

    var tmpFilterArray = props.colorFilterArray;

    if (tmpFilterArray.length == 0) {
      tmpFilterArray.push(e.target.name);
    } else {


      for (var i = 0; i < tmpFilterArray.length; i++) {

        if (e.target.name == tmpFilterArray[i]) {

          if (e.target.checked == true) {

            if (!tmpFilterArray.includes(e.target.name)) {
              tmpFilterArray.push(e.target.name);
            } else {
              //
            }

          } else {

            if (tmpFilterArray.includes(e.target.name)) {

              tmpFilterArray = removeItemOnce(tmpFilterArray, e.target.name);
            }
          }
        } else {
          if (!tmpFilterArray.includes(e.target.name)) {
            tmpFilterArray.push(e.target.name);
          }
        }
      }

    }


    props.setColorFilterArray(tmpFilterArray);
    toggleFlag();
    props.toggleFlag();
  }

  const handleChangePriceRangeFilter = (e, catId) => {

    var tmpFilterArray = localStorage.getItem(catId + 'priceRangeFilterArray')
    if (tmpFilterArray == null) {
      tmpFilterArray = [];
    } else {
      tmpFilterArray = JSON.parse(localStorage.getItem(catId + 'priceRangeFilterArray'));
    }

    if (tmpFilterArray.length == 0) {
      if (e.target.checked == true) {
        tmpFilterArray.push(e.target.name);
      }
    }

    for (var i = 0; i < tmpFilterArray.length; i++) {

      if (e.target.name == tmpFilterArray[i]) {

        if (e.target.checked == true) {

          if (!tmpFilterArray.includes(e.target.name)) {
            tmpFilterArray.push(e.target.name);
          }

        } else {

          if (tmpFilterArray.includes(e.target.name)) {

            tmpFilterArray = removeItemOnce(tmpFilterArray, e.target.name);
          }
        }
      } else {

        if (!tmpFilterArray.includes(e.target.name)) {
          tmpFilterArray.push(e.target.name);
        }
      }
    }

    props.setPriceRangeFilterArray(tmpFilterArray);
    localStorage.setItem(props.currentCategoryId + 'priceRangeFilterArray',
      JSON.stringify(tmpFilterArray))

    toggleFlag();
    props.toggleFlag();
  }


  const handleChangeSearch = (event) => {
    setSearchKey(event.target.value)
  }

  const onAccordianChange = (expanded, id) => {
  }


  function removeItemOnce(arr, value) {

    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const showHelmetsPage = () => {

    props.setIsHelmetsPageVisible(true);
  };

  const hideHelmetsPage = () => {

    props.setIsHelmetsPageVisible(false);
  };


  const getCategories = () => {

    setLoading(true);

    fetch(
      'https://arihant.trikodev.xyz' + "/api/get-all-categories")
      .then((res) => res.json())
      .then((json) => {
        setCategories(json.categories);
        props.setCategoriesCount(json.categories.length)
        setLoading(false);
      })
  };

  const getFitments = () => {

    if(props.currentProduct){

    fetch(
      'https://arihant.trikodev.xyz' + "/api/get-all-fitments-for-a-product?product_id=" + props.currentProduct.id)
      .then((res) => res.json())
      .then((json) => {
        setFitments(json.fitments);
        setFitmentForCount(json.fitments.length)
      })
    }
  };

  const getColors = () => {

    fetch(
      'https://arihant.trikodev.xyz' + "/api/get-all-colors?category_id=" + props.currentCategoryId)
      .then((res) => res.json())
      .then((json) => {
        setColors(json.colors)
      })
  };

  const getBrands = () => {

    fetch(
      'https://arihant.trikodev.xyz' + "/api/get-all-brands")
      .then((res) => res.json())
      .then((json) => {

        setBrands(json.brands);
        setBrandsCount(json.brands.length)
        props.setBrands(json.brands);

        var brandIds = [];
        for (var i = 0; i < json.brands.length; i++) {
          brandIds.push(json.brands[i].id);
        }

      })

  };


  var filteredCategories = [];

  for (var i = 0; i < categories.length; i++) {

    if (categories[i].name.toLowerCase().includes(searchKey.toLowerCase())) {
      filteredCategories.push(categories[i]);
    }
  }

  const handleMychoice = () => {

    setMyChoicePageVisible(true);
    props.setCurrentCategoryId(99999)
    props.setCurrentCategory(null)
    props.setMyChoicePageVisible(true)
    props.getProducts(0, true);    

    props.setBrandsFilterArray([]);
    props.setFitmentFilterArray([]);
    props.setSizeFilterArray([]);
    props.setColorFilterArray([]);
    props.setRatingsFilterArray([]);     
    props.resetSearchKey()

  }


  return (
    <div style={{ height: 600, }}>
      {isLoading ?
        <Skeleton height={50} count={10} style={{ margin: 10, width: '90%' }} />
        :
        <>

          {!props.isFitmentVisible ?

            <div style={{ height: '100%' }}>

              <input type="text" name="searchcats" autoComplete="off"
                onChange={handleChangeSearch}
                value={searchKey}
                
                style={{
                  borderRadius: 5, height: 30, width: '95%', backgroundColor: '#ebedf8',
                  border: '1px solid rgba(0,0,0,.125)', outline: 'none',
                  marginTop: 10, marginLeft: '1%', paddingLeft: 5, paddingRight: 5
                }}
                placeholder={strings.type_to_search_categories} /> <br /><br />


              {!props.isHelmetsPageVisible ?

                <div style={{ marginTop: -20 }}>
                    <div style={{
                            color: 'black',
                            backgroundColor: myChoicePageVisible ? '#F05225' : "#f0f0f0"
                            , color: myChoicePageVisible?'white':'black',
                            marginTop: 10,
                            marginLeft: '5%',
                            marginRight: '5%',
                            width: '90%',
                            padding:5,
                            alignSelf: 'center',
                            cursor:'pointer',
                            fontSize:21
                          }} onClick={handleMychoice}>My Choice
                    </div>

                  {filteredCategories.map((category) => (

                    <div>

                      <div
                        className="side-box"
                        style={{ marginLeft: 0, cursor: 'pointer' }}
                      >

                        <div
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          onClick={() => setTheCategory(category)}
                          id="panel1a-header"
                          style={{
                            color: 'black',
                            backgroundColor: (category.id == props.currentCategoryId && !myChoicePageVisible) ? '#F05225' : "#f0f0f0"
                            , color: 'black',
                            marginTop: 10,
                            marginLeft: '5%',
                            marginRight: '5%',
                            width: '90%',
                            alignSelf: 'center',
                          }}
                        >

                          <div style={{
                            display: 'flex', flexDirection: 'row', width: '100%',
                            marginLeft: -10, padding: 10, marginRight: 10, borderRadius: 3,
                            backgroundColor:
                              (category.id == props.currentCategoryId && !myChoicePageVisible) ? '#F05225' : "#f0f0f0",
                            color: (category.id == props.currentCategoryId && !myChoicePageVisible) ? '#ffffff' : "#212529",
                          }}>
                            <div style={{
                              fontSize: 21, width: '90%',
                              alignSelf: 'flex-start'
                            }}>{category.name}</div>


                            {category.id == props.currentCategoryId ?
                              <ExpandLessIcon size={30} style={{ alignSelf: 'flex-end', }} />
                              :
                              <ExpandMoreIcon size={30} style={{ alignSelf: 'flex-end' }} />
                            }

                          </div>

                        </div>


                        <div
                          style={{ display: category.id == currentlyExpandedCategory ? 'flex' : 'none', flexDirection: 'column' }}>

                          <div style={{ display: 'flex', flexDirection: 'column', padding: 3 }}>

                            <h3 style={{ padding:3 }}>{strings.brand}</h3>

                            {category.brands.map((item) => (
                              <div key={item.id}>
                                <Checkbox
                                  style={{ alignSelf: 'center', marginTop: -2 }}
                                  {...label}
                                  name={item.id}
                                  onChange={(e) => handleChange(e, category.id)}
                                  checked={localStorage.getItem(category.id + 'brandsFilterArray') ?
                                    ((JSON.parse(localStorage.getItem(category.id + 'brandsFilterArray'))).includes(item.id)) ? true : false
                                    : false
                                  }
                                />
                                <span >{item.name}</span>
                              </div>
                            ))}

                          </div>

                          <h3 style={{ padding: 3 }}>{strings.price_range}</h3>

                          <div>
                            <Checkbox {...label}
                              name='₹0 - ₹499'
                              onChange={(e) => handleChangePriceRangeFilter(e, category.id)}
                              checked={localStorage.getItem(category.id + 'priceRangeFilterArray') ?
                                ((JSON.parse(localStorage.getItem(category.id + 'priceRangeFilterArray'))).includes('₹0 - ₹499')) ? true : false
                                : false
                              }


                            />

                            <Typography variant="h7">₹0 - ₹499</Typography>
                          </div>
                          <div>
                            <Checkbox {...label} name='₹500 - ₹999'
                              onChange={(e) => handleChangePriceRangeFilter(e, category.id)}
                              checked={localStorage.getItem(category.id + 'priceRangeFilterArray') ?
                                ((JSON.parse(localStorage.getItem(category.id + 'priceRangeFilterArray')))
                                  .includes('₹500 - ₹999')) ? true : false
                                : false
                              }
                            />

                            <Typography variant="h7">₹500 - ₹999</Typography>
                          </div>
                          <div>
                            <Checkbox {...label} name='₹1000 - ₹1499'
                              onChange={(e) => handleChangePriceRangeFilter(e, category.id)}
                              checked={localStorage.getItem(category.id + 'priceRangeFilterArray') ?
                                ((JSON.parse(localStorage.getItem(category.id + 'priceRangeFilterArray')))
                                  .includes('₹1000 - ₹1499')) ? true : false
                                : false
                              }

                            />

                            <Typography variant="h7">₹1000 - ₹1499</Typography>
                          </div>
                          <div>
                            <Checkbox {...label} name='₹1500 - ₹1999'
                              onChange={(e) => handleChangePriceRangeFilter(e, category.id)}
                              checked={localStorage.getItem(category.id + 'priceRangeFilterArray') ?
                                ((JSON.parse(localStorage.getItem(category.id + 'priceRangeFilterArray')))
                                  .includes('₹1500 - ₹1999')) ? true : false
                                : false
                              }
                            />
                            <Typography variant="h7">₹1500 - ₹1999</Typography>
                          </div>
                          <div>
                            <Checkbox {...label} name='₹2000 - ₹2499'
                              onChange={(e) => handleChangePriceRangeFilter(e, category.id)}
                              checked={localStorage.getItem(category.id + 'priceRangeFilterArray') ?
                                ((JSON.parse(localStorage.getItem(category.id + 'priceRangeFilterArray')))
                                  .includes('₹2000 - ₹2499')) ? true : false
                                : false
                              }
                            />
                            <Typography variant="h7">₹2000 - ₹2499</Typography>
                          </div>

                          <h3 style={{ padding: 10 }}>{strings.ratings}</h3>

                          <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <div>
                              <Checkbox {...label}
                                name={'1'}
                                onChange={(e) => handleChangeRatingsFilter(e, category.id)}
                                checked={localStorage.getItem(category.id + 'ratingsFilterArray') ?
                                  ((JSON.parse(localStorage.getItem(category.id + 'ratingsFilterArray'))).includes('1')) ? true : false
                                  : false
                                }


                                style={{ marginTop: -10 }} />
                              <Rating name="read-only" value={1} readOnly />
                            </div>

                            <div>
                              <Checkbox name={'2'} {...label}
                                onChange={(e) => handleChangeRatingsFilter(e, category.id)}
                                checked={localStorage.getItem(category.id + 'ratingsFilterArray') ?
                                  ((JSON.parse(localStorage.getItem(category.id + 'ratingsFilterArray'))).includes('2')) ? true : false
                                  : false
                                }
                                style={{ marginTop: -10 }} />
                              <Rating name="read-only" value={2} readOnly />
                            </div>


                            <div>
                              <Checkbox name={'3'} {...label}
                                onChange={(e) => handleChangeRatingsFilter(e, category.id)}
                                checked={localStorage.getItem(category.id + 'ratingsFilterArray') ?
                                  ((JSON.parse(localStorage.getItem(category.id + 'ratingsFilterArray'))).includes('3')) ? true : false
                                  : false
                                }
                                style={{ marginTop: -10 }} />
                              <Rating name="read-only" value={3} readOnly />
                            </div>


                            <div>
                              <Checkbox name={'4'} {...label}
                                onChange={(e) => handleChangeRatingsFilter(e, category.id)}
                                checked={localStorage.getItem(category.id + 'ratingsFilterArray') ?
                                  ((JSON.parse(localStorage.getItem(category.id + 'ratingsFilterArray'))).includes('4')) ? true : false
                                  : false
                                }
                                style={{ marginTop: -10 }} />
                              <Rating name="read-only" value={4} readOnly />
                            </div>


                            <div>
                              <Checkbox name={'5'} {...label}
                                onChange={(e) => handleChangeRatingsFilter(e, category.id)}
                                style={{ marginTop: -10 }}
                                checked={localStorage.getItem(category.id + 'ratingsFilterArray') ?
                                  ((JSON.parse(localStorage.getItem(category.id + 'ratingsFilterArray'))).includes('5')) ? true : false
                                  : false
                                } />
                              <Rating name="read-only" value={5} readOnly />
                            </div>

                          </div>

                        </div>
                      </div>
                    </div>

                  ))}

                </div>
                :

                <div className="helmetsFilter">
                  <div>

                    <h3>{strings.brand}</h3>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                      {brands.map((item) => (
                        <div key={item.id}>
                          <Checkbox {...label}
                            name={item.id}
                            onChange={handleChange}
                            checked={props.brandsFilterArray.includes(item.id)}
                          />
                          <span>{item.name}</span>
                        </div>
                      ))}

                    </div>

                    <h3>{strings.helmet_size}</h3>
                    <div>
                      <Checkbox {...label} name="Small" onChange={handleChangeSizeFilter}
                        checked={props.sizeFilterArray.includes('Small')} />
                      <Typography variant="h7">Small</Typography>
                    </div>
                    <div>
                      <Checkbox {...label} name="Medium"
                        onChange={handleChangeSizeFilter}
                        checked={props.sizeFilterArray.includes('Medium')} />
                      <Typography variant="h7">Medium</Typography>
                    </div>
                    <div>
                      <Checkbox {...label} name="Big"
                        onChange={handleChangeSizeFilter}
                        checked={props.sizeFilterArray.includes('Big')} />
                      <Typography variant="h7">Big</Typography>
                    </div>

                  </div>


                  <h3>{strings.price_range}</h3>

                  <div>
                    <Checkbox {...label} name='₹0 - ₹499'
                      onChange={handleChangePriceRangeFilter}
                      checked={props.priceRangeFilterArray.includes('₹0 - ₹499')} />
                    <Typography variant="h7">₹0 - ₹499</Typography>
                  </div>
                  <div>
                    <Checkbox {...label}
                      onChange={handleChangePriceRangeFilter}
                      name='₹500 - ₹999' checked={props.priceRangeFilterArray.includes('₹500 - ₹999')} />
                    <Typography variant="h7">₹500 - ₹999</Typography>
                  </div>
                  <div>
                    <Checkbox {...label}
                      onChange={handleChangePriceRangeFilter}
                      name='₹1000 - ₹1499' checked={props.priceRangeFilterArray.includes('₹1000 - ₹1499')} />
                    <Typography variant="h7">₹1000 - ₹1499</Typography>
                  </div>
                  <div>
                    <Checkbox {...label}
                      onChange={handleChangePriceRangeFilter}
                      name='₹1500 - ₹1999' checked={props.priceRangeFilterArray.includes('₹1500 - ₹1999')} />
                    <Typography variant="h7">₹1500 - ₹1999</Typography>
                  </div>
                  <div>
                    <Checkbox {...label}
                      onChange={handleChangePriceRangeFilter}
                      checked={props.priceRangeFilterArray.includes('₹2000 - ₹2499')} />
                    <Typography variant="h7">₹2000 - ₹2499</Typography>
                  </div>

                  <h3>{strings.ratings}</h3>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <div>
                      <Checkbox {...label} name={"1"}
                        onChange={handleChangeRatingsFilter}
                        style={{ marginTop: -10 }}
                        checked={props.ratingsFilterArray.includes('1')} />
                      <Rating name="read-only" value={1} readOnly />
                    </div>

                    <div>
                      <Checkbox {...label} name={"2"}
                        onChange={handleChangeRatingsFilter}
                        style={{ marginTop: -10 }}
                        checked={props.ratingsFilterArray.includes('2')} />
                      <Rating name="read-only" value={2} readOnly />
                    </div>


                    <div>
                      <Checkbox {...label} name={"3"}
                        onChange={handleChangeRatingsFilter}
                        style={{ marginTop: -10 }}
                        checked={props.ratingsFilterArray.includes('3')} />
                      <Rating name="read-only" value={3} readOnly />
                    </div>


                    <div>
                      <Checkbox {...label} name={"4"}
                        onChange={handleChangeRatingsFilter}
                        style={{ marginTop: -10 }}
                        checked={props.ratingsFilterArray.includes('4')} />
                      <Rating name="read-only" value={4} readOnly />
                    </div>


                    <div>
                      <Checkbox {...label}
                        onChange={handleChangeRatingsFilter}
                        style={{ marginTop: -10 }}
                        name={"5"} checked={props.ratingsFilterArray.includes('5')} />
                      <Rating name="read-only" value={5} readOnly />
                    </div>
                  </div>

                  <div>
                </div>
                </div>

              }

            </div>
            :
            <div style={{ backgroundColor: '#f2f2f2',  }}>

              <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f2f2f2',  }}>

                <img className="square"
                  style={{
                    borderRadius: 10, width: '40%', maxHeight: '100%', margin: 10,
                    verticalAlign: 'center', objectFit: 'contain'
                  }}
                  src={props.currentProduct.product_image_path !== null ? 'https://arihant.trikodev.xyz' + '/' + props.currentProduct.product_image_path : placeholder}
                  alt="" />

                <div style={{ paddingLeft: 3, paddingRight: 10, display: 'flex', flexDirection: 'column' }}>

                  <span style={{ marginTop: 20, marginLeft: -2 }}>{props.currentProduct.name}</span>

                  <ReactStars
                    count={5}
                    size={20}
                    value={props.currentProduct.rating_in_stars}
                    isHalf={true}
                    edit={false}
                    activeColor="#ffd700"
                  />
                  <span style={{ fontSize: 13, marginTop: 5 }}>₹{props.currentProduct.price}.00</span>

                  <div style={{
                    display: 'flex', flexDirection: 'row', width: '100%',
                    alignSelf: 'flex-end', marginRight: -40
                  }}>

                  </div>
                </div>
              </div>


              {props.currentCategory && props.currentCategory.presentation_type == 'fitment-based' &&

                <Typography style={{ marginTop: 50, fontWeight: 'bold', marginLeft: 10 }}>
                  {strings.fitment_for}</Typography>
              }


              {props.currentCategory && props.currentCategory.presentation_type == 'fitment-based' &&
                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>

                  {props.fitments.map((fitment) => (

                    <div>

                      <Checkbox {...label} name={fitment.fitment_name}
                        onChange={handleChangeFitmentFilter}
                        checked={props.fitmentFilterArray.includes(fitment.fitment_name)
                        } />
                      <span>{fitment.fitment_name}</span>
                    </div>

                  ))}

                </div>
              }

            </div>
          }

        </>
      }
    </div>
  );
};

const customStyle1 = {
  margin: "10px",
  padding: "10px 10px",
  border: "2px solid #D6B1B1",
  backgroundColor: "#ffffff",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "center",
  width: 200
}

const customStyle2 = {
  margin: "10px",
  padding: "10px 10px",
  border: "2px solid #B2ACFA",
  backgroundColor: "#ffffff",
  boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
  alignContent: "center",
  width: 200
}

export default Filter;
