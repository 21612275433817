import React, { Fragment, useEffect } from "react";

import { createBrowserHistory } from "history";
import { makeStyles } from "@material-ui/core";
import factory from "./store";

import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import notePadIcon from "./assets/img/icons8-notepad-64.png";

import { strings } from "./string.js";

import ReactModal from 'react-modal-resizable-draggable';

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
});

const UnavailableItemsEntry = (props) => {

  const [isItemsModalOpen, setIsItemsModalOpen] = React.useState(false);
  const [name1, setName1] = React.useState('');
  const [name2, setName2] = React.useState('');
  const [name3, setName3] = React.useState('');
  const [name4, setName4] = React.useState('');
  const [name5, setName5] = React.useState('');
  const [name6, setName6] = React.useState('');
  const [name7, setName7] = React.useState('');
  const [name8, setName8] = React.useState('');
  const [name9, setName9] = React.useState('');
  const [name10, setName10] = React.useState('');
  const [name11, setName11] = React.useState('');
  const [name12, setName12] = React.useState('');
  const [name13, setName13] = React.useState('');
  const [name14, setName14] = React.useState('');
  const [name15, setName15] = React.useState('');
  const [quantity1, setQuantity1] = React.useState('');
  const [quantity2, setQuantity2] = React.useState('');
  const [quantity3, setQuantity3] = React.useState('');
  const [quantity4, setQuantity4] = React.useState('');
  const [quantity5, setQuantity5] = React.useState('');
  const [quantity6, setQuantity6] = React.useState('');
  const [quantity7, setQuantity7] = React.useState('');
  const [quantity8, setQuantity8] = React.useState('');
  const [quantity9, setQuantity9] = React.useState('');
  const [quantity10, setQuantity10] = React.useState('');
  const [quantity11, setQuantity11] = React.useState('');
  const [quantity12, setQuantity12] = React.useState('');
  const [quantity13, setQuantity13] = React.useState('');
  const [quantity14, setQuantity14] = React.useState('');
  const [quantity15, setQuantity15] = React.useState('');

  useEffect(() => {

    var unavailableItemName1 = localStorage.getItem('unavailable_item_name1');
    var unavailableItemName2 = localStorage.getItem('unavailable_item_name2');
    var unavailableItemName3 = localStorage.getItem('unavailable_item_name3');
    var unavailableItemName4 = localStorage.getItem('unavailable_item_name4');
    var unavailableItemName5 = localStorage.getItem('unavailable_item_name5');
    var unavailableItemName6 = localStorage.getItem('unavailable_item_name6');
    var unavailableItemName7 = localStorage.getItem('unavailable_item_name7');
    var unavailableItemName8 = localStorage.getItem('unavailable_item_name8');
    var unavailableItemName9 = localStorage.getItem('unavailable_item_name9');
    var unavailableItemName10 = localStorage.getItem('unavailable_item_name10');
    var unavailableItemName11 = localStorage.getItem('unavailable_item_name11');
    var unavailableItemName12 = localStorage.getItem('unavailable_item_name12');
    var unavailableItemName13 = localStorage.getItem('unavailable_item_name13');
    var unavailableItemName14 = localStorage.getItem('unavailable_item_name14');
    var unavailableItemName15 = localStorage.getItem('unavailable_item_name15');

    var unavailableItemQuantity1 = localStorage.getItem('unavailable_item_quantity1');
    var unavailableItemQuantity2 = localStorage.getItem('unavailable_item_quantity2');
    var unavailableItemQuantity3 = localStorage.getItem('unavailable_item_quantity3');
    var unavailableItemQuantity4 = localStorage.getItem('unavailable_item_quantity4');
    var unavailableItemQuantity5 = localStorage.getItem('unavailable_item_quantity5');
    var unavailableItemQuantity6 = localStorage.getItem('unavailable_item_quantity6');
    var unavailableItemQuantity7 = localStorage.getItem('unavailable_item_quantity7');
    var unavailableItemQuantity8 = localStorage.getItem('unavailable_item_quantity8');
    var unavailableItemQuantity9 = localStorage.getItem('unavailable_item_quantity9');
    var unavailableItemQuantity10 = localStorage.getItem('unavailable_item_quantity10');
    var unavailableItemQuantity11 = localStorage.getItem('unavailable_item_quantity11');
    var unavailableItemQuantity12 = localStorage.getItem('unavailable_item_quantity12');
    var unavailableItemQuantity13 = localStorage.getItem('unavailable_item_quantity13');
    var unavailableItemQuantity14 = localStorage.getItem('unavailable_item_quantity14');
    var unavailableItemQuantity15 = localStorage.getItem('unavailable_item_quantity15');

    setName1(unavailableItemName1);
    setName2(unavailableItemName2);
    setName3(unavailableItemName3);
    setName4(unavailableItemName4);
    setName5(unavailableItemName5);
    setName6(unavailableItemName6);
    setName7(unavailableItemName7);
    setName8(unavailableItemName8);
    setName9(unavailableItemName9);
    setName10(unavailableItemName10);
    setName11(unavailableItemName11);
    setName12(unavailableItemName12);
    setName13(unavailableItemName13);
    setName14(unavailableItemName14);
    setName15(unavailableItemName15);

    setQuantity1(unavailableItemQuantity1);
    setQuantity2(unavailableItemQuantity2);
    setQuantity3(unavailableItemQuantity3);
    setQuantity4(unavailableItemQuantity4);
    setQuantity5(unavailableItemQuantity5);
    setQuantity6(unavailableItemQuantity6);
    setQuantity7(unavailableItemQuantity7);
    setQuantity8(unavailableItemQuantity8);
    setQuantity9(unavailableItemQuantity9);
    setQuantity10(unavailableItemQuantity10);
    setQuantity11(unavailableItemQuantity11);
    setQuantity12(unavailableItemQuantity12);
    setQuantity13(unavailableItemQuantity13);
    setQuantity14(unavailableItemQuantity14);
    setQuantity15(unavailableItemQuantity15);

  }, []);


  // Function to handle name input
  const handleNameInputChange = (e) => {

    if (e.target.name == 'name1') {
      setName1(e.target.value);
      localStorage.setItem('unavailable_item_name1', e.target.value)
    }

    if (e.target.name == 'name2') {
      setName2(e.target.value);
      localStorage.setItem('unavailable_item_name2', e.target.value)
    }
    if (e.target.name == 'name3') {
      setName3(e.target.value);
      localStorage.setItem('unavailable_item_name3', e.target.value)
    }
    if (e.target.name == 'name4') {
      setName4(e.target.value);
      localStorage.setItem('unavailable_item_name4', e.target.value)
    }
    if (e.target.name == 'name5') {
      setName5(e.target.value);
      localStorage.setItem('unavailable_item_name5', e.target.value)
    }
    if (e.target.name == 'name6') {
      setName6(e.target.value);
      localStorage.setItem('unavailable_item_name6', e.target.value)
    }
    if (e.target.name == 'name7') {
      setName7(e.target.value);
      localStorage.setItem('unavailable_item_name7', e.target.value)
    }
    if (e.target.name == 'name8') {
      setName8(e.target.value);
      localStorage.setItem('unavailable_item_name8', e.target.value)
    }
    if (e.target.name == 'name9') {
      setName9(e.target.value);
      localStorage.setItem('unavailable_item_name9', e.target.value)
    }
    if (e.target.name == 'name10') {
      setName10(e.target.value);
      localStorage.setItem('unavailable_item_name10', e.target.value)
    }
    if (e.target.name == 'name11') {
      setName11(e.target.value);
      localStorage.setItem('unavailable_item_name11', e.target.value)
    }
    if (e.target.name == 'name12') {
      setName12(e.target.value);
      localStorage.setItem('unavailable_item_name12', e.target.value)
    }
    if (e.target.name == 'name13') {
      setName13(e.target.value);
      localStorage.setItem('unavailable_item_name13', e.target.value)
    }
    if (e.target.name == 'name14') {
      setName14(e.target.value);
      localStorage.setItem('unavailable_item_name14', e.target.value)
    }
    if (e.target.name == 'name15') {
      setName15(e.target.value);
      localStorage.setItem('unavailable_item_name15', e.target.value)
    }


  };

  // Function to handle quantity input
  const handleQuantityInputChange = (e) => { 

    console.log(e.target.value)
    
    if (/^-?\d+$/.test(e.target.value) || e.target.value=='') {

    if (e.target.name == 'quantity1') {
      setQuantity1(e.target.value);
      localStorage.setItem('unavailable_item_quantity1', e.target.value)
    }

    if (e.target.name == 'quantity2') {
      setQuantity2(e.target.value);
      localStorage.setItem('unavailable_item_quantity2', e.target.value)
    }
    if (e.target.name == 'quantity3') {
      setQuantity3(e.target.value);
      localStorage.setItem('unavailable_item_quantity3', e.target.value)
    }
    if (e.target.name == 'quantity4') {
      setQuantity4(e.target.value);
      localStorage.setItem('unavailable_item_quantity4', e.target.value)
    }
    if (e.target.name == 'quantity5') {
      setQuantity5(e.target.value);
      localStorage.setItem('unavailable_item_quantity5', e.target.value)
    }
    if (e.target.name == 'quantity6') {
      setQuantity6(e.target.value);
      localStorage.setItem('unavailable_item_quantity6', e.target.value)
    }
    if (e.target.name == 'quantity7') {
      setQuantity7(e.target.value);
      localStorage.setItem('unavailable_item_quantity7', e.target.value)
    }
    if (e.target.name == 'quantity8') {
      setQuantity8(e.target.value);
      localStorage.setItem('unavailable_item_quantity8', e.target.value)
    }
    if (e.target.name == 'quantity9') {
      setQuantity9(e.target.value);
      localStorage.setItem('unavailable_item_quantity9', e.target.value)
    }
    if (e.target.name == 'quantity10') {
      setQuantity10(e.target.value);
      localStorage.setItem('unavailable_item_quantity10', e.target.value)
    }
    if (e.target.name == 'quantity11') {
      setQuantity11(e.target.value);
      localStorage.setItem('unavailable_item_quantity11', e.target.value)
    }
    if (e.target.name == 'quantity12') {
      setQuantity12(e.target.value);
      localStorage.setItem('unavailable_item_quantity12', e.target.value)
    }
    if (e.target.name == 'quantity13') {
      setQuantity13(e.target.value);
      localStorage.setItem('unavailable_item_quantity13', e.target.value)
    }
    if (e.target.name == 'quantity14') {
      setQuantity14(e.target.value);
      localStorage.setItem('unavailable_item_quantity14', e.target.value)
    }
    if (e.target.name == 'quantity15') {
      setQuantity15(e.target.value);
      localStorage.setItem('unavailable_item_quantity15', e.target.value)
    }

  }


  };

  const classes = useStyles({

  });  

  const openItemsModal = () => {

    setIsItemsModalOpen(true);
  }
  
  useEffect(() => {
    if (isItemsModalOpen) {
      // Apply the "no-scroll" class to the body element
      document.body.classList.add("no-scroll");
    } else {
      // Remove the "no-scroll" class from the body element
      document.body.classList.remove("no-scroll");
    }
  }, [isItemsModalOpen]);

  const closeItemsModal = () => {

    setIsItemsModalOpen(false)   

    if (props && props.refreshCustomItemsTable) {
        props.refreshCustomItemsTable(); 
    }   

  }


  global.backendUrl = 'https://arihant.trikodev.xyz';  
 

  return (

    <>      

      <button style={{position:'fixed', bottom:0, right:0, backgroundColor:'#5190ED', padding:15, color:'white', fontSize:18, borderWidth:0, 
      display:'flex', flexDirection:'row', justifyContent:'center' }} 
      onClick={openItemsModal}>
        <img src={notePadIcon} style={{width:30, marginRight:10}}/>
        Could not find the item you are looking for? <br/> Click here</button>

      <ReactModal 
                    initWidth={800} 
                    initHeight={520} 
                    onFocus={() => console.log("Modal is clicked")}
                    className={"my-modal-custom-class"}
                    onRequestClose={closeItemsModal} 
                    disableResize
                    disableMove
                    style={{position:'fixed'}}
                    isOpen={isItemsModalOpen}>

                  

                    <div className="body" style={{marginLeft:10}}>    

                     <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <h3 style={{marginLeft:10}}>Enter here the items you could not find</h3>
                        <button style={{backgroundColor:'grey', cursor:'auto', borderWidth:0, marginRight:10, fontSize:20, height:30, marginTop:10, borderRadius:5}} onClick={closeItemsModal}>X</button>
                   </div>


                    <p style={{marginLeft:10}}>This list will be included in your cart</p>

                       
                       <table>
                           <thead>
                               <th style={{textAlign:'left'}}>Item Name</th>
                               <th style={{textAlign:'left'}}>Quantity</th>
                           </thead>

                           <tbody>
                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name1" type="text" value={name1} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity1" type="text" value={quantity1} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>

                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name2" type="text" value={name2} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity2" type="text" value={quantity2} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name3" type="text" value={name3} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity3" type="text" value={quantity3} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name4" type="text" value={name4} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity4" type="text" value={quantity4} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>

                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name5" type="text" value={name5} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity5" type="text" value={quantity5} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name6" type="text" value={name6} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity6" type="text" value={quantity6} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name7" type="text" value={name7} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity7" type="text" value={quantity7} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>

                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name8" type="text" value={name8} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity8" type="text" value={quantity8} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>

                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name9" type="text" value={name9} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity9" type="text" value={quantity9} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name10" type="text" value={name10} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity10" type="text" value={quantity10} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr style={{display:'none'}}>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name11" type="text" value={name11} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity11" type="text" value={quantity11} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr style={{display:'none'}}>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name12" type="text" value={name12} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity12" type="text" value={quantity12} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr style={{display:'none'}}>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name13" type="text" value={name13} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity13" type="text" value={quantity13} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr style={{display:'none'}}>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name14" type="text" value={name14} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity14" type="text" value={quantity14} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>


                              <tr style={{display:'none'}}>
                               <td style={{width:600}}>
                                  <input autoComplete="off" name="name15" type="text" value={name15} className="form-control" style={{width:'100%', height:20}} onChange={handleNameInputChange} data-type="name"/>
                               </td>
                               <td style={{width:100}}>
                                  <input autoComplete="off" name="quantity15" type="text" value={quantity15} className="form-control" style={{width:'100%', height:20}} onChange={handleQuantityInputChange} data-type="quantity"/>
                               </td>
                              </tr>

                              <button className="theButton" style={{marginTop:20, fontSize:14}} onClick={closeItemsModal} >Save Items</button>


                           </tbody>
                       </table>
                    </div>
                   

                </ReactModal>



    </>
  );
};

export default UnavailableItemsEntry;
