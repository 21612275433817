import React, { Fragment, useEffect } from "react";
import Switch from "@material-ui/core/Switch";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from '@mui/material/Badge';

import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Home from "../Home/Home";
import Neworder from "../Neworder/Neworder";
import Account from "../Acoounts/Account";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { logout } from "../../actions/authAction";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import FolderIcon from "@mui/icons-material/Folder";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Cart from "../Ongoing/Cart";
import PastOrders from "../PastOrders/PastOrders";
import { Link, withRouter } from "react-router-dom";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {
  useGmailTabsStyles,
  useGmailTabItemStyles,
} from "@mui-treasury/styles/tabs";

import Inbox from "@material-ui/icons/Inbox";
import LocalOffer from "@material-ui/icons/LocalOffer";
import People from "@material-ui/icons/People";
import Info from "@material-ui/icons/Info";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import { typography } from "@mui/system";
import axios from "axios";
import { strings } from "../../string.js";

import logo from "../../assets/img/arihant_logo.jpeg";
import { tableFooterClasses } from "@mui/material";

const indicatorColors = ["#d93025", "#1a73e8", "#188038", "#e37400"];

const Main = ({ history, server, access_token, login, role, auth, logout }) => {

  const [tabIndex, setTabIndex] = React.useState("0");

  const tabsStyles = useGmailTabsStyles({ indicatorColors });

  const tabItem1Styles = useGmailTabItemStyles({ color: indicatorColors[0] });
  const tabItem2Styles = useGmailTabItemStyles({ color: indicatorColors[1] });
  const tabItem3Styles = useGmailTabItemStyles({ color: indicatorColors[2] });
  const tabItem4Styles = useGmailTabItemStyles({ color: indicatorColors[3] });

  const [isChecked, setIsChecked] = React.useState(false);
  const [numCart, setNumCart] = React.useState(false);

  const customStyle = {
    display: "flex",
    flexDirection: "row",
  }

  useEffect(() => {

    if (strings.getLanguage() == 'hi') {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }

  }, []); // Only re-run the effect if count changes


  useEffect((async) => {

    (async () => {

      try {

        refreshCartQty();

      } catch (error) {
        console.log(error);
      }


    })();


  }, []);



  const refreshCartQty = (qty) => {

    var num = localStorage.getItem('num_of_items_in_cart');

    if (num == null) {
      setNumCart(0);
    } else if (num == '0') {
      setNumCart(0);
    } else {
      setNumCart(num);
    }

  }


  const changeTabIndex = (index) => {

    setTabIndex(index)
  }


  const handleLogOut = () => {

    const config = {
      headers: { Authorization: `Bearer ${access_token}` },
    };
    const parameter = {
      _method: "DELETE",
    };

    logout();
    localStorage.removeItem('disclaimer_accpeted');

    history.push("/");

  };

  const goToNotifications = () => {
    history.push('/notifications');
  }

  const goToHome = () => {
    history.push('/');
  }


  const switchChanged = (e) => {

    if (e.target.checked) {
      strings.setLanguage('hi')
      setIsChecked(true)
    } else {
      strings.setLanguage('en')
      setIsChecked(false)
    }

  }



  const handleChange = (e, index) => {
    setTabIndex(index)

    if(index==1){
      history.push('/new-order')
    }

  }

  return (

    <Fragment>

      <Container maxWidth={false} disableGutters={true}>

        <Box sx={{ bgcolor: "#FFA500" }}>
          <Grid container justifyContent="center" alignItems="center">

            <Grid xs onClick={goToHome} style={{ cursor: 'pointer' }}>
              <img src={logo} width="200" style={{ marginTop: 5 }} />
            </Grid>

            <Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginRight="20px"
                style={{ color: 'white' }}
              >
                {strings.english}
                <Switch
                  defaultChecked={strings.getLanguage() == 'hi'}
                  onChange={switchChanged}
                  checked={isChecked}

                /> {strings.hindi}

                <a onClick={handleLogOut} style={{ fontSize: 12, marginLeft: 50, cursor: 'pointer' }}>
                  {strings.logout}</a>


              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: { xs: "block", md: "none", lg: "none" },
          }}
        >
          <BottomNavigation
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
            }}
            value={tabIndex}
            showLabels
            onChange={handleChange}
            elevation={3} s
          >
            <BottomNavigationAction
              label="DASHBOARD"
              value="0"
              icon={<DashboardIcon />}
            />
            <BottomNavigationAction
              label="NEW ORDER"
              value="1"
              icon={<AddBoxIcon />}
            />
            <BottomNavigationAction
              label="CART"
              value="2"
              icon={<ShoppingCartIcon />}
            />
            <BottomNavigationAction
              label="ORDERS"
              value="3"
              icon={<ShoppingBagIcon />}
            />
            <BottomNavigationAction
              label="PROFILE"
              value="4"
              icon={< PersonIcon />}
            />

          </BottomNavigation>
        </Box>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, { logout })(withRouter(Main));

