import React, { useState, useEffect } from "react";
import "../Acoounts/Account.css";
import { Button, Card } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { strings } from "../../string.js";
import axios from "axios";
import { Image, Breathing } from 'react-shimmer'
import { isMobile, setUserAgent } from "react-device-detect";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress, Snackbar } from '@material-ui/core';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../Home/Header";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Account = ({ history, server, access_token, login, role, auth, logout }) => {

  const [onLoading, setOnLoading] = useState(false)
  const [onSubmitting, setOnSubmitting] = useState(false)
  const [user, setUser] = useState(null)

  const [billingName, setBillingName] = useState('')
  const [ownerName, setOwnerName] = useState('')
  const [email, setEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [whatsappNumber1, setWhatsappNumber1] = useState('')
  const [contactName2, setContactName2] = useState('')
  const [whatsappNumber2, setWhatsappNumber2] = useState('')
  const [contactName3, setContactName3] = useState('')
  const [whatsappNumber3, setWhatsappNumber3] = useState('')
  const [contactName4, setContactName4] = useState('')
  const [whatsappNumber4, setWhatsappNumber4] = useState('')

  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [district, setDistrict] = useState('')

  const [designation1, setDesignation1] = useState('Manager')
  const [designation2, setDesignation2] = useState('Supervisor')
  const [designation3, setDesignation3] = useState('Worker')


  const [tabIndex, setTabIndex] = React.useState("0");

  const handleChange = (e, index) => {
    setTabIndex(index)

    if (index == 0) {
      history.push('/dashboard')
    }

    if (index == 1) {
      history.push('/new-order')
    }

    if (index == 2) {
      history.push('/cart')
    }

    if (index == 3) {
      history.push('/past-orders')
    }

    if (index == 4) {
      history.push('/account')
    }

  }

  useEffect((async) => {

    getMyProfile();

  }, []);

  const getMyProfile = async (json) => {

    setOnLoading(true)

    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch('https://arihant.trikodev.xyz/' + 'api/user/get-my-profile', requestOptions)
      .then(response => response.json())
      .then(data => getProfileResponse(data.user));

  }


  const getProfileResponse = async (user) => {

    setOnLoading(false)
   // setUsername(user.username);
    setBillingName(user.name);
    setOwnerName(user.owner_name);
    setEmail(user.email);
    setContactNumber(user.phone_no);
    setWhatsappNumber1(user.whatsapp_no);
    setContactName2(user.contact_name_2);
    setWhatsappNumber2(user.whatsapp_number_2);
    setContactName3(user.contact_name_3);
    setWhatsappNumber3(user.whatsapp_number_3);
    setContactName4(user.contact_name_4);
    setWhatsappNumber4(user.whatsapp_number_4);

    setAddress(user.address);
    setState(user.state);
    setDistrict(user.district);

  }

  const saveMyProfile = async () => {

    var formData = new FormData();

    setOnSubmitting(true)

    formData.append("whatsapp_no", whatsappNumber1);
    formData.append("phone_no", contactNumber);
    formData.append("owner_name", ownerName);
    formData.append("contact_name_2", contactName2);
    formData.append("whatsapp_number_2", whatsappNumber2);
    formData.append("contact_name_3", contactName3);
    formData.append("whatsapp_number_3", whatsappNumber3);
    formData.append("contact_name_4", contactName4);
    formData.append("whatsapp_number_4", whatsappNumber4);

    const requestOptions = {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch('https://arihant.trikodev.xyz/' + 'api/user/update-my-profile', requestOptions)
      .then(response => response.json())
      .then(data => setSaveResponse(data.user));

  }


  const setSaveResponse = async (user) => {

    setOnSubmitting(false)

    toast(strings.profile_saved_successfully, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  }

  return (
    <>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

        <Header />

{/* Same as */}
<ToastContainer />

        <div className="only-desktop" style={{
          flexDirection: 'row', marginTop: 60,
          position: 'fixed', top: 0, left: 0, right: 0, zIndex: 8000, backgroundColor: '#FCF5E5'
        }}>

          <Link to="/dashboard" style={{
            display: 'flex', flexDirection: 'row', alignItems: 'center',
            width: '20%', borderRadius: 0, textDecoration: 'none', height: 60,
            cursor: 'pointer',
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', color: '#000000',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.dashboard}</span>
          </Link>

          <Link to="/new-order" style={{
            display: 'flex', textDecoration: 'none', borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center', fontSize: 15,
              textTransform: 'uppercase', fontWeight: 700, color: '#000000'
            }}>{strings.new_order}</span>
          </Link>

          <Link to="/cart" style={{
            display: 'flex', textDecoration: 'none',
            borderRadius: 0, flexDirection: 'row', cursor: 'pointer', alignItems: 'center',
            width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', color: '#000000', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.cart}</span>
          </Link>

          <Link to="/past-orders" style={{
            display: 'flex', borderRadius: 0, color: '#000000', textDecoration: 'none',
            flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
            alignSelf: 'center', borderBottom: '2px solid grey'
          }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700, color: '#000000'
            }}>{strings.past_orders}</span>
          </Link>

          <Link to="/account"
            style={{
              display: 'flex', color: '#F05225', borderRadius: 0, textDecoration: 'none',
              flexDirection: 'row', cursor: 'pointer', alignItems: 'center', width: '20%', height: 60,
              borderLeft: '1 px solid black', borderBottom: '4px solid #F05225'
            }}>

            <span style={{
              width: '100%', textAlign: 'center',
              fontSize: 15, textTransform: 'uppercase', fontWeight: 700
            }}>{strings.account}</span>
          </Link>


        </div>



        {onLoading ?

          <div style={{ marginTop: 200, marginLeft: '2%', marginRight: '2%', display: 'flex', flexDirection: 'column' }}>
            <Skeleton height={80} />
            <Skeleton height={20} count={10} />

          </div>
          :
          <div className="main-acc accountPage"
          >

            <h2 style={{ marginLeft: 10 }}>{strings.account_details}</h2>

            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  value={billingName}
                  disabled={true}
                  label={strings.billing_name}
                  variant="standard"
                />
              </div>

              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  value={email}
                  disabled={true}
                  label={strings.email}
                  variant="standard"
                />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  disabled={true}
                  onChange={(e) => setOwnerName(e.target.value)}
                  label={strings.owner_name}
                  value={ownerName}
                  variant="standard"
                />
              </div>
              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  disabled={true}
                  onChange={(e) => setContactNumber(e.target.value)}
                  value={contactNumber}
                  label={strings.contact_number}
                  variant="standard"
                />
              </div>

            </div>


            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  disabled={true}
                  value={whatsappNumber1}
                  onChange={(e) => setWhatsappNumber1(e.target.value)}
                  label={strings.whatsapp_no_1}
                  variant="standard"
                />
              </div>


              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  disabled={true}
                  value={address}
                  label={strings.address}
                  variant="standard"
                />
              </div>

            </div>

            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  disabled={true}
                  value={state}
                  label={strings.state}
                  variant="standard"
                />
              </div>

              <div className="styles">
                <TextField
                  style={{ marginLeft: "0px", width:250 }}
                  id="standard-search"
                  disabled={true}
                  value={district}
                  label={strings.district}
                  variant="standard"
                />
              </div>
            </div>



            <h4 style={{ marginLeft: 8, marginTop: 20 }}>{strings.other_contact_person}</h4>
            <div className="styles" style={{ marginTop: -20 }}>
              <TextField
                style={{ marginLeft: "0px", }}
                id="standard-search"
                onChange={(e) => setContactName2(e.target.value)}
                label={strings.contact_name_2}
                value={contactName2}
                variant="standard"
              />

              <TextField
                style={{ marginLeft: isMobile ? 0 : "45px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                label={strings.designation}
                onChange={(e) => setDesignation1(e.target.value)}
                value={designation1}
                variant="standard"
              />


              <TextField
                style={{ marginLeft: isMobile ? 0 : "45px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                label={strings.whatsapp_no_2}
                onChange={(e) => setWhatsappNumber2(e.target.value)}
                value={whatsappNumber2}
                variant="standard"
              />



            </div>

            <div className="styles">
              <TextField
                style={{ marginLeft: isMobile ? 0 : "0px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                onChange={(e) => setContactName3(e.target.value)}
                label={strings.contact_name_3}
                value={contactName3}
                variant="standard"
              />


              <TextField
                style={{ marginLeft: isMobile ? 0 : "45px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                label={strings.designation}
                onChange={(e) => setDesignation2(e.target.value)}
                value={designation2}
                variant="standard"
              />

              <TextField
                style={{ marginLeft: isMobile ? 0 : "45px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                label={strings.whatsapp_no_3}
                onChange={(e) => setWhatsappNumber3(e.target.value)}
                value={whatsappNumber3}
                variant="standard"
              />

            </div>

            <div className="styles">
              <TextField
                style={{ marginLeft: isMobile ? 0 : "0px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                value={contactName4}
                onChange={(e) => setContactName4(e.target.value)}
                label={strings.contact_name_4}
                variant="standard"
              />

              <TextField
                style={{ marginLeft: isMobile ? 0 : "45px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                label={strings.designation}
                onChange={(e) => setDesignation3(e.target.value)}
                value={designation3}
                variant="standard"
              />


              <TextField
                style={{ marginLeft: isMobile ? 0 : "45px", marginTop: isMobile ? 10 : 0 }}
                id="standard-search"
                value={whatsappNumber4}
                onChange={(e) => setWhatsappNumber4(e.target.value)}
                label={strings.whatsapp_no_4}
                variant="standard"
              />

            </div>
            <div className="acc-submit" style={{ marginTop: 35, marginLeft: -20, width: '100%' }}>
              <button className="btn9"
                onClick={saveMyProfile}
                style={{ width: isMobile?'100%':'30%', borderRadius: 5 }}>
                {onSubmitting ? <CircularProgress size={20} /> : strings.save_profile}
              </button>
            </div>
          </div>
        }

        <Box
          sx={{
            display: { xs: "block", md: "none", lg: "none" },
          }}
        >
          <BottomNavigation
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: '#f0f0f0',
              right: 0,
            }}
            value={"4"}
            showLabels
            onChange={handleChange}
            elevation={3} s
          >
            <BottomNavigationAction
              label="DASHBOARD"
              value="0"
              icon={<DashboardIcon />}
            />
            <BottomNavigationAction
              label="NEW ORDER"
              value="1"
              icon={<AddBoxIcon />}
            />
            <BottomNavigationAction
              label="CART"
              value="2"
              icon={<ShoppingCartIcon />}
            />
            <BottomNavigationAction
              label="ORDERS"
              value="3"
              icon={<ShoppingBagIcon />}
            />
           

          </BottomNavigation>
        </Box>

      </div>


    </>
  );
};

const mapStateToProps = (state) => ({
  server: state.auth.server,
  access_token: state.auth.access_token,
  role: state.auth.role[0],
  point: state.auth.point,
});

export default connect(mapStateToProps, {})(withRouter(Account));