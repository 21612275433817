import React, { useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { Button, Card } from "@material-ui/core";
import CardContent from "@mui/material/CardContent";
import Rating from '@mui/material/Rating';
import Pizza from "../Home/Pizza.jpg";
import Filter from "./Filter";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from "@mui/icons-material/FilterList";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import { Link, withRouter } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import "./New.css";
import UnavailableItemsEntry from '../../UnavailableItemsEntry';
import ConfirmationDialog from './ConfirmationDialog';


import { makeStyles } from "@material-ui/core/styles"
import Formcontorl from "./Formcontrol";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { strings } from "../../string.js";
import Neworder3 from "./Neworder3";
import Neworder4 from "./NewOrder4";
import ReactStars from "react-rating-stars-component";
import { Image, Breathing } from 'react-shimmer'
import CloseIcon from '@mui/icons-material/Close';
import Modal from "@mui/material/Modal";
import { isMobile } from 'react-device-detect';
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import fullFaceImage from "../../assets/img/full_face_new.png";
import halfFaceImage from "../../assets/img/open_face_new.png";
import motoCrossImage from "../../assets/img/motocross-outline.png";
import flipUpImage from "../../assets/img/flip_up_new.png";

import myChoiceFilled from "../../assets/img/mychoice_filled.png";
import myChoiceEmpty from "../../assets/img/mychoice_empty.png";


import { useSwipeable } from "react-swipeable";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import placeholder from "../../assets/img/placeholder.png";
import YouTube from 'react-youtube';
import { MultiSelect } from "react-multi-select-component";

import ReactImageMagnify from 'react-image-magnify';

const Neworder2 = ({ history, server, access_token, login, role, auth, logout }) => {

  const swipeOpenMenuStyles = {
    float: "left",
    position: "fixed",
    width: "33%",
    height: "100%",
    border: "2px dashed gray"
  };

  const bsFilterOptions = [
    { label: "BS4", value: "BS4" },
    { label: "BS6", value: "BS6" },
    { label: "Slim", value: "Slim" },
    { label: "Wide", value: "Wide" },
  ];

  const [selectedBSFilters, setSelectedBSFilters] = useState([]);

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => setFilterOpen(true)
  });

  const customStyle1 = {
    margin: "20px",
    padding: "5px 5px",
    border: "1px solid rgba(0,0,0,.125)",
    backgroundColor: "#ffffff",
    boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
    alignContent: "center",
    width: isMobile ? 320 : 260,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
  }

  const customStyle2 = {
    margin: "20px",
    padding: "5px 5px",
    border: "1px solid rgba(0,0,0,.125)",
    backgroundColor: "#ffffff",
    boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
    alignContent: "center",
    width: isMobile ? 320 : 260,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
  }

  const customStyle4 = {
    margin: "20px",
    padding: "5px 5px",
    border: "1px solid rgba(0,0,0,.125)",
    backgroundColor: "#d6b1b1",
    boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
    alignContent: "center",
    width: isMobile ? 320 : 200,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    position: 'relative',
  }

  const customStyle5 = {
    margin: "20px",
    padding: "5px 5px",
    border: "1px solid rgba(0,0,0,.125)",
    backgroundColor: "#b2acfa",
    boxShadow: "0 1.4rem 3.8rem rgba(0, 0, 0, 0.075)",
    alignContent: "center",
    width: isMobile ? 320 : 200,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    position: 'relative',
  }

  const [images, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [favUnfavProductId, setFavUnfavProductId] = useState(0);
  const [favUnfavProductName, setFavUnfavProductName] = useState("");
  const [favUnfavAction, setFavUnfavAction] = useState('fav');
  

  const [value, setValue] = React.useState(2);
  const [theCartData, setTheCartData] = useState([])
  const [cartTotal, setCartTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [numCart, setNumCart] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const [fitmentOrColorSearchKey, setFitmentOrColorSearchKey] = useState('')

  const scrollableSectionRef = useRef(null);

  const [myChoicePageVisible, setMyChoicePageVisible] = useState(false)


  const [fitments, setFitments] = React.useState([]);
  const [flag, setFlag] = useState(true)
  const [categoriesCount, setCategoriesCount] = useState([])

  const opts = {
    height: '300',
    width: '640',
    playerVars: {      
      autoplay: 1,
    },
  };

  const [filterOpen, setFilterOpen] = useState(true);

  const [underReview, setUnderReview] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [products, setProducts] = React.useState([]);
  const [isFitmentVisible, setIsFitmentVisible] = React.useState(false);
  const [currentProduct, setCurrentProduct] = React.useState(null);
  const [isHelmetsPageVisible, setIsHelmetsPageVisible] = React.useState(false);

  const [currentCategoryId, setCurrentCategoryId] = React.useState(0);
  const [currentCategory, setCurrentCategory] = React.useState(null);

  const [bS4On, setBS4On] = useState(true);
  const [bS6On, setBS6On] = useState(true);
  const [slimOn, setSlimOn] = useState(true);
  const [wideOn, setWideOn] = useState(true);
  const [notApplicableOn, setNotApplicableOn] = useState(true);

  const [brand, setBrands] = useState([]);

  const [fullFaceOn, setFullFaceOn] = useState(false);
  const [flipUpOn, setFlipUpOn] = useState(false);
  const [halfFaceOn, setHalfFaceOn] = useState(false);
  const [motoCrossOn, setMotoCrossOn] = useState(false);

  const [brandNames, setBrandNames] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const [currentImageInGallery, setCurrentImageInGallery] = useState(null);

  const [brandsFilterArray, setBrandsFilterArray] = useState([]);
  const [ratingsFilterArray, setRatingsFilterArray] = useState([]);
  const [priceRangeFilterArray, setPriceRangeFilterArray] = useState([]);
  const [sizeFilterArray, setSizeFilterArray] = useState([]);

  const [fitmentFilterArray, setFitmentFilterArray] = useState([]);
  const [colorFilterArray, setColorFilterArray] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {

    //getProducts();
    //getFitments();
    getColors();
    setSearchKey('');
  }, []);


  const resetSearchKey = () => {
    setSearchKey('')
  };



 const resetScroll = () => {
    if (scrollableSectionRef.current) {
      scrollableSectionRef.current.scrollTop = 0;
    }
  };


  useEffect(() => {

    const containsBS4 = selectedBSFilters.some(filter => filter.value === "BS4");
    const containsBS6 = selectedBSFilters.some(filter => filter.value === "BS6");
    const containsSlim = selectedBSFilters.some(filter => filter.value === "Slim");
    const containsWide = selectedBSFilters.some(filter => filter.value === "Wide");

    if (containsBS4) {
      setBS4On(true);
    } else {
      setBS4On(false);
    }

    if (containsBS6) {
      setBS6On(true);
    } else {
      setBS6On(false);
    }

    if (containsSlim) {
      setSlimOn(true);
    } else {
      setSlimOn(false);
    }

    if (containsWide) {
      setWideOn(true);
    } else {
      setWideOn(false);
    }

  }, [selectedBSFilters]);


  useEffect(() => {

    getFitments();

  }, [currentProduct]);


  const toggleFlag = () => {
    setFlag(!flag)
  }

  const resetSearchKeyAndFilters = () => {

      setSearchKey('');
      setBrandsFilterArray([]);
      setFitmentFilterArray([]);
      setSizeFilterArray([]);
      setColorFilterArray([]);
      setRatingsFilterArray([]);      
  }
  

  const handleImageGalleryThumbClick = (image) => {

        setCurrentImageInGallery(image.original)
  }

  const handleFavoriteConfirm = () => {
    setDialogOpen(false);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    fetch(
      global.backendUrl + "/api/user/favorite-product?product_id="+favUnfavProductId, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        //
        if (json.message === "favorited" || json.message === "unfavorited") {
          setProducts(prevProducts => 
            prevProducts.map(product => 
              product.id === parseInt(json.productId) 
                ? { ...product, is_favorite: json.message === "favorited" ? 1 : 0 }
                : product
            )
          );
        }

      })
       
  }

  const handleFavoriteCancel = () => {

    setDialogOpen(false);
  }

  const handleUnfavoriteConfirm = () => {
    setDialogOpen(false);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };
  
    fetch(
      global.backendUrl + "/api/user/unfavorite-product?product_id="+favUnfavProductId, requestOptions)
      .then((res) => res.json())
      .then((json) => {
        
        if (json.message === "favorited" || json.message === "unfavorited") {
          setProducts(prevProducts => 
            prevProducts.map(product => 
              product.id === parseInt(json.productId) 
                ? { ...product, is_favorite: json.message === "favorited" ? 1 : 0 }
                : product
            )
          );
        }

      })
       
  }

  const handleUnfavoriteCancel = () => {

    setDialogOpen(false);
  }

  const resetHelmetFilters = () => {

    setFullFaceOn(false);
    setFlipUpOn(false);
    setHalfFaceOn(false);
    setMotoCrossOn(false);
  }

  // Add an item to my choice
  const favoriteItem = (productId, productName) => {

    setFavUnfavProductId(productId);
    setFavUnfavProductName(productName);
    setFavUnfavAction('fav');
    setDialogOpen(true);
    setConfirmationMessage("Do you want to add <strong>"+productName+"</strong> into your choice items");
  }

  // Remove an item from my choice
  const unfavoriteItem = (productId, productName) => {

    setFavUnfavProductId(productId);
    setFavUnfavProductName(productName);
    setFavUnfavAction('unfav');
    setDialogOpen(true);
    setConfirmationMessage("Do you want to remove <strong>"+productName+"</strong> from your choice items");
   
  }

  const toggleVideoView = () => {
    setShowVideo(!showVideo)
  }

  const _onReady = (event) => {
    event.target.pauseVideo();
  }

  const getFitments = () => {

    if (currentProduct) {

      fetch(
        global.backendUrl + "/api/get-all-fitments-for-a-product?product_id=" + currentProduct.id)
        .then((res) => res.json())
        .then((json) => {
          setFitments(json.fitments)
        })
    }

  };

  const getColors = () => {

    fetch(
      global.backendUrl + "/api/get-all-colors?category_id=" + currentCategoryId)
      .then((res) => res.json())
      .then((json) => {
        setColorFilterArray(json.colors)
      })
  };


  const openProductModal = (item) => {

    var tmpImages = [];

    if (!item.product_image_path &&
      !item.product_image_path2 &&
      !item.product_image_path3 &&
      !item.product_image_path4 &&
      !item.product_image_path5 &&
      !item.product_image_path6 &&
      !item.product_image_path7 &&
      !item.product_image_path8 &&
      !item.product_image_path9 &&
      !item.product_image_path10
    ) {

      var tmpImage = {};
      tmpImage.original = placeholder;
      tmpImage.thumbnail = placeholder;
      tmpImage.originalHeight = 300;
      tmpImages.push(tmpImage);

      setImages(tmpImages);
      setCurrentImageInGallery(tmpImages[0].original)

      setOpenModal(true);
      setSelectedProduct(item);

    } else {

      if (item.product_image_path) {

        var tmpImage = {};
        tmpImage.original = global.backendUrl + '/' + item.product_image_path;
        tmpImage.thumbnail = global.backendUrl + '/' + item.product_image_path;
        tmpImage.originalHeight = 300;
        tmpImages.push(tmpImage);
      }

      if (item.product_image_path2) {

        var tmpImage2 = {};
        tmpImage2.original = global.backendUrl + '/' + item.product_image_path2;
        tmpImage2.thumbnail = global.backendUrl + '/' + item.product_image_path2;
        tmpImage2.originalHeight = 300;
        tmpImages.push(tmpImage2);
      }

      if (item.product_image_path3) {

        var tmpImage3 = {};
        tmpImage3.original = global.backendUrl + '/' + item.product_image_path3;
        tmpImage3.thumbnail = global.backendUrl + '/' + item.product_image_path3;
        tmpImage3.originalHeight = 300;
        tmpImages.push(tmpImage3);
      }

      if (item.product_image_path4) {

        var tmpImage4 = {};
        tmpImage4.original = global.backendUrl + '/' + item.product_image_path4;
        tmpImage4.thumbnail = global.backendUrl + '/' + item.product_image_path4;
        tmpImage4.originalHeight = 300;
        tmpImages.push(tmpImage4);
      }


      if (item.product_image_path5) {

        var tmpImage5 = {};
        tmpImage5.original = global.backendUrl + '/' + item.product_image_path5;
        tmpImage5.thumbnail = global.backendUrl + '/' + item.product_image_path5;
        tmpImage5.originalHeight = 300;
        tmpImages.push(tmpImage5);
      }


      if (item.product_image_path6) {

        var tmpImage6 = {};
        tmpImage6.original = global.backendUrl + '/' + item.product_image_path6;
        tmpImage6.thumbnail = global.backendUrl + '/' + item.product_image_path6;
        tmpImage6.originalHeight = 300;
        tmpImages.push(tmpImage6);
      }

      if (item.product_image_path7) {

        var tmpImage7 = {};
        tmpImage7.original = global.backendUrl + '/' + item.product_image_path7;
        tmpImage7.thumbnail = global.backendUrl + '/' + item.product_image_path7;
        tmpImage7.originalHeight = 300;
        tmpImages.push(tmpImage7);
      }


      if (item.product_image_path8) {

        var tmpImage8 = {};
        tmpImage8.original = global.backendUrl + '/' + item.product_image_path8;
        tmpImage8.thumbnail = global.backendUrl + '/' + item.product_image_path8;
        tmpImage8.originalHeight = 300;
        tmpImages.push(tmpImage8);
      }

      if (item.product_image_path9) {

        var tmpImage9 = {};
        tmpImage9.original = global.backendUrl + '/' + item.product_image_path9;
        tmpImage9.thumbnail = global.backendUrl + '/' + item.product_image_path9;
        tmpImage9.originalHeight = 300;
        tmpImages.push(tmpImage9);
      }

      if (item.product_image_path10) {

        var tmpImage10 = {};
        tmpImage10.original = global.backendUrl + '/' + item.product_image_path10;
        tmpImage10.thumbnail = global.backendUrl + '/' + item.product_image_path10;
        tmpImage10.originalHeight = 300;

        tmpImages.push(tmpImage10);
      }


      setImages(tmpImages);
      setCurrentImageInGallery(tmpImages[0].original)

      setOpenModal(true);
      setSelectedProduct(item);
    }
  }


  const handleChange = (event) => {
    setSearchKey(event.target.value)
  }

  const handleChangeFitmentOrColorSearch = (event) => {
    setFitmentOrColorSearchKey(event.target.value)
  }


  const handleClose = () => {
    setOpenModal(false);
    setShowVideo(false)
  }


  const getProducts = (categoryId, isMyChoice) => {

    setIsLoadingProducts(true);
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    };

    if(isMyChoice){

      fetch(
        global.backendUrl + "/api/user/get-favorited-products", requestOptions)
        .then((res) => res.json())
        .then((json) => {


          // Sort the products so that items with is_favorite == 1 come first
      const sortedProducts = json.products.sort((a, b) => {
        return b.is_favorite - a.is_favorite;
      });

      setProducts(sortedProducts);
          setIsLoadingProducts(false)
        })

    }else{


    fetch(
      global.backendUrl + "/api/user/get-all-products?category_id="+categoryId, requestOptions)
      .then((res) => res.json())
      .then((json) => {

        // Sort the products so that items with is_favorite == 1 come first
      const sortedProducts = json.products.sort((a, b) => {
        return b.is_favorite - a.is_favorite;
      });

      setProducts(sortedProducts);
        setIsLoadingProducts(false)
      })

    }

  };

  const handleAccept = () => {
    setUnderReview(true);
  };


  const viewFitment = (item) => {

    setIsFitmentVisible(true);
    setCurrentProduct(item);

    resetScroll();
  };


  const minusCart = async (item) => {

    try {

      // Check if the current quantity of this item is 1. If yes, it needs to be removed.
      // Else, its quantity will be decreased by 1          
      var indexOfTheCartItem = item.index;

      var itemQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);

      itemQty = parseInt(itemQty);

      if (itemQty == 1) {

        // Remove the item from cart(set it as inactive)) and setState
        localStorage.setItem('cart_item_product_status_' + indexOfTheCartItem, 'inactive');

        var prevQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);
        prevQty = parseInt(prevQty);

        var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');
        prevNumOfCartItems = parseInt(prevNumOfCartItems);

        var newNumOfCartItems = prevNumOfCartItems - prevQty;

        localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, 0);
        localStorage.setItem('num_of_items_in_cart', newNumOfCartItems);
        setNumCart(newNumOfCartItems);

      } else {

        // Reduce the quantity of the cart item and set state
        itemQty = itemQty - 1;

        localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, itemQty + '');

        var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');

        prevNumOfCartItems = parseInt(prevNumOfCartItems);
        prevNumOfCartItems = prevNumOfCartItems - 1;

        localStorage.setItem('num_of_items_in_cart', '' + prevNumOfCartItems);
        setNumCart(prevNumOfCartItems);

        // Check the quantity and set the price accordingly
        var price = item.price;

        localStorage.setItem('cart_item_product_price_' + indexOfTheCartItem, price + '');
      }

      getCartData();

    }
    catch (error) {
      // console.log('error:' + error)
    }
  };


  // Increase cart item qty
  const plusCart = (item) => {

    var indexOfTheCartItem = item.index;

    var itemQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);

    itemQty = parseInt(itemQty);

    // Increment the quantity of the cart item and set state
    itemQty = itemQty + 1;
    localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, itemQty);

    var newQty = itemQty + 1;

    var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');

    prevNumOfCartItems = parseInt(prevNumOfCartItems);
    prevNumOfCartItems = prevNumOfCartItems + 1;

    localStorage.setItem('num_of_items_in_cart', prevNumOfCartItems);
    setNumCart(prevNumOfCartItems);

    // Check the quantity and set the price accordingly
    var price = item.product_price;

    localStorage.setItem('cart_item_product_price_' + indexOfTheCartItem, price);

    getCartData();

  };


  const plusHandler = async (item, countValue) => {   


    if (countValue == 0) {

      addItemToCart(item.id, item.name, item.tally_import_name, item.price,
        item.category.name, item.color, item.model, item.size, item.brand.name, item.fitment_for,
        item.fitment_name, item.fitment_type, item.product_bucket, item.bucket_price, item.product_thumbnail_path)

    } else {
      plusCart(item)
    }

  }

  const minusHandler = async (item, countValue) => {

    if (countValue <= 1) {
      removeFromCart(item);
    } else {
      minusCart(item)
    }

  }

  // Sidebox filters
  const toggleBS4 = async () => {

    setBS4On(!bS4On);
  }

  const toggleBS6 = async () => {

    setBS6On(!bS6On);
  }

  const toggleSlim = async () => {

    setSlimOn(!slimOn);
  }

  const toggleWide = async () => {

    setWideOn(!wideOn);
  }


  // Helmet filters
  const toggleFullFaceHelmets = async () => {

    setFullFaceOn(!fullFaceOn);
  }

  const toggleFlipUpHelmets = async () => {

    setFlipUpOn(!flipUpOn);
  }

  const toggleHalfFaceHelmets = async () => {

    setHalfFaceOn(!halfFaceOn);
  }

  const toggleMotoCrossHelmets = async () => {

    setMotoCrossOn(!motoCrossOn);
  }


  // Remove from cart
  const removeFromCart = async (item) => {


    var indexOfTheCartItem = item.index;

    localStorage.setItem('cart_item_product_status_' + indexOfTheCartItem, 'inactive');

    var prevQty = localStorage.getItem('cart_item_product_qty_' + indexOfTheCartItem);
    prevQty = parseInt(prevQty);

    var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');
    prevNumOfCartItems = parseInt(prevNumOfCartItems);

    var newNumOfCartItems = prevNumOfCartItems - prevQty;

    localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, 0);
    localStorage.setItem('num_of_items_in_cart', newNumOfCartItems);

    setNumCart(newNumOfCartItems);

    getCartData();
  };



  const addItemToCart = async (productID, productName, tallyImportName, unitPrice, category, color, model, size, brand,
    fitmentFor, fitmentName, fitmentType, productBucket, bucketPrice, productImage) => {      


    try {

      // Check if this item is already available in the local storage cart data
      // If, yes just increment the quantity by 1.
      // Else, add a new cart item with incremented index and set the data
      var highestCartIndex = localStorage.getItem('highest_cart_index');

      if (highestCartIndex == null) {
        highestCartIndex = '0';
      }


      // Quantity based pricing
      var price = unitPrice;

      if (highestCartIndex == '0') {        

        localStorage.setItem('cart_item_product_id_1', productID);
        localStorage.setItem('cart_item_product_name_1', productName);
        localStorage.setItem('cart_item_tally_import_name_1', tallyImportName);
        localStorage.setItem('cart_item_product_price_1', price);
        localStorage.setItem('cart_item_product_qty_1', 1);
        localStorage.setItem('cart_item_product_status_1', 'active');
        localStorage.setItem('cart_item_product_category_1', category);
        localStorage.setItem('cart_item_product_color_1', color);
        localStorage.setItem('cart_item_product_model_1', model);
        localStorage.setItem('cart_item_product_size_1', size);
        localStorage.setItem('cart_item_product_brand_1', brand);
        localStorage.setItem('cart_item_product_fitment_for_1', fitmentFor);
        localStorage.setItem('cart_item_product_fitment_name_1', fitmentName);
        localStorage.setItem('cart_item_product_type_1', fitmentType);
        localStorage.setItem('cart_item_product_bucket_1', productBucket);
        localStorage.setItem('cart_item_bucket_price_1', bucketPrice);
        localStorage.setItem('cart_item_product_image_1', productImage);
        localStorage.setItem('num_of_items_in_cart', 1);
        localStorage.setItem('highest_cart_index', 1);        

      } else {
        
        var isItemInCart = 0;

        for (var i = 1; i <= (parseInt(highestCartIndex)); i++) {

          // Check if the current product is there in the cart
          // if there, increment its quantity
          var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
          var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
          var cartItemTallyImportName = localStorage.getItem('cart_item_tally_import_name_' + i);
          var cartItemProductPrice = localStorage.getItem('cart_item_product_price_' + i);
          var cartItemProductQty = localStorage.getItem('cart_item_product_qty_' + i);

          if (cartItemProductId == '' + productID) {           

            var numOfItemsInCart = localStorage.getItem('num_of_items_in_cart');

            var cartItemProductQty = parseInt(cartItemProductQty) + 1;

            localStorage.setItem('cart_item_product_name_' + i, cartItemProductName);
            localStorage.setItem('cart_item_tally_import_name_' + i, cartItemTallyImportName);
            localStorage.setItem('cart_item_product_qty_' + i, (parseInt(cartItemProductQty)) + '');
            localStorage.setItem('cart_item_product_status_' + i, 'active');
            localStorage.setItem('cart_item_product_price_' + i, '' + price);
            localStorage.setItem('cart_item_product_category_' + i, category);
            localStorage.setItem('cart_item_product_color_' + i, color);
            localStorage.setItem('cart_item_product_model_' + i, model);
            localStorage.setItem('cart_item_product_size_' + i, size);
            localStorage.setItem('cart_item_product_brand_' + i, brand);
            localStorage.setItem('cart_item_product_fitment_for_' + i, fitmentFor);
            localStorage.setItem('cart_item_product_fitment_name_' + i, fitmentName);
            localStorage.setItem('cart_item_product_type_' + i, fitmentType);
            localStorage.setItem('cart_item_product_bucket_' + i, productBucket);
            localStorage.setItem('cart_item_bucket_price_' + i, bucketPrice);
            localStorage.setItem('cart_item_product_image_' + i, productImage);
            localStorage.setItem('num_of_items_in_cart', (parseInt(numOfItemsInCart) + 1) + '');

            isItemInCart = 1;
          }

        }

        // If the item was not found in cart
        if (isItemInCart == 0) {
          

          highestCartIndex = parseInt(highestCartIndex) + 1;

          localStorage.setItem('cart_item_product_id_' + highestCartIndex, productID);
          localStorage.setItem('cart_item_product_name_' + highestCartIndex, productName);
          localStorage.setItem('cart_item_tally_import_name_' + highestCartIndex, tallyImportName);
          localStorage.setItem('cart_item_product_price_' + highestCartIndex, price);
          localStorage.setItem('cart_item_product_qty_' + highestCartIndex, 1);
          localStorage.setItem('cart_item_product_status_' + highestCartIndex, 'active');
          localStorage.setItem('cart_item_product_price_' + highestCartIndex, '' + price);
          localStorage.setItem('cart_item_product_category_' + highestCartIndex, category);
          localStorage.setItem('cart_item_product_color_' + highestCartIndex, color);
          localStorage.setItem('cart_item_product_model_' + highestCartIndex, model);
          localStorage.setItem('cart_item_product_size_' + highestCartIndex, size);
          localStorage.setItem('cart_item_product_brand_' + highestCartIndex, brand);
          localStorage.setItem('cart_item_product_fitment_for_' + highestCartIndex, fitmentFor);
          localStorage.setItem('cart_item_product_fitment_name_' + highestCartIndex, fitmentName);
          localStorage.setItem('cart_item_product_type_' + highestCartIndex, fitmentType);
          localStorage.setItem('cart_item_product_bucket_' + highestCartIndex, productBucket);
          localStorage.setItem('cart_item_bucket_price_' + highestCartIndex, bucketPrice);
          localStorage.setItem('cart_item_product_image_' + highestCartIndex, productImage);
          var numOfItemsInCart = localStorage.getItem('num_of_items_in_cart');

          localStorage.setItem('num_of_items_in_cart', (parseInt(numOfItemsInCart) + 1));
          localStorage.setItem('highest_cart_index', (parseInt(highestCartIndex)));

        } else {


        }
      }

      //props.refreshCartQty();
      getCartData();
    }
    catch (error) {
      //alert('aa' + error)
    }

  };


  const addItemToCartWithQty = async (productID, productName, tallyImportName, unitPrice, category, color, model, size, brand,
    fitmentFor, fitmentName, fitmentType, productBucket, bucketPrice, qty, productImage) => {

    try {

      // Check if this item is already available in the local storage cart data
      // If, yes just increment the quantity by 1.
      // Else, add a new cart item with incremented index and set the data
      var highestCartIndex = localStorage.getItem('highest_cart_index');

      if (highestCartIndex == null) {
        highestCartIndex = '0';
      }


      // Quantity based pricing
      var price = unitPrice;

      if (highestCartIndex == '0') {

        localStorage.setItem('cart_item_product_id_1', productID);
        localStorage.setItem('cart_item_product_name_1', productName);
        localStorage.setItem('cart_item_tally_import_name_1', tallyImportName);
        localStorage.setItem('cart_item_product_price_1', price);
        localStorage.setItem('cart_item_product_qty_1', qty);
        localStorage.setItem('cart_item_product_status_1', 'active');
        localStorage.setItem('cart_item_product_category_1', category);
        localStorage.setItem('cart_item_product_color_1', color);
        localStorage.setItem('cart_item_product_model_1', model);
        localStorage.setItem('cart_item_product_size_1', size);
        localStorage.setItem('cart_item_product_brand_1', brand);
        localStorage.setItem('cart_item_product_fitment_for_1', fitmentFor);
        localStorage.setItem('cart_item_product_fitment_name_1', fitmentName);
        localStorage.setItem('cart_item_product_type_1', fitmentType);
        localStorage.setItem('cart_item_product_image_1', productImage);
        localStorage.setItem('cart_item_product_bucket_1', productBucket);
        localStorage.setItem('cart_item_bucket_price_1', bucketPrice);
        localStorage.setItem('num_of_items_in_cart', 1);
        localStorage.setItem('highest_cart_index', 1);

      } else {

        var isItemInCart = 0;

        for (var i = 1; i <= (parseInt(highestCartIndex)); i++) {

          // Check if the current product is there in the cart
          // if there, increment its quantity
          var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
          var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
          var cartItemProductPrice = localStorage.getItem('cart_item_product_price_' + i);
          var cartItemProductQty = localStorage.getItem('cart_item_product_qty_' + i);

          if (cartItemProductId == '' + productID) {

            var numOfItemsInCart = localStorage.getItem('num_of_items_in_cart');

            var cartItemProductQty = parseInt(cartItemProductQty) + 1;

            localStorage.setItem('cart_item_product_qty_' + i, qty);
            localStorage.setItem('cart_item_product_status_' + i, 'active');
            localStorage.setItem('cart_item_product_price_' + i, '' + price);
            localStorage.setItem('cart_item_product_category_' + i, category);
            localStorage.setItem('cart_item_product_color_' + i, color);
            localStorage.setItem('cart_item_product_model_' + i, model);
            localStorage.setItem('cart_item_product_size_' + i, size);
            localStorage.setItem('cart_item_product_brand_' + i, brand);
            localStorage.setItem('cart_item_product_fitment_for_' + i, fitmentFor);
            localStorage.setItem('cart_item_product_fitment_name_' + i, fitmentName);
            localStorage.setItem('cart_item_product_type_' + i, fitmentType);
            localStorage.setItem('cart_item_product_bucket_' + i, productBucket);
            localStorage.setItem('cart_item_bucket_price_' + i, bucketPrice);
            localStorage.setItem('cart_item_product_image_' + i, productImage);
            localStorage.setItem('num_of_items_in_cart', (parseInt(numOfItemsInCart) + 1) + '');

            isItemInCart = 1;
          }

        }

        // If the item was not found in cart
        if (isItemInCart == 0) {


          highestCartIndex = parseInt(highestCartIndex) + 1;

          localStorage.setItem('cart_item_product_id_' + highestCartIndex, productID);
          localStorage.setItem('cart_item_product_name_' + highestCartIndex, productName);
          localStorage.setItem('cart_item_tally_import_name_' + highestCartIndex, tallyImportName);
          localStorage.setItem('cart_item_product_price_' + highestCartIndex, price);

          localStorage.setItem('cart_item_product_qty_' + highestCartIndex, 1);
          localStorage.setItem('cart_item_product_status_' + highestCartIndex, 'active');

          localStorage.setItem('cart_item_product_price_' + highestCartIndex, '' + price);

          localStorage.setItem('cart_item_product_category_' + highestCartIndex, category);
          localStorage.setItem('cart_item_product_color_' + highestCartIndex, color);
          localStorage.setItem('cart_item_product_model_' + highestCartIndex, model);
          localStorage.setItem('cart_item_product_size_' + highestCartIndex, size);
          localStorage.setItem('cart_item_product_brand_' + highestCartIndex, brand);

          localStorage.setItem('cart_item_product_fitment_for_' + highestCartIndex, fitmentFor);
          localStorage.setItem('cart_item_product_fitment_name_' + highestCartIndex, fitmentName);
          localStorage.setItem('cart_item_product_type_' + highestCartIndex, fitmentType);
          localStorage.setItem('cart_item_product_bucket_' + highestCartIndex, productBucket);
          localStorage.setItem('cart_item_product_image_' + highestCartIndex, productImage);
          localStorage.setItem('cart_item_bucket_price_' + highestCartIndex, bucketPrice);

          var numOfItemsInCart = localStorage.getItem('num_of_items_in_cart');

          localStorage.setItem('num_of_items_in_cart', (parseInt(numOfItemsInCart) + 1));
          localStorage.setItem('highest_cart_index', (parseInt(highestCartIndex)));

        } else {

        }
      }

      //props.refreshCartQty();
      getCartData();
    }
    catch (error) {
      //alert('aa' + error)
    }

  };

  const changeQty = async (item, e) => {


    var indexOfTheCartItem = e.target.name;

    var itemQty = e.target.value;

    if (e.target.value !== '') {


      // if the item does not already exists in the cart
      if (indexOfTheCartItem) {


        localStorage.setItem('cart_item_product_qty_' + indexOfTheCartItem, itemQty);

        var prevNumOfCartItems = localStorage.getItem('num_of_items_in_cart');

        prevNumOfCartItems = parseInt(prevNumOfCartItems);
        prevNumOfCartItems = itemQty;

        localStorage.setItem('num_of_items_in_cart', prevNumOfCartItems);
        setNumCart(prevNumOfCartItems);

      } else {        

        addItemToCartWithQty(item.id, item.name, item.tally_import_name, item.price,
          item.category.name, item.color, item.model, item.size, item.brand.name, item.fitment_for,
          item.fitment_name, item.fitment_type, item.product_bucket, item.bucket_price, e.target.value, item.product_thumbnail_path)

      }

      getCartData();
    }
  }


  const comeBack = async () => {
    setUnderReview(false);
  }

  const toggleFilter = async () => {
    setFilterOpen(!filterOpen)
  }

  const goBackToProdView = async () => {

    setIsFitmentVisible(false);

    // Also set the search key as blank for the fitment/color variation page search bar
    setFitmentOrColorSearchKey('');

  }


  const getCartData = async (json) => {

    try {

      var highestCartIndex = localStorage.getItem('highest_cart_index');

      var cartData = [];

      var theSubTotal = 0;
      var theCartTotal = 0;

      for (var i = 1; i <= parseInt(highestCartIndex); i++) {

        var cartItemProductId = localStorage.getItem('cart_item_product_id_' + i);
        var cartItemProductName = localStorage.getItem('cart_item_product_name_' + i);
        var cartItemTallyImportName = localStorage.getItem('cart_item_tally_import_name_' + i);

        var cartItemPrice = localStorage.getItem('cart_item_product_price_' + i);
        var cartItemQty = localStorage.getItem('cart_item_product_qty_' + i);
        var cartItemStatus = localStorage.getItem('cart_item_product_status_' + i);

        var cartItemCategory = localStorage.getItem('cart_item_product_category_' + i);
        var cartItemColor = localStorage.getItem('cart_item_product_color_' + i);
        var cartItemSize = localStorage.getItem('cart_item_product_size_' + i);
        var cartItemBrand = localStorage.getItem('cart_item_product_brand_' + i);

        var fitmentFor = localStorage.getItem('cart_item_product_fitment_for_' + i);
        var fitmentName = localStorage.getItem('cart_item_product_fitment_name_' + i);
        var type = localStorage.getItem('cart_item_product_type_' + i);
        var productBucket = localStorage.getItem('cart_item_product_bucket_' + i);
        var bucketPrice = localStorage.getItem('cart_item_bucket_price_' + i);


        if (cartItemStatus == 'active') {

          cartData.push({
            'product_id': cartItemProductId,
            'product_name': cartItemProductName,
            'tally_import_name': cartItemTallyImportName,
            'product_price': cartItemPrice,
            'qty': cartItemQty,
            'index': i,
            'status': cartItemStatus,
            'category': cartItemCategory,
            'color': cartItemColor,
            'size': cartItemSize,
            'brand': cartItemBrand,
            'fitment_for': fitmentFor,
            'fitment_name': fitmentName,
            'fitment_type': type,
            'product_bucket': productBucket,
            'bucket_price': bucketPrice,
          });

          var lineTotal = parseFloat(cartItemPrice) * parseInt(cartItemQty);

          theSubTotal = theSubTotal + parseFloat(lineTotal);
          theCartTotal = theCartTotal + parseFloat(lineTotal);
        }

      }


      setCartTotal(theCartTotal);
      setSubTotal(theSubTotal);

      setTheCartData(cartData);

    } catch (error) {
      //console.log(error);
    }

  };


  useEffect((async) => {

    (async () => {

      try {

        getCartData();

        var num = localStorage.getItem('num_of_items_in_cart');

        if (num == null) {
          setNumCart(0);
        } else if (num == '0') {

          setNumCart(0);
        } else {
          setNumCart(num);
        }

      } catch (error) {
        //console.log(error);
      }

    })();

  }, []);



  function isInt(value) {

    return !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10));
  }


  var theProducts = [];
  var addedProductNames = [];
  var addedProductIds = [];
  

  for (var i = 0; i < products.length; i++) {

    if (!addedProductNames.includes(products[i].name)
      && (products[i].category_id == currentCategoryId || currentCategoryId==99999) ) {


      // Filtering with brand, size, ratings, fitment for
      if ((brandsFilterArray.includes(products[i].brand_id) || brandsFilterArray.length == 0)
        && (sizeFilterArray.includes(products[i].size) || sizeFilterArray.length == 0)
        && (ratingsFilterArray.includes(products[i].rating_in_stars + '') || ratingsFilterArray.length == 0)
      ) {

        if (priceRangeFilterArray.length == 0) {


          if (!addedProductIds.includes(products[i].id)) {

            if (currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet')) {

              //
              if (products[i].variant == 'Flip') {

                // If none if the filters is selected, show all products
                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                } else if (flipUpOn == true) {
                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Half Face') {

                // If none if the filters is selected, show all products
                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);

                } else if (halfFaceOn == true) {
                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Full Face') {

                // If none if the filters is selected, show all products
                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);

                } else if (fullFaceOn == true) {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Motocross') {

                // If none if the filters is selected, show all products
                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);

                } else if (motoCrossOn == true) {
                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }
              } else { // If the product has no variant in it

                theProducts.push(products[i]);
                addedProductNames.push(products[i].name);
                addedProductIds.push(products[i].id);
              }
            }
            //
            else {

              theProducts.push(products[i]);
              addedProductNames.push(products[i].name);
              addedProductIds.push(products[i].id);
            }
            

            

          }
        } else {


          // Filtering with price
          if (priceRangeFilterArray.includes('₹0 - ₹499')) {

            if (!addedProductIds.includes(products[i].id)) {

              if ((products[i].price > 0 && products[i].price <= 499)) {


                if (currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet')) {

                  //
                  if (products[i].variant == 'Flip') {

                    // If none if the filters is selected, show all products
                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    } else if (flipUpOn == true) {
                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Half Face') {

                    // If none if the filters is selected, show all products
                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (halfFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Full Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (fullFaceOn == true) {
                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Motocross') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (motoCrossOn == true) {
                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }
                  } else { // If the product has no variant in it

                    theProducts.push(products[i]);
                    addedProductNames.push(products[i].name);
                    addedProductIds.push(products[i].id);
                  }
                }
                //
                else {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }

              }
            }
          }

          if (priceRangeFilterArray.includes('₹500 - ₹999')) {

           /* if (!addedProductIds.includes(products[i].id)) {

              if ((products[i].price >= 500 && products[i].price <= 999) || (priceRangeFilterArray.length == 0)) {
                theProducts.push(products[i]);
                addedProductNames.push(products[i].name);
                addedProductIds.push(products[i].id);
              }
            }
            */

            if (!addedProductIds.includes(products[i].id)) {

              if ((products[i].price >= 500 && products[i].price <= 999) || (priceRangeFilterArray.length == 0)) {

                if (currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet')) {
                  //
                  if (products[i].variant == 'Flip') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (flipUpOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Half Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (halfFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Full Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (fullFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Motocross') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (motoCrossOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }
                  } else { // If the product has no variant in it

                    theProducts.push(products[i]);
                    addedProductNames.push(products[i].name);
                    addedProductIds.push(products[i].id);
                  }
                }
                //
                else {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }
              }
            }


          }




          if (priceRangeFilterArray.includes('₹1000 - ₹1499')) {

            if (!addedProductIds.includes(products[i].id)) {

              if ((products[i].price >= 1000 && products[i].price <= 1499) || (priceRangeFilterArray.length == 0)) {

                if (currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet')) {
                  //
                  if (products[i].variant == 'Flip') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (flipUpOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Half Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (halfFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Full Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (fullFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Motocross') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (motoCrossOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }
                  } else { // If the product has no variant in it

                    theProducts.push(products[i]);
                    addedProductNames.push(products[i].name);
                    addedProductIds.push(products[i].id);
                  }
                }
                //
                else {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }
              }
            }

          }




          if (priceRangeFilterArray.includes('₹1500 - ₹1999')) {

            if (!addedProductIds.includes(products[i].id)) {

              if ((products[i].price >= 1500 && products[i].price <= 1999) || (priceRangeFilterArray.length == 0)) {

                if (currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet')) {
                  //
                  if (products[i].variant == 'Flip') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (flipUpOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Half Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (halfFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Full Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (fullFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Motocross') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (motoCrossOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }
                  } else { // If the product has no variant in it

                    theProducts.push(products[i]);
                    addedProductNames.push(products[i].name);
                    addedProductIds.push(products[i].id);
                  }
                }
                //
                else {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }

              }
            }

          }

          if (priceRangeFilterArray.includes('₹2000 - ₹2499')) {

            if (!addedProductIds.includes(products[i].id)) {

              if ((products[i].price >= 2000 && products[i].price <= 2499)) {

                if (currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet')) {
                  //
                  if (products[i].variant == 'Flip') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (flipUpOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Half Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (halfFaceOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Full Face') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (fullFaceOn == true) {
                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }

                  } else if (products[i].variant == 'Motocross') {

                    if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);

                    } else if (motoCrossOn == true) {

                      theProducts.push(products[i]);
                      addedProductNames.push(products[i].name);
                      addedProductIds.push(products[i].id);
                    }
                  } else { // If the product has no variant in it

                    theProducts.push(products[i]);
                    addedProductNames.push(products[i].name);
                    addedProductIds.push(products[i].id);
                  }
                }
                //
                else {

                  theProducts.push(products[i]);
                  addedProductNames.push(products[i].name);
                  addedProductIds.push(products[i].id);
                }

              }
            }
          }
        }
      }


    }else{
      //theProducts.push(products[i]);
    }
  }



  var cartizedProducts = [];
  var currentProducts = [];


  if (currentProduct) {

    var currentProductName = currentProduct.name;

    console.log('productsssss:',products)

    for (var i = 0; i < products.length; i++) {


      if (products[i].fitment_for) {


        if (products[i].name == currentProductName && (products[i].category_id == currentCategoryId || currentCategoryId==99999)) {


        

          if (products[i].filters_applicable == 'BS4') {


            if (bS4On == false && bS6On == false && slimOn == false && wideOn == false
              && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {
                

              currentProducts.push(products[i]);

            } else if (bS4On == true && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {

              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'BS6' && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {



            if (bS4On == false && bS6On == false && slimOn == false && wideOn == false
              && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {

              currentProducts.push(products[i]);

            } else if (bS6On == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Slim' && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {


            if (bS4On == false && bS6On == false && slimOn == false && wideOn == false
              && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {

              currentProducts.push(products[i]);

            } else if (slimOn == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Wide'
            && (fitmentFilterArray.includes('' + products[i].fitment_for)
              || fitmentFilterArray.length == 0)) {


            if (bS4On == false && bS6On == false && slimOn == false && wideOn == false
              && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {

              currentProducts.push(products[i]);

            } else if (wideOn == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Not Applicable'
            && (fitmentFilterArray.includes('' + products[i].fitment_for)
              || fitmentFilterArray.length == 0)) {


            if (bS4On == false && bS6On == false && slimOn == false && wideOn == false
              && (fitmentFilterArray.includes('' + products[i].fitment_for) || fitmentFilterArray.length == 0)) {

              currentProducts.push(products[i]);

            } else if (notApplicableOn == true) {

              currentProducts.push(products[i]);
            }
          }else{
            currentProducts.push(products[i]);
          }

        
        }

      } else if (products[i].color) {

        if (products[i].name == currentProductName && (products[i].category_id == currentCategoryId || currentCategoryId==99999)) {


          if (products[i].filters_applicable == 'BS4') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {
              currentProducts.push(products[i]);
            } else if (bS4On == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'BS6') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {
              currentProducts.push(products[i]);
            } else if (bS6On == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Slim') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {
              currentProducts.push(products[i]);
            } else if (slimOn == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Wide') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {
              currentProducts.push(products[i]);
            } else if (wideOn == true) {
              currentProducts.push(products[i]);
            }
          } else if (products[i].filters_applicable == 'Not Applicable') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {
              currentProducts.push(products[i]);
            } else if (notApplicableOn == true) {
              currentProducts.push(products[i]);
            }

          }else{
            currentProducts.push(products[i]);
          }
        }
      }else {

        if (products[i].name == currentProductName && (products[i].category_id == currentCategoryId || currentCategoryId==99999)) {

          if(currentCategoryId==99999){
            currentProducts.push(products[i]);
          }else{

          if (products[i].filters_applicable == 'BS4') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {

              currentProducts.push(products[i]);

            } else if (bS4On == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'BS6') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {

              currentProducts.push(products[i]);

            } else if (bS6On == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Slim') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {

              currentProducts.push(products[i]);

            } else if (slimOn == true) {
              currentProducts.push(products[i]);
            }

          } else if (products[i].filters_applicable == 'Wide') {

            if (bS4On == false && bS6On == false && wideOn == false && slimOn == false) {

              currentProducts.push(products[i]);

            } else if (wideOn == true) {
              currentProducts.push(products[i]);
            }
          }else{
            currentProducts.push(products[i]);
          }
        }

          //

          if (products[i].variant == 'Flip') {

            if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

              currentProducts.push(products[i])

            } else if (flipUpOn == true) {

              currentProducts.push(products[i])

            }

          } else if (products[i].variant == 'Half Face') {

            if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

              currentProducts.push(products[i])

            } else if (halfFaceOn == true) {

              currentProducts.push(products[i])

            }

          } else if (products[i].variant == 'Full Face') {

            if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

              currentProducts.push(products[i])

            } else if (fullFaceOn == true) {

              currentProducts.push(products[i])

            }

          } else if (products[i].variant == 'Motocross') {

            if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

              currentProducts.push(products[i])

            } else if (motoCrossOn == true) {

              currentProducts.push(products[i])

            }
          } else { // If there is no variant in this product

            currentProducts.push(products[i])

          }

          //
        }

      }
    }


    console.log('currentProducts:',currentProducts)


    for (var i = 0; i < currentProducts.length; i++) {

      for (var j = 0; j < theCartData.length; j++) {

        if (theCartData[j].product_id == currentProducts[i].id &&
          (currentProducts[i].fitment_for == theCartData[j].fitment_for || currentProducts[i].color == theCartData[j].color)
        ) {

          currentProducts[i].qty = parseInt(theCartData[j].qty);
          currentProducts[i].index = theCartData[j].index;
          currentProducts[i].product_price = theCartData[j].product_price;
        }
      }


      var productExistsInCart = false;
      for (var j = 0; j < theCartData.length; j++) {

        if (theCartData[j].product_id == currentProducts[i].id) {
          productExistsInCart = true;
          break;
        } else {
          productExistsInCart = false;
        }
      }

      if (productExistsInCart == false) {

        currentProducts[i].qty = 0;
      }

      if (!currentProducts[i].hasOwnProperty("qty") || theCartData.length == 0) {
        currentProducts[i].qty = 0;
      }

      cartizedProducts.push(currentProducts[i])
    }


  } // if currentProduct is not null


  // Cartized helmet product
  var cartizedHelmetsProducts = [];
  var addedProductIds = [];

  for (var i = 0; i < products.length; i++) {

    if (products[i].category_id == 1) {

      for (var j = 0; j < theCartData.length; j++) {

        if (theCartData[j].product_id == products[i].id) {

          products[i].qty = theCartData[j].qty;
          products[i].index = theCartData[j].index;
          products[i].product_price = theCartData[j].product_price;
        }
      }

      if (!products[i].hasOwnProperty("qty")) {
        products[i].qty = 0;
      }

      // Filter by brand, size and ratings
      if (brandsFilterArray.includes(products[i].brand_id)
        && sizeFilterArray.includes(products[i].size)
        && ratingsFilterArray.includes(products[i].rating_in_stars + '')) {

        if (priceRangeFilterArray.includes('₹0 - ₹499')) {

          if (products[i].price > 0 && products[i].price <= 499) {

            if (!addedProductIds.includes(products[i].id)) {

              if (products[i].variant == 'Flip') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (flipUpOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Half Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (halfFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Full Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (fullFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Motocross') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (motoCrossOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }
              } else { // Show the product if it has no variant in it
                cartizedHelmetsProducts.push(products[i])
                addedProductIds.push(products[i].id);
              }
            }
          }

        }

        if (priceRangeFilterArray.includes('₹500 - ₹999')) {

          if (!addedProductIds.includes(products[i].id)) {

            if (products[i].price >= 500 && products[i].price <= 999) {

              if (products[i].variant == 'Flip') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (flipUpOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Half Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (halfFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Full Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (fullFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Motocross') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (motoCrossOn == true) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }
              } else { // SHow the product if it has no variant in it
                cartizedHelmetsProducts.push(products[i])
                addedProductIds.push(products[i].id);
              }
            }
          }
        }

        if (priceRangeFilterArray.includes('₹1000 - ₹1499')) {

          if (products[i].price >= 1000 && products[i].price <= 1499) {

            if (!addedProductIds.includes(products[i].id)) {

              if (products[i].variant == 'Flip') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (flipUpOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Half Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (halfFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Full Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (fullFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Motocross') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (motoCrossOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else { // Show the product, if it has no variant in it
                cartizedHelmetsProducts.push(products[i])
                addedProductIds.push(products[i].id);
              }
            }
          }
        }

        if (priceRangeFilterArray.includes('₹1500 - ₹1999')) {

          if (products[i].price >= 1500 && products[i].price <= 1999) {

            if (!addedProductIds.includes(products[i].id)) {


              if (products[i].variant == 'Flip') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (flipUpOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Half Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (halfFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Full Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (fullFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Motocross') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (motoCrossOn == true) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }
              } else { // Show the product if it got no variant
                cartizedHelmetsProducts.push(products[i])
                addedProductIds.push(products[i].id);
              }
            }
          }
        }

        if (priceRangeFilterArray.includes('₹2000 - ₹2499')) {

          if (products[i].price >= 2000 && products[i].price <= 2499) {

            if (!addedProductIds.includes(products[i].id)) {

              if (products[i].variant == 'Flip') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (flipUpOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Half Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (halfFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Full Face') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (fullFaceOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }

              } else if (products[i].variant == 'Motocross') {

                if (flipUpOn == false && halfFaceOn == false && fullFaceOn == false && motoCrossOn == false) {

                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);

                } else if (motoCrossOn == true) {
                  cartizedHelmetsProducts.push(products[i])
                  addedProductIds.push(products[i].id);
                }
              } else { // Show the product if it got no variant
                cartizedHelmetsProducts.push(products[i])
                addedProductIds.push(products[i].id);
              }
            }
          }
        }
      }
    }
  }

 

  var filteredProducts = [];

  for (var i = 0; i < theProducts.length; i++) {

    if ( theProducts[i].name.toLowerCase().includes(searchKey.trim().toLowerCase()) 
    || theProducts[i].id.toString().includes(searchKey.trim().toLowerCase()) 
    ) {

      var productExistsInCart = false;

      for (var j = 0; j < theCartData.length; j++) {

        if (theCartData[j].product_id == theProducts[i].id) {

          theProducts[i].qty = theCartData[j].qty;
          theProducts[i].index = theCartData[j].index;
          theProducts[i].product_price = theCartData[j].product_price;

          productExistsInCart = true;
        }
      }

      if (!theProducts[i].hasOwnProperty("qty") || theCartData.length == 0 || productExistsInCart == false) {
        theProducts[i].qty = 0;
      }

      filteredProducts.push(theProducts[i]);
    }
  }


  var filteredCartizedHelmetsProducts = [];

  for (var i = 0; i < cartizedHelmetsProducts.length; i++) {

    if (cartizedHelmetsProducts[i].name.toLowerCase().includes(searchKey.trim().toLowerCase())
    || cartizedHelmetsProducts[i].id.toString().includes(searchKey.trim().toLowerCase())
    ) {
      filteredCartizedHelmetsProducts.push(cartizedHelmetsProducts[i]);
    }
  }


  console.log('cartizedProducts:',cartizedProducts)


  // Filtering (search) of cartized products
  var filteredCartizedProducts = [];

  for (var i = 0; i < cartizedProducts.length; i++) {

    if ((cartizedProducts[i].color && cartizedProducts[i].color.toLowerCase().includes(fitmentOrColorSearchKey.trim().toLowerCase()))
      || (cartizedProducts[i].model && cartizedProducts[i].model.toLowerCase().includes(fitmentOrColorSearchKey.trim().toLowerCase()))
      || (cartizedProducts[i].fitment_for && cartizedProducts[i].fitment_for.toLowerCase().includes(fitmentOrColorSearchKey.trim().toLowerCase()))
    ) {

      var productExistsInCart = false;

      for (var j = 0; j < theCartData.length; j++) {

        if (theCartData[j].product_id == cartizedProducts[i].id) {

          cartizedProducts[i].qty = theCartData[j].qty;
          cartizedProducts[i].index = theCartData[j].index;
          cartizedProducts[i].product_price = theCartData[j].product_price;

          productExistsInCart = true;
        }
      }

      if (!cartizedProducts[i].hasOwnProperty("qty") || theCartData.length == 0 || productExistsInCart == false) {

        cartizedProducts[i].qty = 0;
      }

      filteredCartizedProducts.push(cartizedProducts[i]);
    }
  }


  console.log('filteredProducts:',filteredProducts)
  console.log('filteredCartizedProducts:',filteredCartizedProducts)  


  return (
    

    <div>


     {favUnfavAction == 'fav' ?
        <ConfirmationDialog
          isOpen={isDialogOpen}
          message={confirmationMessage}
          imageSrc={myChoiceFilled}
          onConfirm={handleFavoriteConfirm}
          onCancel={handleFavoriteCancel}
        />
      :

        <ConfirmationDialog
          isOpen={isDialogOpen}
          message={confirmationMessage}
          imageSrc={myChoiceEmpty}
          onConfirm={handleUnfavoriteConfirm}
          onCancel={handleUnfavoriteCancel}
        />
      }
      
      

      {isLoading ?

        <div style={{
          marginTop: isMobile ? 10 : 70, marginLeft: isMobile ? '3%' : '10%',
          marginRight: isMobile ? '3%' : '10%', display: 'flex', flexDirection: 'column'
        }}>
          <Skeleton height={80} />
          <Skeleton height={20} count={20} />

        </div>
        :

        <div>

          <div className="only-mobile-filter"
          >

            <div className="fabArea" style={{
              width: '100%', backgroundColor: 'white', marginTop: -40,
              display: filterOpen ? 'none' : 'flex', position: 'fixed', zIndex: 900
            }}>

              <Fab
                style={{ textAlign: "fdle", marginLeft: 10, marginBottom: 10, marginTop: 20, backgroundColor: '#F05225' }}
                color="primary"
                aria-label="add"
                onClick={toggleFilter}
              >

                {filterOpen == true ?
                  <FilterIcon />
                  :
                  <FormatAlignLeftIcon />
                }

              </Fab>

              {currentCategoryId !== 0 && !filterOpen && !isFitmentVisible &&

                <div className="form-group has-search only-mobile">

                  <SearchIcon className="form-control-feedback only-mobile" style={{}} />

                  <input type="text" name="search"
                    onChange={handleChange}
                    value={searchKey}
                    className="typeToSearch only-mobile form-control"
                    style={{
                      marginBottom: 20, fontSize: 14, backgroundColor: '#ebedf8',
                      border: '1px solid rgba(0,0,0,.125)', outline: 'none'
                    }}
                    placeholder={strings.type_to_search_products} />

                </div>

              }

              {isMobile && currentCategoryId !== 0  && isFitmentVisible &&

                    <input type="text" 
                           name="search"
                           onChange={handleChangeFitmentOrColorSearch}
                           value={fitmentOrColorSearchKey}
                           className="typeToSearch only-mobile form-control"
                           style={{
                                    marginBottom: 0, marginTop: 30, fontSize: 14, backgroundColor: '#ebedf8',
                                    border: '1px solid rgba(0,0,0,.125)', outline: 'none', width:'70%', marginLeft:10, 
                                  }}
                           placeholder={strings.type_to_search} />
              }

            </div>


            <aside className="theFilterNav" {...handlers} ref={scrollableSectionRef}
              style={{ transition: '0.5s', width: filterOpen ? 300 : 0, overflow: isFitmentVisible?'hidden':'scroll', overflowX:'scroll'  }}>



              <div  style={{
                height: isFitmentVisible?1000: (categoriesCount * 100) + 700,  marginTop: isFitmentVisible ? 50 : 0,
                marginBottom: '5%', width: '90%', marginLeft: 20,
              }}>



                <Filter
                  isFitmentVisible={isFitmentVisible}
                  setBrands={setBrands}
                  setCategoriesCount={setCategoriesCount}
                  setIsHelmetsPageVisible={setIsHelmetsPageVisible}
                  isHelmetsPageVisible={isHelmetsPageVisible}
                  currentCategoryId={currentCategoryId}
                  currentCategory={currentCategory}
                  setCurrentCategoryId={setCurrentCategoryId}
                  setCurrentCategory={setCurrentCategory}
                  // setIsHelmetsPageVisible={setIsHelmetsPageVisible}
                  resetHelmetFilters={resetHelmetFilters}
                  brandsFilterArray={brandsFilterArray}
                  setBrandsFilterArray={setBrandsFilterArray}
                  ratingsFilterArray={ratingsFilterArray}
                  setRatingsFilterArray={setRatingsFilterArray}
                  priceRangeFilterArray={priceRangeFilterArray}
                  setPriceRangeFilterArray={setPriceRangeFilterArray}
                  sizeFilterArray={sizeFilterArray}
                  setSizeFilterArray={setSizeFilterArray}
                  fitmentFilterArray={fitmentFilterArray}
                  colorFilterArray={colorFilterArray}
                  // setBrands={setBrands}
                  toggleFlag={toggleFlag}
                  setFitmentFilterArray={setFitmentFilterArray}
                  setColorFilterArray={setColorFilterArray}
                  currentProduct={currentProduct}
                  setOpen={setOpen}
                  fitments={fitments}
                  resetSearchKeyAndFilters={resetSearchKeyAndFilters}
                  getProducts={getProducts}
                  showApplyButton={true}
                  resetSearchKey={resetSearchKey}
                  setMyChoicePageVisible={setMyChoicePageVisible}>
                </Filter>

              </div>
            </aside>
          </div>

          {isMobile == true && filterOpen == true &&

            <Fab
              // style={{ textAlign: "fdle", marginLeft: 10, marginBottom: 10, marginTop: 20 }}
              color="primary"
              aria-label="add"
              onClick={toggleFilter}
              style={{
                position: 'absolute', right: 15, top: 130, transition: '0.3s', backgroundColor: '#F05225',
                paddingBottom: 5, zIndex: 80000
              }}
            >
              <ArrowBackIcon onClick={toggleFilter}
                sx={{ height: 30, width: 30 }}
              />

            </Fab>
          }


          



          {!underReview ?
            <div className="" style={{ position: "relative", }}>
              <div className="Container">
                <Grid container>

                  <Grid item xs={12} md={filterOpen ? 9 : 12} style={{
                    height: 600, marginLeft: filterOpen ? '25%' : 0,
                    marginTop: isMobile ? -50 : 0,
                  }}>


                  {isLoadingProducts &&
                     <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', alignSelf:'center', marginLeft:'30%', marginTop:'20%'}}>
                          <CircularProgress size={150} disableShrink={false} thickness={0.6}/>
                          <h3>{strings.loading_products}</h3>
                      </div>
                  }


{!isLoadingProducts &&


                    <Grid container>                      

                      {isFitmentVisible ?

                        <div style={{
                          display: 'flex',
                          flexDirection: 'column', flexWrap: 'wrap', marginTop: isMobile ? 100 : 0,
                        }}>

                          
                            <div style={{
                                width: '100%', position: 'fixed', zIndex: 1000, left: isMobile?'2%':'25%',
                                paddingTop: isMobile?0: 25, backgroundColor: isMobile ? 'transparent' : 'white', display:'flex', flexDirection:'row', alignItems:'center'
                              }}>                            

                            {!isMobile ?

                              <>

                          
                                <input type="text" name="search"
                                  onChange={handleChangeFitmentOrColorSearch}
                                  value={fitmentOrColorSearchKey}
                                  className="typeToSearch only-desktop form-control"
                                  style={{
                                    marginBottom: 0, marginTop: 10, fontSize: 14, backgroundColor: '#ebedf8',
                                    border: '1px solid rgba(0,0,0,.125)', outline: 'none',
                                  }}
                                  placeholder={strings.type_to_search} />
                          


                                  {currentCategory && (currentCategory.name !== 'Helmets' && currentCategory.name !== 'Branded helmet') &&

                                    <div style={{
                                        display: 'flex', flexDirection: 'row', margin: 10, marginLeft:20,
                                        paddingTop: 0, alignItems: 'center', width:300
                                    }}>

                                    <span className="innerFilterLabel">{strings.filter}</span>

                                    <MultiSelect
                                        options={bsFilterOptions}
                                        value={selectedBSFilters}
                                        onChange={setSelectedBSFilters}
                                        labelledBy="Select"
                                    />

                                  </div>
                                  }


                                <button className="btn10 backButtonInDetail"
                                        onClick={() => goBackToProdView()}>

                                     <ArrowBackIcon />&nbsp;&nbsp;{strings.back}

                                </button>

                              </>
                              :
                              <>
                              <div style={{display:'flex', flexDirection:'column', backgroundColor:'white'}}>                                     
                               

                               <div>

                                    <button className="btn10 backButtonInDetail" style={{marginRight:10, left:0, marginLeft:-1}}
                                            onClick={() => goBackToProdView()}>
                                            <ArrowBackIcon />
                                    </button>                                


                                {currentCategory && (currentCategory.name !== 'Helmets' && currentCategory.name !== 'Branded helmet') &&

                                    <div style={{
                                        display: 'flex', flexDirection: 'row', margin: 10, marginLeft:90,
                                        paddingTop: 0, alignItems: 'center', width:250, marginTop:-5
                                    }}>

                                    <span className="innerFilterLabel">{strings.filter}</span>

                                    <MultiSelect
                                        options={bsFilterOptions}
                                        value={selectedBSFilters}
                                        onChange={setSelectedBSFilters}
                                        labelledBy="Select"
                                    />

                                  </div>
                                  }

                                  </div>                                   
                                </div>
                              </>
                            }
                            

                          </div>


                          <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            marginBottom: 50,
                            marginTop: isMobile?50: 100
                          }}>


{!myChoicePageVisible ?                            

<>

                            {filteredCartizedProducts.map((item) => (

                              <div>

                                {currentCategory
                                  && currentCategory.presentation_type == 'fitment-based' &&

                                  <div key={item.id} style={item.id % 2 == 1 ? customStyle4 : customStyle5}>

                                    <p style={{
                                      textAlign: 'center', marginLeft: 40,
                                      marginRight: 40, backgroundColor: 'white'
                                    }}
                                    >{item.fitment_name}</p>

                                    <p style={{
                                      textAlign: 'center', marginLeft: 0,
                                      marginRight: 0, marginTop: -10, fontSize: 14
                                    }}
                                    >For {item.fitment_for}</p>


                                    {!item.color && item.model &&
                                      <p style={{
                                        textAlign: 'center', marginLeft: 10,
                                        marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
                                      }}
                                      ><b>{strings.model}</b> : {item.model}
                                      </p>
                                    }


                                    {item.color && !item.model &&
                                      <p style={{
                                        textAlign: 'center', marginLeft: 10,
                                        marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
                                      }}
                                      >

                                        {item.color && item.color !== 'null' && item.color !== 'Null' &&
                                          <>
                                            <b>{strings.color}</b> : {item.color}
                                          </>
                                        }

                                      </p>
                                    }
                                    

                                    {item.color && item.model &&
                                      <>

                                        <p style={{
                                          textAlign: 'center', marginLeft: 10,
                                          marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
                                        }}
                                        ><b>{strings.model}</b> : {item.model}
                                        </p>


                                        <p style={{
                                          textAlign: 'center', marginLeft: 10,
                                          marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
                                        }}
                                        >

                                          {item.color && item.color !== 'null' && item.color !== 'Null' &&
                                            <>
                                              <b>{strings.color}</b> : {item.color}
                                            </>
                                          }

                                        </p>                                        

                                      </>
                                    }


                                    <div style={{
                                      display: 'flex', flexDirection: 'row',
                                      alignSelf: 'center', marginTop: 'auto'
                                    }}>

                                      <button className="btn9" onClick={() => minusHandler(item, item.qty)}
                                        style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

                                      <input style={{
                                        textAlign: 'center', marginTop: 15, marginLeft: 0,
                                        border: '1px solid rgba(0,0,0,.125)', outline: 'none',
                                        marginRight: 0, height: 22, width: 30
                                      }}
                                        name={item.index}
                                        onChange={(e) => changeQty(item, e)}
                                        type="text" value={item.qty} />

                                      <button className="btn9"
                                        onClick={() => plusHandler(item, item.qty)}
                                        style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
                                        +</button>
                                    </div>

                                  </div>
                                }


                                {currentCategory && currentCategory.presentation_type == 'color-based' &&

                                  <div key={item.id} style={item.id % 2 == 1 ? customStyle4 : customStyle5}>

                                    <p style={{
                                      textAlign: 'center', marginLeft: 0,
                                      marginRight: 0, marginTop: 10, fontSize: 14,

                                    }}
                                    >{item.name}</p>

                                    <p style={{
                                      textAlign: 'center', marginLeft: 10,
                                      marginRight: 10, backgroundColor: 'white', marginTop: -10
                                    }}
                                    >

                                      {item.color && item.color !== 'null' && item.color !== 'Null' &&
                                        <>
                                          <b>{strings.color}</b> : {item.color}
                                        </>
                                      }
                                    </p>

                                    <div style={{
                                      display: 'flex', flexDirection: 'row',
                                      alignSelf: 'center', marginTop: 'auto'
                                    }}>

                                      <button className="btn9" onClick={() => minusHandler(item, item.qty)}
                                        style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

                                      <input style={{
                                        textAlign: 'center', marginTop: 15, marginLeft: 0,
                                        border: '1px solid rgba(0,0,0,.125)', outline: 'none',
                                        marginRight: 0, height: 22, width: 30
                                      }}
                                        name={item.index}
                                        onChange={(e) => changeQty(item, e)}
                                        type="text" value={item.qty} />

                                      <button className="btn9"
                                        onClick={() => plusHandler(item, item.qty)}
                                        style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
                                        +</button>
                                    </div>

                                  </div>
                                }



                              </div>
                            ))}

</>

:
<>

{filteredCartizedProducts.map((item) => (

  <div>

    {item.category
      && item.category.presentation_type == 'fitment-based' &&

      <div key={item.id} style={item.id % 2 == 1 ? customStyle4 : customStyle5}>

        <p style={{
          textAlign: 'center', marginLeft: 40,
          marginRight: 40, backgroundColor: 'white'
        }}
        >{item.fitment_name}</p>

        <p style={{
          textAlign: 'center', marginLeft: 0,
          marginRight: 0, marginTop: -10, fontSize: 14
        }}
        >For {item.fitment_for}</p>


        {!item.color && item.model &&
          <p style={{
            textAlign: 'center', marginLeft: 10,
            marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
          }}
          ><b>{strings.model}</b> : {item.model}
          </p>
        }


        {item.color && !item.model &&
          <p style={{
            textAlign: 'center', marginLeft: 10,
            marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
          }}
          >

            {item.color && item.color !== 'null' && item.color !== 'Null' &&
              <>
                <b>{strings.color}</b> : {item.color}
              </>
            }

          </p>
        }
        

        {item.color && item.model &&
          <>

            <p style={{
              textAlign: 'center', marginLeft: 10,
              marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
            }}
            ><b>{strings.model}</b> : {item.model}
            </p>


            <p style={{
              textAlign: 'center', marginLeft: 10,
              marginRight: 10, marginTop: -10, fontSize: 13, backgroundColor: 'white',
            }}
            >

              {item.color && item.color !== 'null' && item.color !== 'Null' &&
                <>
                  <b>{strings.color}</b> : {item.color}
                </>
              }

            </p>                                        

          </>
        }


        <div style={{
          display: 'flex', flexDirection: 'row',
          alignSelf: 'center', marginTop: 'auto'
        }}>

          <button className="btn9" onClick={() => minusHandler(item, item.qty)}
            style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

          <input style={{
            textAlign: 'center', marginTop: 15, marginLeft: 0,
            border: '1px solid rgba(0,0,0,.125)', outline: 'none',
            marginRight: 0, height: 22, width: 30
          }}
            name={item.index}
            onChange={(e) => changeQty(item, e)}
            type="text" value={item.qty} />

          <button className="btn9"
            onClick={() => plusHandler(item, item.qty)}
            style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
            +</button>
        </div>

      </div>
    }


    {item.category && item.category.presentation_type == 'color-based' &&

      <div key={item.id} style={item.id % 2 == 1 ? customStyle4 : customStyle5}>

        <p style={{
          textAlign: 'center', marginLeft: 0,
          marginRight: 0, marginTop: 10, fontSize: 14,

        }}
        >{item.name}</p>

        <p style={{
          textAlign: 'center', marginLeft: 10,
          marginRight: 10, backgroundColor: 'white', marginTop: -10
        }}
        >

          {item.color && item.color !== 'null' && item.color !== 'Null' &&
            <>
              <b>{strings.color}</b> : {item.color}
            </>
          }
        </p>

        <div style={{
          display: 'flex', flexDirection: 'row',
          alignSelf: 'center', marginTop: 'auto'
        }}>

          <button className="btn9" onClick={() => minusHandler(item, item.qty)}
            style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

          <input style={{
            textAlign: 'center', marginTop: 15, marginLeft: 0,
            border: '1px solid rgba(0,0,0,.125)', outline: 'none',
            marginRight: 0, height: 22, width: 30
          }}
            name={item.index}
            onChange={(e) => changeQty(item, e)}
            type="text" value={item.qty} />

          <button className="btn9"
            onClick={() => plusHandler(item, item.qty)}
            style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
            +</button>
        </div>

      </div>
    }



  </div>
))}

</>
}




                          </div>
                        </div>

                        :

                        <div style={{ width: '100%', }}>

                          {isHelmetsPageVisible && !isLoadingProducts ?

                            <div>

                              <div style={{
                                width: '100%', display:
                                  'flex', flexDirection: isMobile ? 'column' : 'row',
                                marginLeft: isMobile ? 0 : -10
                              }}>
                                <div style={{ width: '100%' }}>

                                  <div className="helmetsSection" style={{}}
                                  >

                                    <span
                                      onClick={toggleFullFaceHelmets}
                                      className="helmetsInnerFilter"
                                      style={{
                                        padding: 5,
                                        borderRadius: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        fontSize: 12,
                                        width: '100%',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        border: '1px solid #788896',
                                        color: fullFaceOn ? 'white' : 'black',
                                        backgroundColor: fullFaceOn ? '#788896' : '#ffffff'
                                      }}>
                                      <img src={fullFaceImage}
                                        style={{ width: '100%', height: '100%' }} />
                                      Full Face Helmets</span>

                                    <span
                                      onClick={toggleFlipUpHelmets}
                                      className="helmetsInnerFilter"
                                      style={{
                                        padding: 5,
                                        borderRadius: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        textAlign: 'center',
                                        border: '1px solid #788896',
                                        color: flipUpOn ? 'white' : 'black',
                                        backgroundColor: flipUpOn ? '#788896' : '#ffffff'
                                      }}>
                                      <img src={flipUpImage} style={{ width: '100%', height: '100%' }} />
                                      Flip Up Helmets</span>

                                    <span
                                      onClick={toggleHalfFaceHelmets}
                                      className="helmetsInnerFilter1"
                                      style={{
                                        padding: 5,
                                        borderRadius: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        textAlign: 'center',
                                        border: '1px solid #788896',
                                        color: halfFaceOn ? 'white' : 'black',
                                        backgroundColor: halfFaceOn ? '#788896' : '#ffffff'
                                      }}>
                                      <img src={halfFaceImage} style={{ width: '100%', height: '100%' }} />
                                      Half Face Helmets</span>

                                    <span
                                      onClick={toggleMotoCrossHelmets}
                                      className="helmetsInnerFilter1"
                                      style={{
                                        padding: 5,
                                        borderRadius: 10,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        cursor: 'pointer',
                                        fontSize: 12,
                                        textAlign: 'center',
                                        border: '1px solid #788896',
                                        color: motoCrossOn ? 'white' : 'black',
                                        backgroundColor: motoCrossOn ? '#788896' : '#ffffff'
                                      }}>
                                      <img src={motoCrossImage} style={{ width: '100%', height: '100%' }} />
                                      Motocross Helmets</span>


                                  </div>


                                </div>

                                <button className="btn9" onClick={() => setIsHelmetsPageVisible(false)}
                                  style={{
                                    padding: 13, alignSelf: 'flex-end',
                                    marginLeft: 30
                                  }}>BACK</button>
                              </div>

                              <div>



                                <br /><br />

                                <div style={{
                                  display: 'flex', flexDirection: 'row', flexWrap: 'wrap',
                                  width: '100%', alignSelf: 'flex-end',
                                  marginBottom: 50, justifyContent: 'flex-start'
                                }}>


                                  {filteredCartizedHelmetsProducts.map((item, index) => (

                                    <div key={item.id} style={item.id % 2 == 1 ? customStyle1 : customStyle2}>

                                        <div style={{display:'flex', flexDirection:'column', width:117}}>
                                         
                                         <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
                                         alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
                                          

                                          {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
                                          <>
                                            <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                            <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                >
                                            MY CHOICE</span>
                                          </>
                                          :
                                          <>
                                              <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                              <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                  >
                                              MY CHOICE</span>
                                          </>
                                          }                     

                                        </div>

                                          <img className="square"
                                            onClick={() => openProductModal(item)}
                                            style={{
                                              borderRadius: 10, width: '40%', maxHeight: '100%',
                                              minWidth: 100, marginTop:30,
                                              verticalAlign: 'center', objectFit: 'contain'
                                            }}
                                            src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
                                            alt="" />
                                      </div>



                                        

                                      <div style={{ paddingLeft: 20, paddingRight: 10, display: 'flex', flexDirection: 'column' }}>

                                        <div style={{ display: 'flex', flexDirection: 'column', }}>

                                        <span style={{fontSize:10}}>Product ID : {item.id}</span>

                                          <span style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden', maxWidth: isMobile ? 160 : 120,
                                            textOverflow: 'ellipsis', fontSize: 17,marginTop:3
                                          }}>{strings.getLanguage() == 'en'
                                            ? item.name : item.name_in_hindi}</span>

                                        </div>
                                        <ReactStars
                                          count={5}
                                          size={24}
                                          value={item.rating_in_stars}
                                          isHalf={true}
                                          edit={false}
                                          style={{ marginLeft: -3 }}
                                          activeColor="#ffd700"
                                        />
                                        <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

                                        <div style={{
                                          display: 'flex', flexDirection: 'row', width: '100%',
                                          alignSelf: 'flex-start', marginRight: -40
                                        }}>


                                          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
                                            <button className="btn9" onClick={() => minusHandler(item, item.qty)}
                                              style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

                                            <input style={{
                                              textAlign: 'center', marginTop: 15, marginLeft: 2,
                                              border: '1px solid rgba(0,0,0,.125)', outline: 'none',
                                              marginRight: 2, height: 22, width: 30
                                            }}

                                              name={item.index}
                                              onChange={(e) => changeQty(item, e)}
                                              type="text" value={item.qty} />

                                            <button className="btn9"
                                              onClick={() => plusHandler(item, item.qty)}
                                              style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
                                              +</button>
                                          </div>


                                        </div>

                                      </div>

                                    </div>

                                  ))}
                                </div>

                              </div>
                            </div>

                            :

                            <div style={{ width: '100%', marginTop: 10 }}>

                              <div style={{
                                width: '100%', position: 'fixed', zIndex: 1000, left: '25%',
                                paddingTop: 25, backgroundColor: isMobile ? 'transparent' : 'white'
                              }}>

                                {currentCategoryId !== 0 && filterOpen && !isLoadingProducts &&

                                  <div className="form-group has-search">

                                    <SearchIcon className="form-control-feedback only-desktop" />

                                    <input type="text" name="search"
                                      onChange={handleChange}
                                      value={searchKey}
                                      className="typeToSearch only-desktop form-control"
                                      style={{
                                        marginBottom: 20, fontSize: 14, backgroundColor: '#ebedf8',
                                        border: '1px solid rgba(0,0,0,.125)', outline: 'none'
                                      }}
                                      placeholder={strings.type_to_search_products} />

                                  </div>

                                }

                              </div>


                              {currentCategoryId !== 0 &&

                                <div style={{
                                  display: 'flex', flexDirection: 'row',
                                  flexWrap: 'wrap', marginBottom: 50, overflow: 'hidden',
                                  justifyContent: 'flex-start', paddingTop: 80
                                }}
                                >

                                  {currentCategory && (currentCategory.name == 'Helmets' || currentCategory.name == 'Branded helmet') && !isLoadingProducts &&
                                    <div style={{
                                      width: '100%',
                                      display: 'flex', flexDirection: 'row', marginLeft: isMobile ? 0 : -10
                                    }}>
                                      <div style={{ width: '100%' }}>

                                        <div className="helmetsSection"
                                        >

                                          <span
                                            onClick={toggleFullFaceHelmets}
                                            className="helmetsInnerFilter"
                                            style={{
                                              padding: 5,
                                              borderRadius: 10,
                                              display: 'flex',
                                              flexDirection: isMobile ? 'row' : 'column',
                                              fontSize: 12,
                                              width: isMobile ? '85%' : 'auto',
                                              textAlign: 'center',
                                              alignSelf: 'flex-start',
                                              cursor: 'pointer',
                                              marginTop: isMobile ? 10 : 0,
                                              border: '1px solid #788896',
                                              color: fullFaceOn ? 'white' : 'black',
                                              backgroundColor: fullFaceOn ? '#788896' : '#ffffff'
                                            }}>
                                            <img src={fullFaceImage}
                                              style={{ borderRadius: 10, width: isMobile ? 50 : '100%', height: isMobile ? 50 : 100, }} />
                                            <span style={{
                                              alignSelf: 'center', marginTop: isMobile ? 0 : 5,
                                              marginLeft: isMobile ? 20 : 0
                                            }}> Full Face Helmets</span>  </span>

                                          <span
                                            onClick={toggleFlipUpHelmets}
                                            className="helmetsInnerFilter"
                                            style={{
                                              padding: 5,
                                              borderRadius: 10,
                                              display: 'flex',
                                              flexDirection: isMobile ? 'row' : 'column',
                                              fontSize: 12,
                                              width: isMobile ? '85%' : 'auto',
                                              textAlign: 'center',
                                              alignSelf: 'flex-start',
                                              cursor: 'pointer',
                                              marginTop: isMobile ? 10 : 0,
                                              border: '1px solid #788896',
                                              color: flipUpOn ? 'white' : 'black',
                                              backgroundColor: flipUpOn ? '#788896' : '#ffffff'
                                            }}>
                                            <img src={flipUpImage}
                                              style={{ borderRadius: 10, width: isMobile ? 50 : '100%', height: isMobile ? 50 : 100 }} />
                                            <span style={{
                                              alignSelf: 'center', marginTop: isMobile ? 0 : 5,
                                              marginLeft: isMobile ? 20 : 0
                                            }}> Flip Up Helmets</span>  </span>

                                          <span
                                            onClick={toggleHalfFaceHelmets}
                                            className="helmetsInnerFilter"
                                            style={{
                                              padding: 5,
                                              borderRadius: 10,
                                              display: 'flex',
                                              flexDirection: isMobile ? 'row' : 'column',
                                              fontSize: 12,
                                              width: isMobile ? '85%' : 'auto',
                                              textAlign: 'center',
                                              alignSelf: 'flex-start',
                                              cursor: 'pointer',
                                              marginTop: isMobile ? 10 : 0,
                                              border: '1px solid #788896',
                                              color: halfFaceOn ? 'white' : 'black',
                                              backgroundColor: halfFaceOn ? '#788896' : '#ffffff'
                                            }}>
                                            <img src={halfFaceImage}
                                              style={{ borderRadius: 10, width: isMobile ? 50 : '100%', height: isMobile ? 50 : 100 }} />
                                            <span style={{
                                              alignSelf: 'center', marginTop: isMobile ? 0 : 5,
                                              marginLeft: isMobile ? 20 : 0
                                            }}> Half Face Helmets</span>  </span>

                                          <span
                                            onClick={toggleMotoCrossHelmets}
                                            className="helmetsInnerFilter"
                                            style={{
                                              padding: 5,
                                              borderRadius: 10,
                                              display: 'flex',
                                              flexDirection: isMobile ? 'row' : 'column',
                                              fontSize: 12,
                                              width: isMobile ? '85%' : 'auto',
                                              marginTop: isMobile ? 10 : 0,
                                              textAlign: 'center',
                                              alignSelf: 'flex-start',
                                              cursor: 'pointer',
                                              border: '1px solid #788896',
                                              color: motoCrossOn ? 'white' : 'black',
                                              backgroundColor: motoCrossOn ? '#788896' : '#ffffff'
                                            }}>
                                            <img src={motoCrossImage}
                                              style={{ borderRadius: 10, width: isMobile ? 50 : '100%', height: isMobile ? 50 : 100 }} />
                                            <span style={{
                                              alignSelf: 'center', marginTop: isMobile ? 0 : 5,
                                              marginLeft: isMobile ? 20 : 0
                                            }}> Motocross Helmets</span>  </span>


                                        </div>


                                      </div>


                                    </div>
                                  }


                                  {!myChoicePageVisible && filteredProducts.map((item) => (

                                    <div>
                                      

                                      {currentCategory && currentCategory.presentation_type == 'fitment-based' &&

                                        <div key={item.id} style={item.id % 2 == 1 ? customStyle1 : customStyle2}>

                                       <div style={{display:'flex', flexDirection:'column',width:117}}>
                                         
                                         <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
                                         alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
                                          

                                          {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
                                          <>
                                            <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                            <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                >
                                            MY CHOICE</span>
                                          </>
                                          :
                                          <>
                                              <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                              <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                  >
                                              MY CHOICE</span>
                                          </>
                                          }                     

                                        </div>

                                          <img className="square"
                                            onClick={() => openProductModal(item)}
                                            style={{
                                              borderRadius: 10, width: '40%', maxHeight: '100%',
                                              minWidth: 100, marginTop:30,
                                              verticalAlign: 'center', objectFit: 'contain'
                                            }}
                                            src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
                                            alt="" />
                                      </div>



                                          {/* Grid test*/}
                                          <div style={{ paddingLeft: 20, paddingRight: 10, display: 'flex', flexDirection: 'column',  }}>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <span style={{fontSize:10}}>Product ID : {item.id}</span>

                                              <span style={{
                                                whiteSpace: 'wrap', height: 50,
                                                fontSize: 17, marginBottom: 5,marginTop:3
                                              }}
                                              >{strings.getLanguage() == 'en' ?
                                                item.name : item.name_in_hindi}</span>
                                            </div>

                                            <div style={{marginTop:30}}>

                                            <ReactStars
                                              count={5}
                                              size={24}
                                              value={item.rating_in_stars}
                                              isHalf={true}
                                              edit={false}
                                              style={{ marginLeft: -3 }}
                                              activeColor="#ffd700"
                                            />
                                            <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

                                            <div style={{
                                              display: 'flex', flexDirection: 'row', width: '100%',
                                              alignSelf: 'flex-start', marginRight: -40
                                            }}>

                                              <a className="fitment-button" style={{
                                                cursor: 'pointer',
                                                color: '#fff',
                                                backgroundColor:'#f05225',
                                                padding:8,
                                                textDecoration:'none',
                                                alignSelf: 'flex-start',
                                                marginTop: 20,
                                                fontWeight:'bold',
                                                fontSize: 10,
                                                borderColor:'white',
                                                borderWidth:3,
                                                border:'0px solid black',
                                                borderRadius:10,
                                                letterSpacing:1,
                                                width:150,
                                                textAlign:'center'
                                              }}
                                                onClick={() => viewFitment(item)}>{strings.view_fitment}</a>

                                             </div>

                                            </div>
                                          </div>
                                        </div>
                                      }


                                      {currentCategory && currentCategory.presentation_type == 'color-based'  &&

                                        <div key={item.id}
                                          style={item.id % 2 == 1 ? customStyle1 : customStyle2}>
                                         

                                      <div style={{display:'flex', flexDirection:'column', width:117}}>
                                         
                                         <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
                                         alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
                                          

                                          {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
                                          <>
                                            <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                            <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                >
                                            MY CHOICE</span>
                                          </>
                                          :
                                          <>
                                              <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                              <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                  >
                                              MY CHOICE</span>
                                          </>
                                          }                     

                                        </div>

                                          <img className="square"
                                            onClick={() => openProductModal(item)}
                                            style={{
                                              borderRadius: 10, width: '40%', maxHeight: '100%',
                                              minWidth: 100, marginTop:30,
                                              verticalAlign: 'center', objectFit: 'contain'
                                            }}
                                            src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
                                            alt="" />
                                      </div>



                                          <div style={{
                                            paddingLeft: 20,
                                            paddingRight: 10, display: 'flex',
                                            flexDirection: 'column'
                                          }}>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <span style={{fontSize:10}}>Product ID : {item.id}</span>

                                              <span style={{
                                                whiteSpace: 'wrap',
                                                height: 50,
                                                fontSize: 17,
                                                marginBottom: 5,
                                                marginTop:3
                                              }}>
                                                {strings.getLanguage() === 'en' ?
                                                  (item.name.length > 32 ? item.name.substring(0, 25) + '...' : item.name)
                                                  :
                                                  (item.name_in_hindi.length > 32 ? item.name_in_hindi.substring(0, 25) + '...' : item.name_in_hindi)
                                                }
                                              </span>
                                            </div>

                                            <div style={{marginTop:30}}>

                                            <ReactStars
                                              count={5}
                                              size={24}
                                              value={item.rating_in_stars}
                                              isHalf={true}
                                              edit={false}
                                              style={{ marginLeft: -3 }}
                                              activeColor="#ffd700"
                                            />
                                            <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

                                            <div style={{
                                              display: 'flex', flexDirection: 'row', width: '100%',
                                              alignSelf: 'flex-start', marginRight: -40
                                            }}>

                                              <a className="fitment-button" style={{
                                                cursor: 'pointer',
                                                color: '#fff',
                                                backgroundColor:'#f05225',
                                                padding:8,
                                                textDecoration:'none',
                                                alignSelf: 'flex-start',
                                                marginTop: 20,
                                                fontWeight:'bold',
                                                fontSize: 10,
                                                borderColor:'white',
                                                borderWidth:3,
                                                border:'0px solid black',
                                                borderRadius:10,
                                                letterSpacing:1,
                                                width:150,
                                                textAlign:'center'
                                              }}
                                                onClick={() => viewFitment(item)}>{strings.view_colors}</a>

                                            </div>


                                            </div>
                                          </div>
                                        </div>
                                      }



                                      {currentCategory && currentCategory.presentation_type == 'direct'  &&

                                        <div key={item.id} style={item.id % 2 == 1 ? customStyle1 : customStyle2}>

                                          

                                      <div style={{display:'flex', flexDirection:'column', width:117}}>
                                         
                                         <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
                                         alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
                                          

                                          {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
                                          <>
                                            <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                            <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                >
                                            MY CHOICE</span>
                                          </>
                                          :
                                          <>
                                              <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
                                              <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
                                                  >
                                              MY CHOICE</span>
                                          </>
                                          }                     

                                        </div>

                                          <img className="square"
                                            onClick={() => openProductModal(item)}
                                            style={{
                                              borderRadius: 10, width: '40%', maxHeight: '100%',
                                              minWidth: 100, marginTop:30,
                                              verticalAlign: 'center', objectFit: 'contain'
                                            }}
                                            src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
                                            alt="" />
                                      </div>



                                          <div style={{ paddingLeft: 20, paddingRight: 10, display: 'flex', flexDirection: 'column' }}>

                                            <div style={{ display: 'flex', flexDirection: 'column' }}>

                                            <span style={{fontSize:10}}>Product ID : {item.id}</span>

                                              <span style={{
                                                whiteSpace: 'wrap', height: 50,
                                                fontSize: 17, marginBottom: 5, marginTop:3
                                              }}>{strings.getLanguage() == 'en' ?
                                                item.name : item.name_in_hindi}</span>

                                            </div>

                                            <ReactStars
                                              count={5}
                                              size={24}
                                              value={item.rating_in_stars}
                                              isHalf={true}
                                              edit={false}
                                              style={{ marginLeft: -3 }}
                                              activeColor="#ffd700"
                                            />
                                            <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

                                            <div style={{
                                              display: 'flex', flexDirection: 'row', width: '100%',
                                              alignSelf: 'flex-start', marginRight: -40
                                            }}>

                                              <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>

                                                <button className="btn9" onClick={() => minusHandler(item, item.qty)}
                                                  style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

                                                <input style={{
                                                  textAlign: 'center', marginTop: 15, marginLeft: 0,
                                                  border: '1px solid rgba(0,0,0,.125)', outline: 'none',
                                                  marginRight: 0, height: 22, width: 30
                                                }}

                                                  name={item.index}
                                                  onChange={(e) => changeQty(item, e)}
                                                  type="text" value={item.qty} />

                                                <button className="btn9"
                                                  onClick={() => plusHandler(item, item.qty)}
                                                  style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
                                                  +</button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  ))}


{myChoicePageVisible && filteredProducts.map((item) => (

<div>
  

  {item.category && item.category.presentation_type == 'fitment-based' && item.is_favorite == 1 && 

    <div key={item.id} style={item.id % 2 == 1 ? customStyle1 : customStyle2}>

   <div style={{display:'flex', flexDirection:'column', width:117}}>
     
     <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
     alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
      

      {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
      <>
        <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
        <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
            >
        MY CHOICE</span>
      </>
      :
      <>
          <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
          <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
              >
          MY CHOICE</span>
      </>
      }                     

    </div>

      <img className="square"
        onClick={() => openProductModal(item)}
        style={{
          borderRadius: 10, width: '40%', maxHeight: '100%',
          minWidth: 100, marginTop:30,
          verticalAlign: 'center', objectFit: 'contain'
        }}
        src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
        alt="" />
  </div>



      {/* Grid test*/}
      <div style={{ paddingLeft: 20, paddingRight: 10, display: 'flex', flexDirection: 'column',  }}>

        <div style={{ display: 'flex', flexDirection: 'column' }}>

        <span style={{fontSize:10}}>Product ID : {item.id}</span>

          <span style={{
            whiteSpace: 'wrap', height: 50,
            fontSize: 17, marginBottom: 5,marginTop:3
          }}
          >{strings.getLanguage() == 'en' ?
            item.name : item.name_in_hindi}</span>
        </div>

        <div style={{marginTop:30}}>

        <ReactStars
          count={5}
          size={24}
          value={item.rating_in_stars}
          isHalf={true}
          edit={false}
          style={{ marginLeft: -3 }}
          activeColor="#ffd700"
        />
        <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%',
          alignSelf: 'flex-start', marginRight: -40
        }}>

          <a className="fitment-button" style={{
            cursor: 'pointer',
            color: '#fff',
            backgroundColor:'#f05225',
            padding:8,
            textDecoration:'none',
            alignSelf: 'flex-start',
            marginTop: 20,
            fontWeight:'bold',
            fontSize: 10,
            borderColor:'white',
            borderWidth:3,
            border:'0px solid black',
            borderRadius:10,
            letterSpacing:1,
            width:150,
            textAlign:'center'
          }}
            onClick={() => viewFitment(item)}>{strings.view_fitment}</a>

         </div>

        </div>
      </div>
    </div>
  }


  {item.category && item.category.presentation_type == 'color-based' &&  item.is_favorite == 1 &&

    <div key={item.id}
      style={item.id % 2 == 1 ? customStyle1 : customStyle2}>
     

  <div style={{display:'flex', flexDirection:'column', width:117}}>
     
     <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
     alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
      

      {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
      <>
        <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
        <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
            >
        MY CHOICE</span>
      </>
      :
      <>
          <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
          <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
              >
          MY CHOICE</span>
      </>
      }                     

    </div>

      <img className="square"
        onClick={() => openProductModal(item)}
        style={{
          borderRadius: 10, width: '40%', maxHeight: '100%',
          minWidth: 100, marginTop:30,
          verticalAlign: 'center', objectFit: 'contain'
        }}
        src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
        alt="" />
  </div>



      <div style={{
        paddingLeft: 20,
        paddingRight: 10, display: 'flex',
        flexDirection: 'column'
      }}>

        <div style={{ display: 'flex', flexDirection: 'column' }}>

        <span style={{fontSize:10}}>Product ID : {item.id}</span>

          <span style={{
            whiteSpace: 'wrap',
            height: 50,
            fontSize: 17,
            marginBottom: 5,
            marginTop:3
          }}>
            {strings.getLanguage() === 'en' ?
              (item.name.length > 32 ? item.name.substring(0, 25) + '...' : item.name)
              :
              (item.name_in_hindi.length > 32 ? item.name_in_hindi.substring(0, 25) + '...' : item.name_in_hindi)
            }
          </span>
        </div>

        <div style={{marginTop:30}}>

        <ReactStars
          count={5}
          size={24}
          value={item.rating_in_stars}
          isHalf={true}
          edit={false}
          style={{ marginLeft: -3 }}
          activeColor="#ffd700"
        />
        <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%',
          alignSelf: 'flex-start', marginRight: -40
        }}>

          <a className="fitment-button" style={{
            cursor: 'pointer',
            color: '#fff',
            backgroundColor:'#f05225',
            padding:8,
            textDecoration:'none',
            alignSelf: 'flex-start',
            marginTop: 20,
            fontWeight:'bold',
            fontSize: 10,
            borderColor:'white',
            borderWidth:3,
            border:'0px solid black',
            borderRadius:10,
            letterSpacing:1,
            width:150,
            textAlign:'center'
          }}
            onClick={() => viewFitment(item)}>{strings.view_colors}</a>

        </div>


        </div>
      </div>
    </div>
  }



  {item.category && item.category.presentation_type == 'direct' && item.is_favorite == 1  &&

    <div key={item.id} style={item.id % 2 == 1 ? customStyle1 : customStyle2}>

      

  <div style={{display:'flex', flexDirection:'column', width:117}}>
     
     <div style={{display:'flex', flexDirection:'row', justifyContent:'center', 
     alignItems:'flex-start', padding:2, width:80, borderRadius:2, backgroundColor:'#ffffff'}}>
      

      {!(item.hasOwnProperty('is_favorite')) || item.is_favorite == 0 ?
      <>
        <img src={myChoiceEmpty} onClick={() => favoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
        <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
            >
        MY CHOICE</span>
      </>
      :
      <>
          <img src={myChoiceFilled} onClick={() => unfavoriteItem(item.id, item.name)} width="30" style={{marginLeft:10}}/>
          <span style={{fontSize:10,fontWeight:'normal', textAlign:'center', color:'black'}} 
              >
          MY CHOICE</span>
      </>
      }                     

    </div>

      <img className="square"
        onClick={() => openProductModal(item)}
        style={{
          borderRadius: 10, width: '40%', maxHeight: '100%',
          minWidth: 100, marginTop:30,
          verticalAlign: 'center', objectFit: 'contain'
        }}
        src={item.product_thumbnail_path !== null ? global.backendUrl + '/' + item.product_thumbnail_path : placeholder}
        alt="" />
  </div>



      <div style={{ paddingLeft: 20, paddingRight: 10, display: 'flex', flexDirection: 'column' }}>

        <div style={{ display: 'flex', flexDirection: 'column' }}>

        <span style={{fontSize:10}}>Product ID : {item.id}</span>

          <span style={{
            whiteSpace: 'wrap', height: 50,
            fontSize: 17, marginBottom: 5, marginTop:3
          }}>{strings.getLanguage() == 'en' ?
            item.name : item.name_in_hindi}</span>

        </div>

        <ReactStars
          count={5}
          size={24}
          value={item.rating_in_stars}
          isHalf={true}
          edit={false}
          style={{ marginLeft: -3 }}
          activeColor="#ffd700"
        />
        <span style={{ fontSize: 13, marginTop: 5 }}><b>₹</b>{item.price}.00</span>

        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%',
          alignSelf: 'flex-start', marginRight: -40
        }}>

          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>

            <button className="btn9" onClick={() => minusHandler(item, item.qty)}
              style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>-</button>

            <input style={{
              textAlign: 'center', marginTop: 15, marginLeft: 0,
              border: '1px solid rgba(0,0,0,.125)', outline: 'none',
              marginRight: 0, height: 22, width: 30
            }}

              name={item.index}
              onChange={(e) => changeQty(item, e)}
              type="text" value={item.qty} />

            <button className="btn9"
              onClick={() => plusHandler(item, item.qty)}
              style={{ height: 15, width: 15, padding: 13, marginLeft: 0 }}>
              +</button>
          </div>
        </div>
      </div>
    </div>
  }
</div>
))}

                                </div>
                              }
                            </div>
                          }
                        </div>
                      }
                    </Grid>

                    }
                  </Grid>
                </Grid>

                
              </div>









            </div>
            :

            <div>

              <Neworder4 comeBack={comeBack}
                server={server}
                access_token={access_token} />

            </div>
          }

        </div>

      }

      <Modal
        open={openModal}
        onClose={handleClose}
        style={{ zIndex: 50000, overflowY:'scroll' }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {selectedProduct &&
          <div className="background1">

            <CloseIcon style={{ position: 'fixed', width: 70, right: isMobile?5:100, padding: 10 }}
              onClick={handleClose} />

            <div className="modal-container" style={{ width: '100%', height: '70%' }}>

              <div className="">

                <p><b>{strings.product_code}</b> : #{selectedProduct.id}</p>
                <h4><b>{strings.product_name}</b> : {selectedProduct.name}</h4>
                <p><b>{strings.product_description}</b> : {selectedProduct.description}</p>

                {selectedProduct.product_video_youtube_code && selectedProduct.product_video_youtube_code !== 'undefined' &&
                selectedProduct.product_video_youtube_code !== 'null' &&

                  <a onClick={toggleVideoView}
                    style={{
                      color: '#F05225', textDecoration: 'underline',
                      cursor: 'pointer', marginBottom: 10
                    }}>
                    {showVideo == false ? strings.view_product_video : strings.hide_product_video}
                  </a>
                }


                  {showVideo == true ?

                    <YouTube videoId={selectedProduct.product_video_youtube_code} opts={opts} onReady={_onReady} style={{ marginTop: 10 }} />
                    :

                      <div className="image-container" style={{display:'flex', flexDirection:isMobile?'column-reverse':'column-reverse', alignItems:'flex-start'}}>


                        <div style={{display:'flex', flexDirection:isMobile?'row':'row', flexWrap:'wrap', marginRight:20, justifyContent:'center'}}>

                        {images.map((image, index) => (

                          <button 
                            style={{borderColor:currentImageInGallery == image.original? 'green':'grey', backgroundColor:'transparent', margin:10}}
                            onClick={() => handleImageGalleryThumbClick(image)}>

                              {isMobile?

                            <img width="50" height="50"
                                 src={image.original}/>    
                                 : 
                            <img width="50" height="50"
                                 src={image.original}/> 
                              }

                          </button>         

                        ))}                      

                        
                      </div>   

                      <div className="magnify-image">

                          <ReactImageMagnify {...{
                                smallImage: {
                                  alt: 'Wristwatch by Ted Baker London',
                                  isFluidWidth: true,
                                  src: currentImageInGallery,                                  
                              },
                              largeImage: {
                                 src: currentImageInGallery,
                                 width: 1200,
                                 height: 1800
                              }
                         }} />

                         </div>


                      </div>


                  }

                


                

              </div>
            </div>
          </div>
        }
      </Modal>

      <UnavailableItemsEntry/>     

    </div>

  );
};

export default Neworder2;

